<template>
  <div id="callsgroup">
    <div class="row d-flex">
      <div class="col s12 m5 l4 schedule">
        <div class="calls-group">
          <schedule :agenda="newAppointments" :finished="finishedAppointments"
            @getAgenda="getAgenda" @answer="answerCall" @finished="finished"></schedule>
        </div>
      </div>
      <div class="col s12 m7 l8" v-if="!hasCall">
        <div class="callcenter-info">
          <callcenter-info></callcenter-info>
        </div>
      </div>
      <div class="col s12 m7 l4" v-if="hasCall">
        <call :appointment="callActive" :user="user" @open-chat="openchat = true" :online="callActive.online" @refresh-calls="refresh"></call>
      </div>
      <div class="col s12 m3 l4 patientdata" v-if="hasCall">
        <div v-if="!validCode" class="patient-code">
          <patient-code @validateCode="validateCode" :interaction-id="callActive.id"></patient-code>
        </div>
        <patient-data v-else :user="user"></patient-data>
      </div>
    </div>
    <chat @close-chat="close" v-if="openchat" :name="user.Name + ' ' + user.LastName" :consultation="callActive" :currentUser="currentUser"></chat>
  </div>
</template>

<script>
  import Call from "../Agenda/Call.vue";
  import PatientData from "../Agenda/PatientData.vue"
  import CallCard from "./CallCard.vue";
  import CallcenterInfo from './CallcenterInfo.vue'
  import PatientCode from './PatientCode.vue'
  import Appointment from './Appointment.vue'
  import Schedule from '../Agenda/Schedule.vue'
  import AgendaTabs from '../Agenda/AgendaTabs.vue'
  import Chat from "./Chat.vue";
  import axios from "axios";
  import store from "@/store";

  export default ({
    name: "CallsGroup",
    components: {
      CallCard,
      CallcenterInfo,
      PatientCode,
      Appointment,
      Schedule,
      AgendaTabs,
      Call,
      PatientData,
      Chat,
    },
    data() {
      return {
        callActive: {},
        hasCall: false,
        validCode: false,
        user: {},
        newAppointments: [],
        finishedAppointments: [],
        openchat: false,
      };
    },
    computed: {
      userToken() {
        return store.getters.getToken
      },
      currentUser() {
        return store.getters.getUser
      },
      notifications() {
        return store.getters.getNotifications
      },
      notiLength() {
        return store.getters.getNotifications.length
      }
    },
    watch: {
      notiLength() {
        this.onlinePatient()
      },
    },
    methods: {
      validateCode() {
        this.validCode = true
      },

      close() {
        this.openchat = false
      },

      onlinePatient() {
        for (let i = 0; i < this.notifications.length; i++) {
          const n = this.notifications[i];
          if(n.data.notificationCode == "PT001") {
            let index = this.newAppointments.findIndex((e) => e.id == n.data.appointmentId)
            if(index > -1) {
              this.newAppointments[index].online = true
            }
          }
        }
      },

      async getAgenda(params) {
        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          appid: process.env.VUE_APP_APP_ID,
          companyid: process.env.VUE_APP_COMPANY_ID,
        }

        const respondeAgenda = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/agenda", {
          headers,
          params,
        })

        const agenda = respondeAgenda.data

        let appointments = []

        if(agenda.length > 0) {
          for (let i = 0; i < agenda.length; i++) {
            let user = await this.getPatientInfo(headers, agenda[i].UserId)
            user.Avatar = 'AvatarIcon.png'
            let timestart  = (agenda[i].Date.split('T'))[1].split(':')[0]
            timestart = parseInt(timestart, 10)
            let timeend = (parseInt(timestart, 10) + 1)
            let data = {
              id: agenda[i].Id,
              date: agenda[i].Date,
              status: agenda[i].Status[agenda[i].Status.length-1].Status,
              quota: agenda[i].Properties.Paid,
              consent: agenda[i].Properties.InformedConsentSigned,
              timestart,
              timeend,
              user,
              active: false,
              online: false,
            }
            appointments.push(data)
          }
        }

        appointments = appointments.sort((a, b) => { return a.timestart - b.timestart })
        
        this.newAppointments = appointments.filter((o) => o.status == 'Active')
        this.finishedAppointments = appointments.filter((o) => o.status == 'Attended')

        this.onlinePatient()
      },

      async getPatientInfo(headers, patientId) {
        const response = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/patient/" + patientId, {
          headers,
        })

        return response.data
      },

      refresh() {
        let from = this.convertDate(new Date())
        let to = this.convertDate(new Date())

        const params = {
          from: from + 'T00:00:00',
          to: to + 'T23:59:59',
        }

        this.getAgenda(params)
      },

      answerCall(call) {
        this.openInfo(call, this.newAppointments)
      },
      finished(call) {
        this.openInfo(call, this.finishedAppointments)
      },
      openInfo(call, array) {

        let date = new Date ()
        let day = date.getDate()

        let callDate = parseInt(call.date.split('T')[0].split('-')[2])

        if (day !== callDate) {
          return M.toast({
            html: this.$t('schedule.messages.error1.msg'),
          });
        }

        // let hourDiff = call.timestart - date.getHours()
        // let minutes = date.getMinutes()

        // if (hourDiff > 1 || (hourDiff == 1 && minutes < 60 - this.minutes)) {
        //   return M.toast({
        //     html: this.$t('schedule.messages.error2.msg1') + this.minutes + this.$t('schedule.messages.error2.msg2'),
        //   });
        // }

        this.openchat = false

        this.validCode = false
        if (call === this.callActive) {
          call.active = false
          this.callActive = {}
          this.user = {}
          return this.hasCall = false
        }

        for (const c of array) {
          c.active = false
          if (c.id === call.id) {
            c.active = true
          }
        }
        this.hasCall = true
        this.callActive = call
        this.user = call.user
      },
      convertDate(date) {
        return date.getFullYear() + '/' + this.addZero(date.getMonth() + 1) + '/' + this.addZero(date.getDate())
      },
      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },
    },
  });
</script>

<style scoped>
  #callsgroup {
    margin-left: 67px;
    padding: 24px;
    overflow: hidden;
    height: 90vh;
  }

  #callsgroup>.row {
    height: 100%;
  }

  .calls-group {
    height: 100%;
    overflow: hidden;
  }

  .schedule {
    border-right: 1px solid #E1E2E6;
    border-radius: 0px 0px 0px 15px;
  }

  .patientdata {
    border-left: 1px solid #E1E2E6;
  }

  .row {
    box-shadow: 0px 0px 8px rgba(27, 30, 32, 0.15);
    border-radius: 15px;
  }

  .col {
    padding: 0 !important;
  }

  .patient-code {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @media (max-width: 768px) {
    #callsgroup {
      margin-left: 0px;
    }
  }
</style>