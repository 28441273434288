<template>
  <div id="callsgroup">
    <h2 class="left-align">{{ $t('schedule.welcome') }} {{ currentUser.Name }}</h2>
    <div class="row d-flex">
      <div class="col s12 m5 l4 schedule">
        <div class="calls-group">
          <call-entries></call-entries>
        </div>
      </div>
      <div class="col s12 m7 l8" v-if="!hasCall && !openUnattended">
        <div class="callcenter-info">
          <callcenter-info :type="tabNum"></callcenter-info>
        </div>
      </div>
      <div class="col s12 m7 l5" v-if="openUnattended">
        <un-attended-call :appointment="callActive" :user="user" @finishCall="changeToFinished" @unattended="changeToUnattended"></un-attended-call>
      </div>
      <div class="col s12 m7 l4" v-if="hasCall && !openUnattended">
        <call :appointment="callActive" :user="user" @open-chat="openchat = true" :online="callActive.online" @refresh-calls="refresh" @unattended="changeToUnattended"></call>
      </div>
      <div class="col s12 m3 l4 patientdata" v-if="hasCall">
        <patient-data :user="user" :appointment="callActive"></patient-data>
      </div>
    </div>
    <chat @close-chat="close" v-if="openchat" :name="user.Name + ' ' + user.LastName" :consultation="callActive" :currentUser="currentUser"></chat>
  </div>
</template>

<script>
  import Call from "../Callcenter/Call.vue";
  import CallcenterInfo from '../Callcenter/CallcenterInfo.vue'
  import Chat from "../Callcenter/Chat.vue";
  import PatientData from "../Callcenter/PatientData.vue"
  import UnAttendedCall from "../Callcenter/UnAttendedCall.vue";
  import CallEntries from './CallEntries.vue'
  import axios from "axios";
  import store from "@/store";

  export default ({
    name: "CallsGroup",
    components: {
      CallEntries,
      CallcenterInfo,
      Call,
      PatientData,
      Chat,
      UnAttendedCall,
    },
    data() {
      return {
        callActive: {},
        hasCall: false,
        validCode: false,
        user: {},
        newAppointments: [],
        finishedAppointments: [],
        unattendedAppointments: [],
        openchat: false,
        openUnattended: false,
        tabNum: 0,
        loading: false,
        params: null,
        interval: null,
        changed: false,
        toFinished: false,
      };
    },
    computed: {
      userToken() {
        return store.getters.getToken
      },
      currentUser() {
        return store.getters.getUser
      },
      notifications() {
        return store.getters.getNotifications
      },
      notiLength() {
        return store.getters.getNotifications.length
      }
    },
    watch: {
      async notiLength() {
        this.onlinePatient()
        if(this.hasCall) {
          await this.getAgenda(this.params)
          let index = this.newAppointments.findIndex((i) => i.id == this.callActive.id)
          if(index > -1) {
            this.openInfo(this.newAppointments[index])
          }
        }
      },
      callActive(call) {
        for (const c of this.newAppointments) {
          c.active = false
          if (c.id === call.id) {
            c.active = true
          }
        }

        for (const c of this.finishedAppointments) {
          c.active = false
          if (c.id === call.id) {
            c.active = true
          }
        }

        for (const c of this.unattendedAppointments) {
          c.active = false
          if (c.id === call.id) {
            c.active = true
          }
        }
      }
    },
    methods: {
      validateCode() {
        this.validCode = true
      },

      close() {
        this.openchat = false
      },

      onlinePatient() {
        for (let i = 0; i < this.notifications.length; i++) {
          const n = this.notifications[i];
          if(n.data.notificationCode == "PT001") {
            let index = this.newAppointments.findIndex((e) => e.id == n.data.appointmentId)
            if(index > -1) {
              this.newAppointments[index].online = true;
              this.newAppointments[index].quota = 'true';
            }
          }
        }
      },

      async getAgenda(params) {
        this.params = params
        this.loading = true
        this.newAppointments = []
        this.finishedAppointments = []
        this.unattendedAppointments = []
        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          appid: process.env.VUE_APP_APP_ID,
          companyid: process.env.VUE_APP_COMPANY_ID,
        }

        let respondeAgenda = null

        try {
          respondeAgenda =  await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/agenda", {
            headers,
            params,
          })
        } catch (error) {
          this.loading = false
          return M.toast({
            html: 'Error en la búsqueda de la citas',
          });
        }

        const tasks = [];

        const agenda = respondeAgenda.data
        agenda.forEach(function(item) {
          tasks.push(axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/patient/" + item.UserId, { headers }))
        })

        const responses = await Promise.all(tasks)

        let appointments = []

        if(agenda.length > 0) {
          for (let i = 0; i < agenda.length; i++) {
            let user = responses[i].data
            if (user) user.Avatar = 'AvatarIcon.png';
            let timestart = new Date(agenda[i].Date+'Z')
            let timeend = new Date(timestart.getTime() + 20*60000);
            let data = {
              id: agenda[i].Id,
              date: agenda[i].Date,
              status: agenda[i].Status[agenda[i].Status.length-1].Status,
              statusAnnotation: agenda[i].Status[agenda[i].Status.length-1].Annotation ? agenda[i].Status[agenda[i].Status.length-1].Annotation.trim() : '',
              quota: agenda[i].Properties.Paid ? agenda[i].Properties.Paid : "false",
              consent: agenda[i].Properties.InformedConsentSigned ? agenda[i].Properties.InformedConsentSigned : "not signed",
              specialty: agenda[i].Properties.Specialty ? agenda[i].Properties.Specialty : "Medicina general",
              timestart,
              timeend,
              user,
              active: false,
              online: false,
              inHour: false,
            }
            appointments.push(data)
          }
        }

        appointments = appointments.sort((a, b) => { return a.timestart - b.timestart })
        
        this.newAppointments = appointments.filter((o) => o.status == 'Active')
        this.finishedAppointments = appointments.filter((o) => o.status == 'Attended')
        this.unattendedAppointments = appointments.filter((o) => o.status == 'Unattended')
        this.loading = false
        this.onlinePatient()
        if(!this.interval) {
          this.validateHour()
          this.interval = setInterval(() => {
            this.validateHour()
          }, 60000)
        }
      },

      async getPatientInfo(headers, patientId) {
        const response = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/patient/" + patientId, {
          headers,
        })

        return response.data
      },

      changeTab(type) {
        this.tabNum = type
      },

      refresh() {
        let from = this.convertDate(new Date())
        let to = this.convertDate(new Date())

        const params = {
          from: from + 'T00:00:00',
          to: to + 'T23:59:59',
        }

        this.getAgenda(params)
      },

      answerCall(call) {
        this.openInfo(call)
      },
      finished(call) {
        this.openInfo(call)
      },
      unattended(call) {
        if (call === this.callActive) {
          this.hasCall = false
          call.active = false
          this.callActive = {}
          this.user = {}
          return this.openUnattended = false
        }
        this.hasCall = true
        this.openUnattended = true
        this.callActive = call
        this.user = call.user
      },
      changeToUnattended(call) {
        this.refresh()
        this.changed = true;
        this.callActive = {}
        this.unattended(call)
        setTimeout(() => {
          this.changed = false;
        }, 500);
      },
      changeToFinished(call) {
        this.refresh()
        this.toFinished = true;
        this.callActive = {};
        this.openUnattended = false;
        this.hasCall = false;
        setTimeout(() => {
          this.toFinished = false;
        }, 500);
      },
      openInfo(call) {

        let date = new Date ()
        let day = date.getDate()

        let callDate = parseInt(call.date.split('T')[0].split('-')[2])

        // if (day !== callDate) {
        //   return M.toast({
        //     html: this.$t('schedule.messages.error1.msg'),
        //   });
        // }

        // let hourDiff = call.timestart - date.getHours()
        // let minutes = date.getMinutes()

        // if (hourDiff > 1 || (hourDiff == 1 && minutes < 60 - this.minutes)) {
        //   return M.toast({
        //     html: this.$t('schedule.messages.error2.msg1') + this.minutes + this.$t('schedule.messages.error2.msg2'),
        //   });
        // }

        this.openchat = false
        this.openUnattended = false

        this.validCode = false
        if (call === this.callActive) {
          call.active = false
          this.callActive = {}
          this.user = {}
          return this.hasCall = false
        }

        this.hasCall = true
        this.callActive = call
        this.user = call.user
      },
      convertDate(date) {
        return date.getFullYear() + '/' + this.addZero(date.getMonth() + 1) + '/' + this.addZero(date.getDate())
      },
      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },
      validateHour() {
        let now = new Date()
        for (let i = 0; i < this.newAppointments.length; i++) {
          let e = this.newAppointments[i];
          if ((e.timestart.getHours() - now.getHours()) == 0) {
            this.newAppointments[i].inHour = true
          }
        }
      },
    },
    unmounted() {
      clearInterval(this.interval)
      this.interval = null
    },
  });
</script>

<style scoped>
  #callsgroup {
    margin-left: 67px;
    padding: 24px;
    overflow: hidden;
    height: 90vh;
  }

  #callsgroup>.row {
    height: calc(100% - 40px);
  }

  .calls-group {
    height: 100%;
    overflow: hidden;
  }

  .schedule {
    border-right: 1px solid #E1E2E6;
    border-radius: 0px 0px 0px 15px;
  }

  .patientdata {
    border-left: 1px solid #E1E2E6;
  }

  .row {
    box-shadow: 0px 0px 8px rgba(27, 30, 32, 0.15);
    border-radius: 15px;
  }

  .col {
    padding: 0 !important;
  }

  .patient-code {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  h2 {
    margin-bottom: 18px !important;
    font-size: 20px !important;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    #callsgroup {
      margin-left: 0px;
    }
  }
</style>