<template>
  <div class="login">
    <loading-screen :visible="isLoading" />
    <div class="d-flex justify-content-between align-items-center px-5 pt-4">
      <img class="img-fluid" :src="logoUrl" alt="logo-complete" style="height: 5vw" />
      <ul id="dropdown2" class="dropdown-content">
        <li>
          <a @click="changeLanguage('en')">EN</a>
        </li>
        <li>
          <a @click="changeLanguage('es')">ES</a>
        </li>
        <li>
          <a @click="changeLanguage('pr')">PR</a>
        </li>
      </ul>
      <a id="trigger2" class="dropdown-trigger" href="#" data-target="dropdown2">
        {{ $t('topbar.items[0]') }}: {{ locale }}
        <i class="material-icons right">arrow_drop_down</i>
      </a>
    </div>
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col l6 m6 s12 title d-flex h-100">
          <div class="my-auto">
            <h1 class="text-start responsive-title">
              {{ $t('login.welcome') }} <br /> <span style="text-transform: capitalize;">{{ companyName }}</span>
            </h1>
            <!-- <p class="text-start responsive-subtitle">
              {{ $t('login.subtitle') }}
            </p> -->
          </div>
        </div>
        <div class="col l6 m6 s12 form d-flex h-100">
          <div class="my-auto w-100">
            <div>
              <h2 class="text-start responsive-title mb-3">{{ $t('login.form.title') }}</h2>
              <form @submit.prevent="login">
                <span class="p-float-label mb-4 responsive-subtitle">
                  <input class="form-control px-4" type="email" name="User" id="User" v-model="form.user"
                    placeholder="Email" />
                  <label for="User">{{ $t('login.form.labelEmail') }}</label>
                </span>
                <span class="p-float-label mb-4 responsive-subtitle">
                  <input class="form-control px-4" type="password" name="Password" id="Password" v-model="form.password"
                    placeholder="Password" />
                  <label for="Password">{{ $t('login.form.labelPassword') }}</label>
                </span>
                <div class="form-group responsive-subtitle d-flex justify-content-start mb-5">
                  <button class="btn btn-primary py-1 px-4">{{ $t('login.form.button') }}</button>
                </div>
              </form>
              <!-- <div>
                <p class="text-start responsive-subtitle">
                  <a href="javascript:;" @click="goToForgot">{{ $t('login.forgotPass') }}</a>
                </p>
                <p class="text-start responsive-subtitle mt-3 mb-0">
                  {{ $t('login.signUp.text') }} <br /> <a class="text-start responsive-subtitle" href="javascript:;"
                    @click="goToRegister">{{ $t('login.signUp.link') }}</a>
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import axios from 'axios';
import { getUserData } from '../../../../core/user'
import LoadingScreen from '@/components/LoadingScreen.vue';
import { database, auth } from '@/core/firebase';
import { ref, get, onChildAdded, onChildChanged } from '@firebase/database'
import { signInWithCustomToken, UserCredential } from 'firebase/auth'


export default {

  name: "LoginComponent",
  components: {
    LoadingScreen,
  },
  data() {
    return {
      form: {
        user: "",
        password: "",
      },
      isLoading: false,
    };
  },
  computed: {
    logoUrl() {
      return store.getters.getCompanyLogo ?? null;
    },
    companyName() {
      return store.getters.getCompany?.Name?.toLowerCase() ?? null;
    },
    locale() {
      return this.$i18n?.locale?.toUpperCase() ?? null
    },

    hasFormReady() {
      return store.getters.hasFormReady;
    },
    userToken() {
      return store.getters.getToken;
    },
    notifications() {
      return store.getters.getNotifications;
    },
    plans() {
      return store.getters.getPlans;
    }
  },
  methods: {
    async login() {
      this.isLoading = true;
      if (this.form.user === "" || this.form.password === "") {
        this.isLoading = false;
        return alert("error login");
      }
      console.log("login");
      let data = JSON.stringify({
        "username": this.form.user,
        "userpassword": this.form.password,
        "type": 1
      });

      var urlLogin = process.env.VUE_APP_BOPS_USRKC_V1_API_URL

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${urlLogin}/api/v1/login-medical`,
        headers: {
          'companyid': process.env.VUE_APP_COMPANY_ID,
          'Content-Type': 'application/json',
        },
        data: data
      };

      await axios.request(config)
        .then(async (response) => {
          console.log(response.data);
          if (response.status === 200) {
            const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
            this.$store.commit("SET_TOKEN", responseData.Token);
            this.$store.commit("SET_REFRESH_TOKEN", responseData.RefreshToken);
            await getUserData(responseData.Token);
            this.form.user = "";
            this.form.password = "";

            await this.signIn();
            this.getNotifications();
            this.getAllNotifications();
            this.isLoading = false;
            this.$router.replace("/");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    goToForgot() {
      console.log('forgot');
    },
    goToRegister() {
      console.log('register');
      this.$router.replace("/register");
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    async signIn() {
      console.log("signIn");
      let currentUser = auth.currentUser
      if (!currentUser) {
        const headers = {
          'Authorization': `Bearer ${this.userToken}`,
          'appid': process.env.VUE_APP_APP_ID,
          'companyid': process.env.VUE_APP_COMPANY_ID,
        };
        console.log(headers);

        let response = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/v1/customlogin', {
          headers,
          params: {
            doctor: true
          }
        })

        await signInWithCustomToken(auth, response.data).then(() => {
          console.log('login')
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    getNewNotifications() {
      let notiRef = ref(database, 'notifications/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID + '/Doctors/' + store.getters.getUser.Id);
      onChildAdded(notiRef, (snap) => {
        let noti = this.transformNoti(snap.val(), snap.key)
        let index = this.notifications.some(function (el) { return el.data.notificationId == noti.data.notificationId; })
        if (!index)
          store.commit('SET_NOTIFICATION', noti)
      });
    },
    async getAllNotifications() {
      let notiRef = ref(database, 'notification');
      let snapshot = await get(notiRef);

      if (!snapshot.exists()) return;

      let notifications = snapshot.val();

      for (const key in notifications) {
        const noti = notifications[key];
        if (noti.DoctorId === store.getters.getUser.Id) {
          for (let i = 0; i < noti.Notifications.length; i++) {
            const e = noti.Notifications[i];
            e.data.appointmentId = noti.Id;
            if (e.properties.fromPatient) {
              store.commit('SET_NOTIFICATION', this.transformNoti(e, ''))
            }
          }
        }
      }
    },
    getNotifications() {
      let notiRef = ref(database, 'notification');
      onChildChanged(notiRef, (snap) => {
        let noti = snap.val();
        if (noti.DoctorId === store.getters.getUser.Id) {
          for (let i = 0; i < noti.Notifications.length; i++) {
            const e = noti.Notifications[i];
            e.data.appointmentId = noti.Id;
            if (e.properties.fromPatient) {
              let index = this.notifications.some(function (el) { return el.data.appointmentId == e.data.appointmentId && el.data.notificationCode == e.data.notificationCode; })
              if (!index) {
                store.commit('SET_NOTIFICATION', this.transformNoti(e, ''))
              }
            }
          }
        }
      });
    },
    transformNoti(value, id) {
      let notification = value
      let data = notification.data;
      if (id !== '') data.notificationId = id;

      if (data.notificationCode == "PT001") {
        data.title = this.$t('notifications.onWaitingRoom.title')
        data.message = this.$t('notifications.onWaitingRoom.text')
      }

      if (data.notificationCode == "VD001") {
        data.title = this.$t('notifications.onCall.title')
        data.message = this.$t('notifications.onCall.text')
      }

      if (data.notificationCode == "DT002") {
        data.title = this.$t('notifications.onFinishedCall.title')
        data.message = this.$t('notifications.onFinishedCall.text')
      }

      if (data.notificationCode == "FL001") {
        data.title = this.$t('notifications.onFile.title')
        data.message = this.$t('notifications.onFile.text')
      }

      let a = {
        data: notification.data,
        date: new Date(notification.timestamp),
        properties: notification.properties
      }

      return a
    }
  },
  mounted() {
    var dropdown = document.querySelectorAll(".dropdown-trigger");
    var dropdownOptions = {
      closeOnClick: false,
      hover: true,
      coverTrigger: false,
    };
    M.Dropdown.init(dropdown, dropdownOptions);
  }
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
}

.container {
  height: calc(100% - 80px);
  /* Altura ajustada */
  display: flex;
  align-items: center;
  /* Centra verticalmente */
  justify-content: center;
  /* Centra horizontalmente */
}

.row {
  width: 100%;
  /* Asegúrate de que la fila ocupe el ancho completo */
  margin-top: auto;
  align-items: center;
  /* Centra verticalmente el contenido dentro de la fila */
  justify-content: center;
  /* Centra horizontalmente el contenido dentro de la fila */
}

.title {
  h1 {
    color: #303133;
    font-size: 68px;
    font-weight: 600;
    line-height: 75px;

    span {
      color: var(--primary);
    }
  }

  p {
    color: #303133;
    font-weight: 300;
  }
}

.form {
  .p-float-label label {
    color: rgba(0, 0, 0, .6);
    position: absolute;
    margin-top: 0 !important;
    top: 2.5px;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    text-align: start;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    font-size: initial;
  }

  .p-float-label input:focus~label,
  .p-float-label input:not(:placeholder-shown)~label {
    transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    color: rgba(33, 37, 41, .65);
  }

  input {
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
    border: none !important;
    height: calc(3.5rem + 1px*2);
    min-height: calc(3.5rem + 1px*2);
    line-height: 1.25;
    display: block;
    width: 100%;
    font-weight: 400;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding: 1rem .75rem;
    box-sizing: border-box;

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
    }
  }

  a,
  h2 {
    color: var(--primary);
  }

  h2 {
    font-size: 60px;
    font-weight: 500;
  }

  label {
    color: rgba(0, 0, 0, 0.6);
  }

  p {
    color: #303133;
    font-weight: 300;
    font-size: 16px;

    a {
      font-weight: 400;
      text-decoration: underline !important;
    }
  }

  .btn {
    border-radius: 8px;
    box-shadow: none;
  }
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.text-start {
  text-align: left !important;
}

.text-start.responsive-title {
  font-size: clamp(2rem, 5vw, 5rem);
  /* Tamaño adaptable base */

  @media (max-width: 1200px) {
    font-size: clamp(2rem, 5vw, 5rem);
  }

  @media (max-width: 992px) {
    font-size: clamp(2rem, 6vw, 6rem);
  }

  @media (max-width: 768px) {
    font-size: clamp(1.8rem, 6vw, 6rem);
  }

  @media (max-width: 600px) {
    font-size: clamp(2rem, 10vw, 10rem);
  }
}

.text-start.responsive-subtitle {
  /* Tamaño base adaptable con clamp() */
  font-size: clamp(1rem, 2vw, 1.2rem);
  /* Tamaño base */

  /* Media queries para ajustar el tamaño en diferentes tamaños de pantalla */
  @media (max-width: 1200px) {
    font-size: clamp(0.9rem, 2vw, 1.1rem);
    /* Ajuste para pantallas grandes */
  }

  @media (max-width: 992px) {
    font-size: clamp(0.8rem, 2.5vw, 1rem);
    /* Ajuste para pantallas medianas */
  }

  @media (max-width: 768px) {
    font-size: clamp(0.7rem, 3vw, 1rem);
    /* Ajuste para tabletas */
  }

  @media (max-width: 600px) {
    font-size: clamp(0.6rem, 4vw, 1rem);
    /* Ajuste para dispositivos móviles */
  }
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

input::placeholder {
  opacity: 0;
}
</style>
