<template>
  <div id="agenda">
    <ConfirmDialog class="call-exit"></ConfirmDialog>
    <Dialog v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :closable="false"
      :style="{width: '50vw'}" :modal="true" :draggable="false">
      <h3 class="left-align"> {{ $t('callExit.reason.title') }} </h3>
      <Dropdown id="reason-exit" v-model="reason" :options="wrapUpCodes" optionLabel="Label" :emptyMessage="$t('newCon.addDocument.emptySelect')" />
      <template #footer>
        <button class="waves-effect waves-light btn btn-rounded btn-text btn-text-sec" @click="displayModal = false">{{ $t('callExit.reason.buttons[0]') }}</button>
        <button class="waves-effect waves-light btn btn-rounded btn-primary" @click="updateAppointment">{{ $t('callExit.reason.buttons[1]') }}</button>
      </template>
    </Dialog>
    <div class="row d-flex">
      <div class="col s12 m5 l4 schedule">
        <div class="calls-group">
          <schedule :agenda="newAppointments" :finished="finishedAppointments" :unattended="unattendedAppointments"
            @getAgenda="getAgenda" @answer="answerCall" @finished="finished" @not-attended="unattended"  :callParams="callParams" @end-call="endCall" :loading="loading" @change-tab="changeTab" :changed="changed" :toFinished="toFinished" ></schedule>
        </div>
      </div>
      <div class="col s12 m7 l8" v-if="!hasCall && !openUnattended">
        <div class="callcenter-info">
          <callcenter-info :type="tabNum"></callcenter-info>
        </div>
      </div>
      <div class="col s12 m7 l8" v-if="openUnattended">
        <un-attended-call v-show="callActive.type == 2 && !showCode" :appointment="callActive" :user="user" @finishCall="changeToFinished" @unattended="changeToUnattended" @open-call="showCode = true" @hide-hce="endUnattended" ></un-attended-call>
        <div v-if="!validCode && showCode" class="patient-code">
          <patient-code @validateCode="validateCode" :interactionId="callActive.id"></patient-code>
        </div>
        <appointment v-else-if="showHce" :user="user" :consultation="callActive"></appointment>
      </div>
      <div class="col s12 m7 l8" v-if="hasCall && !openUnattended">
        <virtual-appointment v-if="callActive.type == 2 && !showCode" :appointment="callActive" :user="user" @open-chat="openchat = true" :online="callActive.online" @open-call="goToCall" @unattended="changeToUnattended"></virtual-appointment>
        <div v-if="!validCode && showCode" class="patient-code">
          <patient-code @validateCode="validateCode" :interactionId="callActive.id"></patient-code>
        </div>
        <appointment v-else-if="showHce" :user="user" :consultation="callActive"></appointment>
      </div>
    </div>
    <chat @close-chat="close" v-if="openchat" :name="user.Name + ' ' + user.LastName" :consultation="callActive" :currentUser="currentUser"></chat>
  </div>
</template>

<script>
  import Appointment from './Appointment.vue'
  import CallcenterInfo from './CallcenterInfo.vue'
  import Chat from "./Chat.vue"
  import PatientCode from './PatientCode.vue'
  import Schedule from './Schedule.vue'
  import VirtualAppointment from "./VirtualAppointment.vue";
  import Dialog from 'primevue/dialog';
  import axios from "axios";
  import store from "@/store";
  import router from "@/router";
  import UnAttendedCall from "./UnAttendedCall.vue";
  import codes from "@/data/codes"

  export default ({
    name: "Agenda",
    components: {
      CallcenterInfo,
      PatientCode,
      Appointment,
      Schedule,
      VirtualAppointment,
      Dialog, 
      Chat,
      UnAttendedCall,
    },
    data() {
      return {
        callActive: {},
        hasCall: false,
        validCode: false,
        user: {},
        newAppointments: [],
        finishedAppointments : [],
        unattendedAppointments : [],
        minutes: 15,
        openchat: false,
        openUnattended: false,
        wrapUpCodes: [],
        displayModal: false,
        reason: null,
        codes: codes,
        showCode: false,
        showHce: false,
        callParams: null,
        loading: false,
        params: null,
        tabNum: 0,
        isInCall: false,
        changed: false,
        toFinished: false,
      };
    },
    computed: {
      userToken() {
        return store.getters.getToken
      },
      currentUser() {
        return store.getters.getUser
      },
      notifications() {
        return store.getters.getNotifications
      },
      notiLength() {
        return store.getters.getNotifications.length
      },
      realCodes() {
        const locale = this.$i18n.locale
        if (locale == 'es') {
          return this.codes.codesEs
        } else if (locale == 'en') {
          return this.codes.codesEn
        } else {
          return this.codes.codesPr
        }
      },
    },
    watch: {
      async notiLength() {
        this.onlinePatient()
        await this.getAgenda(this.params)
        if(this.hasCall && !this.isInCall) {
          let index = this.newAppointments.findIndex((i) => i.id == this.callActive.id)
          if(index > -1) {
            this.openInfo(this.newAppointments[index], this.newAppointments, this.isInCall)
          }
        }
      },
      callActive() {
        this.openchat = false;
      }
    },
    methods: {
      validateCode() {
        this.validCode = true
        this.showHce = true
      },

      close() {
        this.openchat = false
      },

      onlinePatient() {
        for (let i = 0; i < this.notifications.length; i++) {
          const n = this.notifications[i];
          if(n.data.notificationCode == "PT001") {
            let index = this.newAppointments.findIndex((e) => e.id == n.data.appointmentId)
            if(index > -1) {
              this.newAppointments[index].online = true;
              this.newAppointments[index].quota = 'true';
            }
          }
        }
      },

      async getAgenda(params) {
        this.params = params
        this.loading = true
        this.newAppointments = []
        this.finishedAppointments = []
        this.unattendedAppointments = []
        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          appid: process.env.VUE_APP_APP_ID,
          companyid: process.env.VUE_APP_COMPANY_ID,
        }

        const respondeAgenda = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/agenda", {
          headers,
          params,
        })

        const tasks = [];

        const agenda = respondeAgenda.data
        agenda.forEach(function(item) {
          tasks.push(axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/patient/" + item.UserId, { headers }))
        })

        const responses = await Promise.all(tasks)

        let appointments = []

        if(agenda.length > 0) {
          for (let i = 0; i < agenda.length; i++) {
            let user = responses[i].data;
            user.Avatar = 'AvatarIcon.png'
            let timestart = new Date(agenda[i].Date+'Z')
            let timeend = new Date(timestart.getTime() + 30*60000);
            let data = {
              id: agenda[i].Id,
              date: timestart.toLocaleString("default", { year: "numeric" }) + "-" + timestart.toLocaleString("default", { month: "2-digit" }) + "-" + timestart.toLocaleString("default", { day: "2-digit" }),
              status: agenda[i].Status[agenda[i].Status.length-1].Status,
              statusAnnotation: agenda[i].Status[agenda[i].Status.length-1].Annotation ? agenda[i].Status[agenda[i].Status.length-1].Annotation.trim() : '',
              quota: "true",
              consent: agenda[i].Properties.InformedConsentSigned ? agenda[i].Properties.InformedConsentSigned : "not signed",
              specialty: agenda[i].Properties.Specialty ? agenda[i].Properties.Specialty : "",
              type: agenda[i].Type, 
              timestart,
              timeend,
              user,
              active: false,
              online: false,
            }
            appointments.push(data)
          }
        }

        appointments = appointments.sort((a, b) => { return a.timestart - b.timestart })
        
        this.newAppointments = appointments.filter((o) => o.status == 'Active')       
        this.finishedAppointments = appointments.filter((o) => o.status == 'Attended')
        this.unattendedAppointments = appointments.filter((o) => o.status == 'Unattended')
        this.onlinePatient()
        this.loading = false
      },

      refresh() {
        let from = this.convertDate(new Date())
        let to = this.convertDate(new Date())

        const params = {
          from: from + 'T00:00:00',
          to: to + 'T23:59:59',
        }

        this.getAgenda(params)
      },

      goToCall(params) {
        this.callParams = params
        this.showCode = true
        this.isInCall = true;
      },

      endCall() {
        this.callParams = null;
        this.displayModal = true;
        this.hasCall = false;
        this.showHce = false;
        this.showCode = false;
        this.isInCall = false;
      },

      endUnattended() {
        this.showHce = false;
        this.showCode = false;
      },

      changeTab(type) {
        this.tabNum = type
      },

      async getPatientInfo(headers, patientId) {
        const response = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/patient/" + patientId, {
          headers,
        })

        return response.data
      },

      answerCall(call) {
        this.openInfo(call, this.newAppointments, false)
      },
      finished(call) {
        this.openInfo(call, this.finishedAppointments, false)
      },
      openInfo(call, array, isInCall) {

        let date = new Date()
        let day = date.getDate()

        let callDate = parseInt(call.date.split('T')[0].split('-')[2])

        // if (day !== callDate) {
        //   return M.toast({
        //     html: this.$t('schedule.messages.error1.msg'),
        //   });
        // }

        // let hourDiff = call.timestart - date.getHours()
        // let minutes = date.getMinutes()

        // if (hourDiff > 1 || (hourDiff == 1 && minutes < 60 - this.minutes)) {
        //   return M.toast({
        //     html: this.$t('schedule.messages.error2.msg1') + this.minutes + this.$t('schedule.messages.error2.msg2'),
        //   });
        // }

        if (this.hasCall && this.validCode) {
          return this.$confirm.require({
            message: this.$t('hceExit.header'),
            header: this.$t('hceExit.text'),
            acceptClass: 'btn-primary',
            acceptLabel: this.$t('hceExit.buttons[0]'),
            rejectLabel: this.$t('hceExit.buttons[1]'),
            accept: () => {
              this.changeCall(call, array)
            },
            reject: () => {
            }
          })
        }

        if(isInCall) return;
        
        this.changeCall(call, array)
      },
      async updateAppointment() {
        if (!this.reason) {
          return M.toast({
            html: this.$t("callExit.reason.alert"),
          });
        }

        const headers = {
          'Authorization': `Bearer ${this.userToken}`,
          'appid': process.env.VUE_APP_APP_ID,
          'companyid': process.env.VUE_APP_COMPANY_ID,
        };

        const body = {
          StatusId: "4",
          AppointmentId: this.callActive.id,
          Annotation: this.reason.InternalCode,
          UserId: this.callActive.user.Id,
          CompanyId: process.env.VUE_APP_COMPANY_ID,
          AppId: process.env.VUE_APP_APP_ID,
        }

        try {
          await axios.post(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/agenda/appointment-status', body, {
            headers,
          }).then((response) => {
            if (response.status == 200) {
              this.displayModal = false
              this.refresh()
            }
          })
        } catch (error) {
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },
      async getWrapUpCodes() {
        const headers = {
          'Authorization': `Bearer ${this.userToken}`,
          'appid': process.env.VUE_APP_APP_ID,
          'companyid': process.env.VUE_APP_COMPANY_ID,
        };

        let response = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/agenda/wrapup-codes', {
          headers,
        })

        let data = response.data

        for (let i = 0; i < data.length; i++) {
          const elem = data[i];
          this.wrapUpCodes.push({
            Label: this.realCodes[i],
            InternalCode: elem.InternalCode
          })
        }
      },
      changeCall(call, array) {
        if(call.type == 2) {
          this.showCode = false
        } else {
          this.showCode = true
        }

        this.openUnattended = false;
        this.openchat = false;
        this.showHce = false
        this.validCode = false
        if (call === this.callActive) {
          call.active = false
          this.callActive = {}
          this.user = {}
          this.showCode = false
          return this.hasCall = false
        }

        for (const c of array) {
          c.active = false
          if (c.id === call.id) {
            c.active = true
          }
        }
        this.hasCall = true
        this.callActive = call
        this.user = call.user
      },
      unattended(call) {
        if (call === this.callActive) {
          this.hasCall = false
          call.active = false
          this.callActive = {}
          this.user = {}
          return this.openUnattended = false
        }
        this.hasCall = true
        this.openUnattended = true
        for (const c of this.unattendedAppointments) {
          c.active = false
          if (c.id === call.id) {
            c.active = true
          }
        }
        this.callActive = call
        this.callActive
        this.user = call.user
      },
      changeToUnattended(call) {
        this.refresh()
        this.changed = true;
        this.callActive = {}
        this.unattended(call)
        setTimeout(() => {
          this.changed = false;
        }, 500);
      },
      changeToFinished(call) {
        this.refresh()
        this.toFinished = true;
        this.callActive = {};
        this.openUnattended = false;
        this.hasCall = false;
        setTimeout(() => {
          this.toFinished = false;
        }, 500);
      },
      convertDate(date) {
        return date.getFullYear() + '/' + this.addZero(date.getMonth() + 1) + '/' + this.addZero(date.getDate())
      },
      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },
    },
    mounted() {
      this.getWrapUpCodes()
    }
  });
</script>

<style scoped>
  #agenda {
    margin-left: 67px;
    padding: 24px;
    overflow: hidden;
    height: 90vh;
  }

  #agenda>.row {
    height: 100%;
  }

  .calls-group {
    height: 100%;
    overflow: hidden;
  }

  .schedule {
    border-right: 1px solid #E1E2E6;
    border-radius: 0px 0px 0px 15px;
  }

  .patientdata {
    border-left: 1px solid #E1E2E6;
  }

  .row {
    box-shadow: 0px 0px 8px rgba(27, 30, 32, 0.15);
    border-radius: 15px;
  }

  .col {
    padding: 0 !important;
  }

  .patient-code {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @media (max-width: 768px) {
    #agenda {
      margin-left: 0px;
    }
  }
</style>