<template>
  <div class="reports-2">
    <top-bar />
    <side-nav />
    <reports />
  </div>
</template>

<script>
import TopBar from '@/components/themes/2/TopBar.vue'
import SideNav from '@/components/themes/2/SideNav.vue'
import Reports from '@/components/themes/2/Reports/Reports.vue'

export default {
  name: 'ReportsView',
  components: {
    TopBar,
    SideNav,
    Reports
  }
}
</script>
