<template>
  <ProgressSpinner v-if="loading" style="margin-top: 100px;"/>
  <div id="history" v-else>
    <div class="row" id="record-list" v-if="!active">
      <div class="col s12">
        <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="row">
              <div class="col s6">
                <span class="p-float-label p-input-icon-right">
                  <i class="pi pi-calendar" />
                  <InputText id="inputtext-right" :value="inputValue.start" v-on="inputEvents.start" class="p-filled" />
                  <label for="inputtext-right">{{ $t("patientData.backlabels[0]") }}</label>
                </span>
              </div>
              <div class="col s6">
                <span class="p-float-label p-input-icon-right">
                  <i class="pi pi-calendar" />
                  <InputText id="inputtext-right" :value="inputValue.end" v-on="inputEvents.end" class="p-filled" />
                  <label for="inputtext-right">{{ $t("patientData.backlabels[1]") }}</label>
                </span>
              </div>
            </div>
          </template>
        </v-date-picker>
      </div>
      <div class="col s12">
        <div class="record-card" v-for="(item, index) in appointments" :key="index" @click="seeAppointment(item)">
          <h3 class="left-align"> {{ item.doctor.Name }} {{ item.doctor.LastName }} - {{ item.specialty !== '' ? item.specialty : 'Medicina general' }} </h3>
          <h5 class="left-align"> {{ getTimestamp(item.date) }} </h5>
        </div>
      </div>
    </div>
    <div class="row" id="individual" v-else>
      <div class="col s12">
        <div class="d-flex title">
          <i class="material-icons" @click="back">arrow_back</i>
          <h5>{{ active.doctor.Name }} {{ active.doctor.LastName }} - {{ getTimestamp(active.date).split('-')[0] }}</h5>
        </div>
      </div>
      <div class="col s12">
        <div class="options d-flex">
          <div class="option" v-for="(option, index) in options" :key="index" @click="selected = option.type" :class="{ active: selected == option.type}">
          <p>
            {{ option.label }}
          </p>
          </div>
        </div>
        <div class="documents">
          <div class="record-card d-flex" v-for="(doc, index) in filterDocuments" :key="index">
            <div class="d-flex">
              <img :src="require('@/assets/images/PDFIcon.png')" alt="icon">
              <div>
                <h3 class="left-align"> {{ doc.FileName }} </h3>
                <h5 class="left-align"> {{ getTimestamp(doc.CreationDate) }} </h5>
              </div>
            </div>
            <i class="material-icons" @click="downloadFile(doc)">visibility</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { httpKeycV1, httpKeycV2 } from '@/core/http'
  import ProgressSpinner from 'primevue/progressspinner'
  import { ref, get, onChildAdded } from '@firebase/database'
  import { database } from '@/core/firebase'
  import store from '@/store'
  import router from '@/router'
  export default({
    name: 'History',
    components: {
      ProgressSpinner,
    },
    props: {
      user: Object,
      actualConsultation: Object
    },
    data() {
      return {
        range: {
          start: new Date(),
          end: new Date()
        },
        options: [{
          label: 'Orden clínica',
          type: 1,
        },
        {
          label: 'Incapacidad',
          type: 2,
        }, 
        {
          label: 'Medicamentos',
          type: 3
        },
        {
          label: 'Compartidos',
          type: 4,
        }],
        active: null,
        selected: 1,
        appointments: [],
        doctors: [],
        loading: false,
        documents: [],
      }
    },
    computed: {
      locale() {
        return this.$i18n.locale
      },
      filterDocuments() {
        let a = this.documents
        a = a.filter((v, i, array) => array.findIndex(value => v.InternalFileName === value.InternalFileName) === i)
        return a.filter((a) => a.Type == this.selected)
      }
    },
    watch: {
      async user() {
        await this.getAppointments();
        this.showActual();
        this.active = null;
        this.documents = [];
      },
      async range() {
        await this.getAppointments();
      }
    },
    methods: {
      seeAppointment(item) {
        this.active = item
        this.getFilesByAppointment(item);
        this.getFilesPatient(item);
      },
      back() {
        this.active = null
        this.documents = []
      },
      showActual() {
        if(this.actualConsultation.status !== "Active") return;

        const { id, specialty, timestart, user } = this.actualConsultation;
        this.appointments.push({
          doctor: this.doctors.find((i) => i.Id == store.getters.getUser.Id),
          specialty: specialty.toLowerCase(),
          date: timestart,
          id,
        });
        let notiref = ref(database, 'notification/'+id+'/Notifications')

        onChildAdded(notiref, async (snapshot) => {
          let val = snapshot.val();
          if(val.data.notificationCode === "FL001") {
            this.documents.push({
              PatientId: user.Id,
              File: await this.getSpecificFile(user.Id, val.data.internalFilename),
              InternalFileName: val.data.internalFilename,
              FileName: val.data.filename,
              CreationDate: val.timestamp,
              Type: 4,
            })
          }
        })
      },
      convertDate(date) {
        return date.getFullYear() + '/' + this.addZero(date.getMonth() + 1) + '/' + this.addZero(date.getDate())
      },
      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },
      async getDoctors() {
        let response = await httpKeycV1.get('/api/user/doctors');

        this.doctors = response.data
      },
      getTimestamp(date) {
        return new Date(date).toLocaleDateString('default', { year: 'numeric', month: 'numeric', day: '2-digit' }) + ' - ' + new Date(date).toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit', hour12: true });
      },
      async getAppointments() {
        this.loading = true;
        let from = this.range.start
        let to = this.range.end

        from = from.setHours(0,0,0);
        to = to.setHours(23,59,59);

        const params = {
          patientId: this.user.Id,
          count: 100,
        }

        const response = await httpKeycV2.get("/api/consultation/patient", {
          params,
        });

        const data = response.data;
        const agenda = data.filter((v, i, a) => a.findIndex(value => v.ConsultationId === value.ConsultationId) === i);
        const history = [];
        for (let i = 0; i < agenda.length; i++) {
          const e = agenda[i];
          const date = new Date(e.ConsultationDate)
          if(date.getTime() >= from && date.getTime() <= to) {
            const appointment = {
              doctor: this.doctors.find((i) => i.Id == e.DoctorId),
              specialty: e.Annotation,
              date,
              id: e.ConsultationId
            }

            history.push(appointment)
          }
        }
        this.appointments = history;
        this.loading = false;
      },
      async getFilesByAppointment(item) {
        const params = {
          appointmentId: item.id,
          count: 1000
        };

        let response = await httpKeycV2.get('/api/files', {
          params
        });
        
        const tasks = [];
        const files = response.data;
        files.forEach(function(item) {
          tasks.push(httpKeycV2.get("/api/files/file/", {
            params: {
              userId: item.PatientId,
              internalFileName: item.InternalFileName,
            }
          }))
        })
        const responses = await Promise.all(tasks)
        for (let i = 0; i < files.length; i++) {
          const e = files[i];
          e.File = responses[i].data;
          e.Type = 4;
        }
        this.documents = this.documents.concat(files);
      },
      async getSpecificFile(userId, internalFileName) {
        const response = await httpKeycV2.get("/api/files/file/", {
          params: {
            userId,
            internalFileName,
          }
        });

        return response.data;
      },
      async getFilesPatient(item) {
        let notiref = ref(database, 'notification/'+item.id)
        let notiSnapshot = await get(notiref);

        if(!notiSnapshot.exists()) return;
        const notifications = notiSnapshot.val().Notifications ? notiSnapshot.val().Notifications : [];
        let files = [];

        for (let i = 0; i < notifications.length; i++) {
          const e = notifications[i];
          if(e.data.notificationCode === "FL001") {
            files.push({
              PatientId: notiSnapshot.val().PatientId,
              File: await this.getSpecificFile(notiSnapshot.val().PatientId, e.data.internalFilename),
              FileName: e.data.filename,
              CreationDate: e.timestamp,
              Type: 4,
              InternalFileName: e.data.internalFilename,
            })
          }
        }

        this.documents = this.documents.concat(files);
      },
      async downloadFile(file) {
        let route = router.resolve({ name: 'FileViewer', query: { userId: file.PatientId, filename: file.InternalFileName } });
        window.open(route.href, '_blank');
      }
    },
    async mounted() {
      await this.getDoctors();
      await this.getAppointments();
      this.showActual();
    },
  })
</script>

<style scoped> 
  #history {
    padding-top: 15px;
  }

  #record-list {
    padding: 21px 30px 17px 30px;
  }

  #history:deep(.p-float-label input.p-filled ~ label) {
    top: 3px !important;
  }

  #history:deep(.p-input-icon-right > .p-inputtext) {
    padding-right: 0 !important;
  }

  .record-card {
    box-shadow: 0px 3px 12px rgba(36, 42, 51, 0.12);
    border-radius: 8px;
    padding: 18px 21px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .record-card h3 {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #242A33;
  }

  .record-card h5 {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #798291;
  }

  .title {
    align-items: center;
    border-bottom: 1px solid #E1E2E6;
    margin-bottom: 10px;
    padding: 0px 20px 15px;
  }

  .title i {
    cursor: pointer;
  }

  .title h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #242A33;
    margin-left: 23px !important;
  }

  .options {
    overflow-x: auto;
    justify-content: space-between;
    padding: 0 25px;
  }

  .options .option {
    background: #E1E2E6;
    border-radius: 8px;
    padding: 6px 16px 8px;
    cursor: pointer;
  }

  .options .option.active {
    background: #1B1D1F !important;
  }

  .options .option p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #5D6062;
  }

  .options .option.active p {
    color: #FBFCFF !important;
  }

  #individual>.col {
    padding-right: 0 !important;
  }

  .documents {
    padding: 20px 25px;
  }

  .documents .record-card {
    align-items: center;
    justify-content: space-between;
    cursor: default;
  }

  .documents .record-card .d-flex {
    align-items: center;  
  }

  .documents .record-card .d-flex img {
    margin-right: 15px;
  }

  .documents .record-card i {
    cursor: pointer;
  }
</style>