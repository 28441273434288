<template>
  <div class="fileviewer">
    <ProgressSpinner v-if="loading"  style="margin-top: 100px;"/>
    <div class="file-container" v-else>
      <div class="pdf" v-if="extension === 'pdf'">
        <embed :src="src">
      </div>
      <div class="img" v-else>
        <img :src="src" alt="img-viewer">
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { httpKeycV2 } from '@/core/http';
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: 'FileViewer',
  components: {
    ProgressSpinner
  },
  setup() {
    const route = useRoute();
    const file = ref('');
    const src = ref('');
    const loading = ref(false);
    const userId = route.query.userId;
    const internalFileName = route.query.filename;

    const extension = internalFileName.split('.')[1];
    
    onMounted(async function() {
      loading.value = true;
      let response = await httpKeycV2.get('/api/files/file/', { params: { userId, internalFileName } })
      file.value = response.data;

      if(extension === 'pdf') {
        src.value = URL.createObjectURL(b64toBlob(file.value, 'application/pdf')) + '#toolbar=0&navpanes=0&scrollbar=0'
      } else {
        src.value = 'data:application/'+ extension +';base64,'+file.value;
      }

      loading.value = false;
    })

    function b64toBlob(b64Data, contentType) {
      var byteCharacters = atob(b64Data)

      var byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        var slice = byteCharacters.slice(offset, offset + 512),
            byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }
        var byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      var blob = new Blob(byteArrays, { type: contentType })
      return blob
    }
    
    return { extension, file, src, loading }
  }
}
</script>

<style lang="scss" scoped>
  .file-container {
    width: 100vw;

    img {
      margin-top: 40px;
      pointer-events: none; 
    }

    embed {
      width: 100%;
      height: 100vh;
    }
  }
</style>
