<template>
  <div id="admin-reports">
    <div class="row row-cont">
      <div class="col s12" style="padding: 0;">
        <ul class="tabs">
          <li class="tab col s3" @click="getCompanyReports"><a href="#insertion">Reporte inserción</a></li>
          <li class="tab col s3" @click="getInteractions"><a href="#interaction">Reporte interacciones</a></li>
          <li class="tab col s3" @click="getAgendaReports"><a href="#agenda">Reporte agenda</a></li>
          <li class="tab col s3" @click="getEmailReports"><a href="#notifications">Reporte notificaciones</a></li>
        </ul>
      </div>
      <div class="col s12" id="insertion">
        <div class="row">
          <div class="col s12">
            <div class="reports-header d-flex">
              <Button type="button" icon="pi pi-filter-slash" label="Limpiar" class="p-button-outlined" @click="initFilters"/>
              <div class="d-flex">
                <button class="waves-effect waves-light btn export" @click="downloadFile">Exportar</button>
                <MultiSelect class="select-filter" v-model="selectedColumsIns" :options="columnsIns" optionLabel="header" placeholder="Filtrar columnas" />
              </div>
            </div>
          </div>
          <ProgressSpinner v-if="loading" style="margin-top: 100px;"/>
          <div class="col s12" v-else>
            <DataTable class="reports-table" :value="reports" :paginator="true" :rows="10" responsiveLayout="scroll" filterDisplay="menu" v-model:filters="filters">
              <Column v-for="col of selectedColumsIns" :field="col.field" :header="col.header" :key="col.field">    
                <template #filter="{filterModel}">
                  <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Filtro"/>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>  
      </div>
      <div class="col s12" id="interaction">
        <div class="row">
          <div class="col s12">
            <div class="reports-header d-flex">
              <Button type="button" icon="pi pi-filter-slash" label="Limpiar" class="p-button-outlined" @click="initFiltersInt"/>
              <div class="d-flex">
                <button class="waves-effect waves-light btn export" @click="downloadReports(interactions, 'interactions-report-')">Exportar</button>
                <MultiSelect class="select-filter" v-model="selectedColumsInt" :options="columnsInt" optionLabel="header" placeholder="Filtrar columnas" />
              </div>
            </div>
          </div>
          <ProgressSpinner v-if="loadingInt" style="margin-top: 100px;"/>
          <div class="col s12" v-else>
            <DataTable class="reports-table" :value="interactions" :paginator="true" :rows="10" responsiveLayout="scroll" filterDisplay="menu" v-model:filters="filtersInt">
              <Column v-for="col of selectedColumsInt" :field="col.field" :header="col.header" :key="col.field">    
                <template #filter="{filterModel}">
                  <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Filtro"/>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>  
      </div>
      <div class="col s12" id="agenda">
        <div class="row">
          <div class="col s12">
            <div class="reports-header d-flex">
              <Button type="button" icon="pi pi-filter-slash" label="Limpiar" class="p-button-outlined" @click="initFiltersAge"/>
              <div class="d-flex">
                <button class="waves-effect waves-light btn export" @click="downloadFileAge">Exportar</button>
                <MultiSelect class="select-filter" v-model="selectedColumsAge" :options="columnsAge" optionLabel="header" placeholder="Filtrar columnas" />
              </div>
            </div>
          </div>
          <ProgressSpinner v-if="loadingAge" style="margin-top: 100px;"/>
          <div class="col s12" v-else>
            <DataTable class="reports-table" :value="agenda" :paginator="true" :rows="10" responsiveLayout="scroll" filterDisplay="menu" v-model:filters="filtersAge">
              <Column v-for="col of selectedColumsAge" :field="col.field" :header="col.header" :key="col.field">
                <template #filter="{filterModel}">
                  <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Filtro"/>
                </template>
              </Column>
            </DataTable>
          </div>
        </div> 
      </div>
      <div class="col s12" id="notifications">
        <div class="row">
          <div class="col s12">
            <div class="reports-header d-flex">
              <Button type="button" icon="pi pi-filter-slash" label="Limpiar" class="p-button-outlined" @click="initFiltersNoti"/>
              <div class="d-flex">
                <button class="waves-effect waves-light btn export" @click="downloadReports(notifications, 'notifications-report-')">Exportar</button>
                <MultiSelect class="select-filter" v-model="selectedColumsNoti" :options="columnsNoti" optionLabel="header" placeholder="Filtrar columnas" />
              </div>
            </div>
          </div>
          <ProgressSpinner v-if="loadingNoti" style="margin-top: 100px;"/>
          <div class="col s12" v-else>
            <DataTable class="reports-table" :value="notifications" :paginator="true" :rows="10" responsiveLayout="scroll" filterDisplay="menu" v-model:filters="filtersNoti">
              <Column v-for="col of selectedColumsNoti" :field="col.field" :header="col.header" :key="col.field">  
                <template #filter="{filterModel}">
                  <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Filtro"/>
                </template>  
              </Column>
            </DataTable>
          </div>
        </div> 
      </div>
      
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import MultiSelect from 'primevue/multiselect';
import store from '../../../../store'
import exportFromJSON from "export-from-json";
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { database } from '../../../../core/firebase';
import { ref, get } from '@firebase/database'
import { httpRep, httpKeycV1 } from '../../../../core/http';

export default defineComponent({
  components: {
    DataTable,
    Column,
    MultiSelect,
    ProgressSpinner,
    Button
  },
  data() {
    return {
      loading: false,
      loadingNoti: false,
      loadingInt: false,
      loadingAge: false,
      doctors: [],
      patients: [],
      reports: [],
      notifications: [],
      agenda: [],
      interactions: [],
      filters: {},
      filtersInt: {},
      filtersAge: {},
      filtersNoti: {},
      selectedColumsIns: [
        {field: 'compensarAppointment.origin', header: 'Origin'},
        {field: 'compensarAppointment.filename', header: 'Filename'},
        {field: 'compensarAppointment.xnomcor', header: 'Xnomcor'},
        {field: 'compensarAppointment.xnomsed', header: 'Xnomsed'},
        {field: 'compensarAppointment.fprgcit', header: 'Fprgcit'},
        {field: 'compensarAppointment.hora', header: 'Hora'},
        {field: 'compensarAppointment.proccessStatus', header: 'ProccessStatus'},
        {field: 'notifications.proccessDate', header: 'proccessDate'},
      ],
      columnsIns: [
        {field: 'compensarAppointment.origin', header: 'Origin'},
        {field: 'compensarAppointment.filename', header: 'Filename'},
        {field: 'compensarAppointment.xnomcor', header: 'Xnomcor'},
        {field: 'compensarAppointment.xnomsed', header: 'Xnomsed'},
        {field: 'compensarAppointment.fprgcit', header: 'Fprgcit'},
        {field: 'compensarAppointment.hora', header: 'Hora'},
        {field: 'compensarAppointment.xnompro', header: 'Xnompro'},
        {field: 'compensarAppointment.xnomeje', header: 'Xnomeje'},
        {field: 'compensarAppointment.nomB_ASEG', header: 'NomB_ASEG'},
        {field: 'compensarAppointment.proccessStatus', header: 'ProccessStatus'},
        {field: 'compensarAppointment.comment', header: 'Comment'},
        {field: 'notifications.proccessDate', header: 'proccessDate'},
      ],
      selectedColumsNoti: [
        {field: 'type', header: 'Tipo Notificación'},
        {field: 'to_name', header: 'Receptor'},
        {field: 'from_email', header: 'From_email'},
        {field: 'subject', header: 'Subject'},
        {field: 'to_email', header: 'To_email'},
        {field: 'last_event_time', header: 'Last_event_time'},
      ],
      columnsNoti: [
        {field: 'type', header: 'Tipo Notificación'},
        {field: 'to_name', header: 'Receptor'},
        {field: 'from_email', header: 'From_email'},
        {field: 'subject', header: 'Subject'},
        {field: 'to_email', header: 'To_email'},
        {field: 'last_event_time', header: 'Last_event_time'},
        {field: 'opens_count', header: 'Opens_count'},
        {field: 'clicks_count', header: 'Clicks_count'},
      ],
      selectedColumsInt: [
        {field: 'id', header: 'Id cita'},
        {field: 'nameDoctor', header: 'Nombre profesional'},
        {field: 'namePatient', header: 'Nombre paciente'},
        {field: 'duration', header: 'Duracion de la llamada'},
        {field: 'startDate', header: 'Fecha inicio'},
        {field: 'endDate', header: 'Fecha fin'},
        {field: 'specialty', header: 'Especialidad'},
      ],
      columnsInt: [
        {field: 'id', header: 'Id cita'},
        {field: 'nameDoctor', header: 'Nombre profesional'},
        {field: 'namePatient', header: 'Nombre paciente'},
        {field: 'duration', header: 'Duracion de la llamada'},
        {field: 'startDate', header: 'Fecha inicio'},
        {field: 'endDate', header: 'Fecha fin'},
        {field: 'specialty', header: 'Especialidad'},
        {field: 'status', header: 'Estatus'},
        {field: 'type', header: 'Tipo'},
        {field: 'roomId', header: 'Id Room (Twilio)'},
        {field: 'phones', header: 'Números marcados'},
        {field: 'phoneAnswered', header: 'Número atendido'},
      ],
      selectedColumsAge: [
        {field: 'schedule.id', header: 'Id'},
        {field: 'doctor', header: 'Nombre profesional'},
        {field: 'patient', header: 'Nombre paciente'},
        {field: 'specialty', header: 'Especialidad'},
        {field: 'paid', header: 'Pago'},
        {field: 'consent', header: 'Consentimiento informado'},
        {field: 'startDate', header: 'Fecha inicio'},
        {field: 'endDate', header: 'Fecha fin'},
      ],
      columnsAge: [
        {field: 'schedule.id', header: 'Id'},
        {field: 'doctor', header: 'Nombre profesional'},
        {field: 'patient', header: 'Nombre paciente'},
        {field: 'specialty', header: 'Especialidad'},
        {field: 'paid', header: 'Pago'},
        {field: 'consent', header: 'Consentimiento informado'},
        {field: 'startDate', header: 'Fecha inicio'},
        {field: 'endDate', header: 'Fecha fin'},
        {field: 'duration', header: 'Duración'},
        {field: 'statusName', header: 'Estatus'},
      ],
    }
  },
  computed: {
    userToken() {
      return store.getters.getToken;
    },
  },
  methods: {
    downloadReports(array, name) {
      const datestr = new Date().toLocaleString();
      const data = array;
      const fileName = name + (datestr.replace(', ', '-'));
      const exportType = exportFromJSON.types.xls;

      exportFromJSON({ data, fileName, exportType })
    },
    downloadFile() {
      let parsed = [];
      for (let i = 0; i < this.reports.length; i++) {
        const e = this.reports[i];
        if(e.notifications) {
          e.notifications['notificationId'] = e.notifications['id']
          delete e.notifications['id']
        }
        delete e['recordId']
        parsed.push({...e.compensarAppointment, ...e.notifications})
      }

      const datestr = new Date().toLocaleString();
      const data = parsed;
      const fileName = 'insertion-report-'+(datestr.replace(', ', ''));
      const exportType = exportFromJSON.types.xls;

      exportFromJSON({ data, fileName, exportType })
    },
    downloadFileAge() {
      let parsed = [];
      for (let i = 0; i < this.agenda.length; i++) {
        const e = this.agenda[i];
        if(e.status) {
          e.status[e.status.length - 1]['statusId'] = e.status[e.status.length - 1]['id']
          delete e.status[e.status.length - 1]['id']
        }
        parsed.push({...e.schedule, ...e.status[e.status.length - 1], statusName: e.statusName, user: e.patient, professional: e.doctor, specialty: e.specialty, paid: e.paid, consent: e.consent, startDate: e.startDate, endDate: e.endDate })
      }
      const datestr = new Date().toLocaleString();
      const data = parsed;
      const fileName = 'agenda-report-'+(datestr.replace(', ', ''));
      const exportType = exportFromJSON.types.xls;

      exportFromJSON({ data, fileName, exportType })
    },
    getInteractions() {
      let inteRef = ref(database, 'interactions/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID);
      get(inteRef).then((snap) => {
        const data = snap.val();
        let inte = [];
        for (const [key, value] of Object.entries(data)) {
          let interaction = {
            id: value.Id,
            idDoctor: value.DoctorId,
            idPatient: value.PatientId,
            nameDoctor: this.getDoctorName(value.DoctorId),
            namePatient: this.getPatientName(value.PatientId),
            roomId: value.RoomId,
            specialty: value.Specialty,
            type: value.Type === 1 ? 'Videollamada' : 'Llamada telefónica',
            startDate: '',
            endDate: '',
            duration: '',
            status: 'Pendiente',
            phones: value.Phones ? value.Phones.filter((v, i, a) => a.indexOf(v) === i).toString().replace(',', ', ') : '',
            phoneAnswered: value.PhoneAnswered ? value.PhoneAnswered : ''
          };

          if(!value.Interactions) continue;

          for (let i = 0; i < value.Interactions.length; i++) {
            const e = value.Interactions[i];
            if(e.Type === 'startCall') interaction.startDate = new Date(e.Timestamp);
            else if (e.Type === 'endCall') {
              interaction.endDate = new Date(e.Timestamp);
              interaction.status = 'Finalizada';
            }
          }
          if(interaction.startDate !== '' && interaction.endDate !== '') {
            var diffMs = (new Date(interaction.endDate) - new Date(interaction.startDate));
            let minutes = Math.floor(((diffMs % 86400000) % 3600000) / 60000);
            let seconds = Math.floor(((diffMs - (minutes * 60000)) % 60000) / 1000);
            interaction.duration = minutes + ' min, ' + seconds + ' seg'
          }

          interaction.startDate = interaction.startDate.toLocaleString('default', { hour12: true });
          interaction.endDate = interaction.endDate.toLocaleString('default', { hour12: true });

          inte.push(interaction);
        }
        this.interactions = inte;
      }) 
      this.loadingInt = false;
    },
    async getVideoReports() {
      let response = await httpRep.get('/api/video');
      console.log(response)
    },
    async getEmailReports() {
      if (this.notifications.length > 0) return;

      this.loadingNoti = true;
      let response = await httpRep.get('/api/email', { params: { company: process.env.VUE_APP_COMPANY_ID, } });

      let data = response.data;
      let noti = [];
      let concat = this.doctors.concat(this.patients)
      for (const i of data) {
        let date = new Date(i.last_event_time)
        date = date.toLocaleString('default', { hour12: true })
        i.type = 'Email'
        i.to_name = this.getNameByEmail(concat, i.to_email)
        i.last_event_time = date
        noti.push(i)
      }
      this.notifications = noti;
      this.loadingNoti = false;
    },
    async getAgendaReports() {
      if (this.agenda.length > 0) return;
      this.loadingAge = true;
      let response = await httpRep.get('/api/doctor', { params: { from: '2022-01-01', to: this.convertDate(new Date()), } });

      let data = response.data;
      for (let i = 0; i < data.length; i++) {
        let e = data[i];
        let paid = e.scheduleProperties.find((i) => i.property == "Paid");
        paid = paid && paid.value === 'True' ? 'Pagado' : 'No pagado';

        let consent = e.scheduleProperties.find((i) => i.property == "InformedConsentSigned");
        consent = consent ? consent.value === 'True' ? 'Firmado' : 'Rechazado' : 'No firmado';

        let specialty = e.scheduleProperties.find((i) => i.property == "Specialty");
        specialty = specialty ? specialty.value : 'Medicina General';

        let duration = e.scheduleProperties.find((i) => i.property == "Duration");
        duration = duration ? duration.value : '';

        let endDate = e.scheduleProperties.find((i) => i.property == "EndDate");
        endDate = endDate ? new Date(endDate.value) : '';

        e.patient = this.getPatientName(e.schedule.id_user);
        e.doctor = this.getDoctorName(e.schedule.id_professional);
        e.paid = paid;
        e.consent = consent;
        e.specialty = specialty;
        e.duration = duration;
        let date = new Date(e.schedule.dateSchedule)
        date = date.toLocaleString('default', { hour12: true })
        e.startDate = date;
        e.endDate = endDate === '' ? endDate : endDate.toLocaleString('default', { hour12: true });
        e.statusName = e.status[e.status.length - 1].id_status === 4 ? 'Atendida' : 'Pendiente'
      }
      this.agenda = data;
      this.loadingAge = false;
    },
    async getCompanyReports() {
      if (this.reports.length > 0) return;
      this.loading = true;
      let response = await httpRep.get('/api/compensar');
      
      this.reports = response.data;
      this.loading = false;
    },
    async getDoctors() {
      let response = await httpKeycV1.get('/api/user/doctors');

      this.doctors = response.data
    },
    async getPatients() {
      let response = await httpKeycV1.get('/api/patient');

      this.patients = response.data
    },
    getDoctorName(id) {
      const doctor = this.doctors.find((i) => i.Id == id)
      let name = doctor ? doctor.Name + ' ' + doctor.LastName : ''
      return name
    },
    getPatientName(id) {
      const patient = this.patients.find((i) => i.Id == id)
      let name = patient ? patient.Name + ' ' + patient.LastName : ''
      return name
    },
    getNameByEmail(array, email) {
      let user = array.find((i) => i.Email == email)
      if (user) return user.Name + ' ' + user.LastName
      else return ''
    },
    initFilters() {
      this.filters = {
        'compensarAppointment.origin': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.filename': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.xnomcor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.xnomsed': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.fprgcit': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.hora': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.xnompro': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.xnomeje': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.nomB_ASEG': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.proccessStatus': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'compensarAppointment.comment': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'notifications.proccessDate': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
      }
    },
    initFiltersInt() {
      this.filtersInt = {
        'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'nameDoctor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'namePatient': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'duration': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'startDate': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'endDate': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'specialty': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'type': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'roomId': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
      }
    },
    initFiltersAge() {
      this.filtersAge = {
        'schedule.id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'doctor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'patient': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'duration': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'startDate': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'endDate': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'specialty': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'statusName': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'paid': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'consent': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
      }
    },
    initFiltersNoti() {
      this.filtersNoti = {
        'type': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'to_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'from_email': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'subject': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'to_email': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'last_event_time': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'opens_count': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'clicks_count': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
      }
    },
    changeToSpanish() {
      this.$primevue.config.locale.startsWith = 'Comienza por';
      this.$primevue.config.locale.contains = 'Contiene';
      this.$primevue.config.locale.notContains = 'No contiene';
      this.$primevue.config.locale.endsWith = 'Termina en';
      this.$primevue.config.locale.equals = 'Igual a';
      this.$primevue.config.locale.notEquals = 'No igual a';
      this.$primevue.config.locale.matchAll = 'Todos';
      this.$primevue.config.locale.matchAny = 'Cualquiera';
      this.$primevue.config.locale.clear = 'Limpiar';
      this.$primevue.config.locale.apply = 'Aplicar';
      this.$primevue.config.locale.addRule = 'Añadir regla';
      this.$primevue.config.locale.removeRule = 'Remover regla';
    },
    convertDate(d) {
      return d.getFullYear() + '-' + this.addZero((d.getMonth()+1)) + '-' + this.addZero(d.getDate())
    },
    addZero(date) {
      if ((date / 10) < 1)
        return '0' + date

      return date
    },
  },
  async mounted() {
    M.AutoInit();
    var tab = document.querySelectorAll(".tabs");
    M.Tabs.init(tab);
    
    this.changeToSpanish();
    this.initFilters();
    this.initFiltersInt();
    this.initFiltersAge();
    this.initFiltersNoti();
    await this.getCompanyReports();
    await this.getDoctors();
    await this.getPatients();
  }
})
</script>

<style scoped>
  #admin-reports {
    margin-left: 67px;
    padding: 24px;
  }

  h2 {
    font-weight: 700 !important;
    font-size: 35px !important;
    line-height: 40px !important;
  }

  .row-cont {
    box-shadow: 0px 0px 8px rgba(27, 30, 32, 0.15);
    border-radius: 15px;
  }

  .select-filter:deep(.pi-chevron-down:before) {
    content: "";
  }

  .select-filter:deep(.p-multiselect-label-container) {
    display: none;
  }

  .select-filter:deep(.p-multiselect-trigger) {
    height: 40px !important;
  }

  .reports-header {
    justify-content: space-between;
    margin: 20px 0;
  }

  .export {
    background: #454B55 !important;
    border-radius: 8px;
    margin-right: 20px;
    height: 40px;
  }

  .reports-table:deep(.p-paginator) {
    justify-content: end;
    border: none !important;
  }

  .p-button-outlined {
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
    background-color: transparent !important;
    height: 40px !important;
  }

  .p-button-outlined:deep(.p-button-label) {
    font-weight: 500 !important;
    font-family: inherit !important;
  }
  
</style>