
import { defineComponent } from 'vue'
import TopBar from '@/components/TopBar.vue'
import SideNav from '@/components/SideNav.vue'
import CallsGroup from '@/components/Callcenter/CallsGroup.vue';

export default defineComponent({
  name: 'CallCenter',
  components: {
    TopBar,
    CallsGroup,
    SideNav,
  }
})
