<template>
  <div id="schedule">
    <div class="row row-cont d-flex">
      <div class="agenda">
        <ProgressSpinner v-if="load" style="margin-top: 100px;"/>
        <div class="row" v-show="!load">
          <div class="col s12">
            <ul class="collapsible">
              <li>
                <div class="collapsible-header title">
                  <h3>{{ $t('schedule.calendarTitle') }}</h3> <i class="material-icons">keyboard_arrow_down</i>
                </div>
                <div class="collapsible-body">
                  <div class="calendar">
                    <v-date-picker v-if="isRange" :locale="locale" class="calendar-bops" title-position="left" v-model="dateRange" is-expanded is-range />
                    <v-date-picker v-else :locale="locale" class="calendar-bops" title-position="left" v-model="date" is-expanded @dayclick="getAgenda" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col s12">
            <ul class="tabs">
              <li class="tab col s4" @click="select(0)"><a href="#upcoming"> {{ $t('schedule.tabs[0]') }} </a></li>
              <li class="tab col s4" @click="select(1)"><a href="#finished"> {{ $t('schedule.tabs[1]') }} </a></li>
              <li class="tab col s4" @click="select(2)"><a href="#unattended"> {{ $t('schedule.tabs[2]') }} </a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tabs-cont" v-show="!load">
        <div id="upcoming" class="col s12">
          <user-info v-for="appointment in agenda" :key="appointment.id" class="info-appointment"
            :class="{inHour: appointment.inHour}" :active="appointment.active" :username="appointment.user.Name + ' ' + appointment.user.LastName" :appointmentId="appointment.id" :avatar="appointment.user.Avatar" :subtitle="appointment.date.split('T')[0].replaceAll('-', '/') + ' - ' + schedule(appointment)" :type="appointment.type" @click="answerCall(appointment)" :online="appointment.online" :status="userStatus(appointment)">
          </user-info>
        </div>
        <div id="finished" class="col s12">
          <user-info v-for="appointment in finished" :key="appointment.id" class="info-appointment"
            :class="{inHour: appointment.inHour}" :active="appointment.active" :username="appointment.user.Name + ' ' + appointment.user.LastName" :appointmentId="appointment.id" :avatar="appointment.user.Avatar" :subtitle="appointment.date.split('T')[0].replaceAll('-', '/') + ' - ' + schedule(appointment)" :type="appointment.type" @click="seeFinishedCall(appointment)">
          </user-info>
        </div>
        <div id="unattended" class="col s12">
          <user-info v-for="appointment in unattended" :key="appointment.id" class="info-appointment"
            :class="{inHour: appointment.inHour}" :active="appointment.active" :username="appointment.user.Name + ' ' + appointment.user.LastName" :appointmentId="appointment.id" :avatar="appointment.user.Avatar" :subtitle="appointment.date.split('T')[0].replaceAll('-', '/') + ' - ' + schedule(appointment)" :type="appointment.type" @click="notAnswered(appointment)">
          </user-info>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserInfo from './UserInfo.vue'
  import ProgressSpinner from 'primevue/progressspinner';

  export default ({
    name: 'Schedule',
    components: {
      UserInfo,
      ProgressSpinner,
    },
    props: {
      agenda: Array,
      finished: Array,
      unattended: Array,
      hasCall: Boolean,
      loading: Boolean,
      changed: Boolean,
      toFinished: Boolean,
    },
    data() {
      return {
        dateRange: {
          start: new Date(),
          end: new Date(),
        },
        date: new Date(),
        interval: null,
        load: false,
        isRange: true,
      }
    },
    computed: {
      locale() {
        return this.$i18n.locale
      }
    },
    watch: {
      loading(val) {
        this.load = val
      },
      dateRange() {
        this.getAgendaRange()
      },
      changed(val) {
        if (val) {
          var tab = document.querySelector(".tabs");
          var instance = M.Tabs.getInstance(tab);
          instance.select('unattended')
        }
      },
      toFinished(val) {
        if (val) {
          var tab = document.querySelector(".tabs");
          var instance = M.Tabs.getInstance(tab);
          instance.select('finished')
        }
      }
    },
    methods: {
      getAgendaRange() {
        let from = this.convertDate(this.dateRange.start)
        let to = this.convertDate(this.dateRange.end)

        const params = {
          from: from + 'T00:00:00',
          to: to + 'T23:59:59',
        }

        this.$emit('getAgenda', params)
      },

      getAgenda() {
        let from = this.convertDate(this.date)

        const params = {
          from: from + 'T00:00:00',
          to: from + 'T23:59:59',
        }

        this.$emit('getAgenda', params)
      },

      select(type) {
        this.$emit('change-tab', type)
      },

      schedule(appointment) {
        var start = this.convertHour(appointment.timestart)
        var end = this.convertHour(appointment.timeend)
        return start + ' - ' + end
      },

      convertHour(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'p. m.' : 'a. m.';
        hours = hours % 12;
        hours = hours ? hours : 12;
        var strTime = this.addZero(hours) + ':' + this.addZero(minutes) + ' ' + ampm;
        return strTime;
      },

      answerCall(call) {
        this.$emit('answer', call);
      },
      seeFinishedCall(call) {
        this.$emit('finished', call);
      },
      notAnswered(call) {
        this.$emit('not-attended', call);
      },
      userStatus(appointment) {
        if(appointment.quota && appointment.consent) {
          const quota = appointment.quota.trim().toLowerCase()
          const consent = appointment.consent.trim().toLowerCase()
          if (quota == 'true' && consent == 'true') {
            return 'success'
          }

          if (quota == 'false' || consent == 'not signed') {
            return 'warning'
          }

          if (consent == 'false') {
            return 'error'
          }
        }
      },
      convertDate(date) {
        return date.getFullYear() + '/' + this.addZero(date.getMonth() + 1) + '/' + this.addZero(date.getDate())
      },
      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },
    },
    mounted() {
      M.AutoInit();
      var tab = document.querySelectorAll(".tabs");
      M.Tabs.init(tab);

      var collap = document.querySelectorAll('.collapsible');
      M.Collapsible.init(collap);

      this.getAgenda()
      let i = setInterval(() => {
        let minutes = new Date()
        minutes = minutes.getMinutes()
        if ((minutes+1) % 5 == 0) {
          this.getAgenda();
          this.interval = setInterval(() => {
            this.getAgenda();
          }, 300000)
          clearInterval(i)
        }
      }, 1000);
    },
    unmounted() {
      clearInterval(this.interval)
    },
  })
</script>

<style scoped>
  #schedule,
  #schedule>.row {
    height: 100%;
  }

  .row-cont {
    flex-flow: column;
  }

  .agenda {
    flex: 0 1 auto;
  }

  .agenda .row {
    margin-bottom: 0 !important;
  }

  .tabs-cont {
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .title {
    background-color: #EFF0F4;
    padding-left: 30px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px 0 0 0;
  }

  .collapsible {
    margin: 0 !important;
    box-shadow: none;
    border: 0 !important;
  }

  .collapsible-header,
  .collapsible-body {
    border-bottom: 1px solid #E1E2E6;
  }

  .collapsible-body {
    padding: 1rem 2rem 0;
  }
</style>