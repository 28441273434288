
  import {
    defineComponent
  } from 'vue'
  import UserInfo from '../Callcenter/UserInfo.vue'

  export default defineComponent({
    name: 'Schedule',
    components: {
      UserInfo
    },
    props: {
      agenda: Array,
      finished: Array,
      unattended: Array,
      hasCall: Boolean,
    },
    data() {
      return {
        dateRange: {
          start: new Date(),
          end: new Date(),
        },
      }
    },
    computed: {
      locale() {
        return this.$i18n.locale
      }
    },
    methods: {
      getAgenda() {
        let from = this.convertDate(this.dateRange.start)
        let to = this.convertDate(this.dateRange.end)

        const params = {
          from: from + 'T00:00:00',
          to: to + 'T23:59:59',
        }

        this.$emit('getAgenda', params)
      },

      schedule(appointment) {
        return appointment.timestart + ':00 - ' + appointment.timeend + ':00'
      },

      answerCall(call) {
        this.$emit('answer', call);
      },
      seeFinishedCall(call) {
        this.$emit('finished', call);
      },
      notAnswered(call) {
        this.$emit('not-attended', call);
      },
      userStatus(appointment: any) {
        if(appointment.quota && appointment.consent) {
          const quota = appointment.quota.trim().toLowerCase()
          const consent = appointment.consent.trim().toLowerCase()
          if (quota == 'true' && consent == 'true') {
            return 'success'
          }

          if (quota == 'false') {
            return 'warning'
          }

          if (consent == 'false') {
            return 'error'
          }
        }
      },
      convertDate(date: any) {
        return date.getFullYear() + '/' + this.addZero(date.getMonth() + 1) + '/' + this.addZero(date.getDate())
      },
      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },
    },
    mounted() {
      M.AutoInit();
      var tab = document.querySelectorAll(".tabs");
      M.Tabs.init(tab);

      var collap = document.querySelectorAll('.collapsible');
      M.Collapsible.init(collap);

      this.getAgenda()
    },
  })
