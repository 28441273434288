
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserInfo',
  props: {
    online: Boolean,
    username: String,
    avatar: String,
    status: String,
    type: Number,
    subtitle: String,
  }
})
