
  import { defineComponent, ref, onMounted } from 'vue'
  import { ref as fbref, get, update, set } from '@firebase/database'
  import { database } from '../../core/firebase'
  import store from '../../store';
  import NexmoClient, { Application } from 'nexmo-client';
  import { useRoute } from 'vue-router';
  
  export default defineComponent({
    name: 'KeyboardView',
    emits: ['close-keyboard'],
    setup(props, { emit }) {
      const route = useRoute();
      let jwt = `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDE0NDg0NjksImp0aSI6IjdmZWNmMTUwLTkwNjctMTFlZS1iM2EzLTkxZmRiNzVjNzE1MSIsInN1YiI6ImJvcHNnZW5lcmFsIiwiZXhwIjo4NjQxNzAxNDQ4NDcwLCJhY2wiOnsicGF0aHMiOnsiLyovdXNlcnMvKioiOnt9LCIvKi9jb252ZXJzYXRpb25zLyoqIjp7fSwiLyovc2Vzc2lvbnMvKioiOnt9LCIvKi9kZXZpY2VzLyoqIjp7fSwiLyovaW1hZ2UvKioiOnt9LCIvKi9tZWRpYS8qKiI6e30sIi8qL2FwcGxpY2F0aW9ucy8qKiI6e30sIi8qL3B1c2gvKioiOnt9LCIvKi9rbm9ja2luZy8qKiI6e30sIi8qL2xlZ3MvKioiOnt9fX0sImFwcGxpY2F0aW9uX2lkIjoiOGE1M2NjMDAtOGRjNS00Y2VlLWFlZDMtZTkyNmEwMzEzMDBkIn0.LeGtLfZEwQHtSeUr4-f7lg0MLJRJA1O4C-SDeRyIHmUmCq2BXzS2xtmjlGeRDUHE0m00s5a839QPbOsqtLgLrVPeRVfjjIG7hROtN9xfKo4IoJQr0qTOezv3MAnaAZ2M_dgyZBF8PECQhkoJPCpOIw04o-eNyWph8T2W4LTJ4CJAJxzq8fFmE7uimtF_58AURj6TxR-appSphZPM3GiiLSiMSD50vSrPqGEDPBXc5cUU-X9DTQr9MsgZM4y83BpnoCRRuVTz9b6whX3DVNFsmZL-Q9b1R0OO3weo6WcKdTQLTAHZ5Y1Ng-K0OWkXYij0iiIwW7QPU-8D01Pwl0WMvA`;
      const phone = ref('');
      const onCall = ref(false);
      const startDate = ref();
      const interval = ref();
      const countdown = ref('Marcando...');
      const minutes = ref(0);
      const seconds = ref(0);
      const { appointmentId, userId, specialty } = route.query;
      let app: Application;
      let hangupButton: HTMLElement;
      let nexmo = new NexmoClient({ debug: process.env.VUE_APP_NEXMO_DEBUG && process.env.VUE_APP_NEXMO_DEBUG == 'true' ? true : false });
  
      const keyboard = [{num: '1', subtitle: ''}, {num: '2', subtitle: 'ABC'}, {num: '3', subtitle: 'DEF'},{num: '4', subtitle: 'GHI'}, {num: '5', subtitle: 'JKL'}, {num: '6', subtitle: 'MNO'}, {num: '7', subtitle: 'PQRS'}, {num: '8', subtitle: 'TUV'}, {num: '9', subtitle: 'WXYZ'}]

      const notiref = fbref(database, 'interactions/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID + '/' + appointmentId);
  
      onMounted(async function () {
        hangupButton = document.getElementById("hangup")!
        app = await nexmo.createSession(jwt);
  
        app.on("call:status:changed", (call) => {
          if (call.status === call.CALL_STATUS.STARTED){
            countdown.value = 'Marcando...';
            savePhone();
            hangupButton.addEventListener("click", () => endCall(call));
          }

          if (call.status === call.CALL_STATUS.ANSWERED){
            countdown.value = '00:00';
            startDate.value = new Date();
            sendInteraction();
            interval.value = setInterval(function() {
              countdown.value = getCountdown();
            }, 1000);
          }

          if (call.status === call.CALL_STATUS.COMPLETED){
            endCall(call);
          }
        })
      })
  
      function pressButton(num: string) {
        if(phone.value.length < 10) phone.value = phone.value + num;
      }
  
      function erase() {
        phone.value = phone.value.slice(0, -1);
      }
  
      function startCall() {
        if(phone.value == '') {
          return
        }
        onCall.value = true;
        app.callServer('57'+phone.value);
      }
  
      function endCall(call) {
        call.hangUp();
        onCall.value = false;
        countdown.value = '00:00';
        phone.value = '';
        minutes.value = 0;
        seconds.value = 0;
        clearInterval(interval.value);
        sendEndInteraction();
      }
  
      function closeKeyboard() {
        emit('close-keyboard')
      }
  
      function getCountdown() {
        seconds.value++;
        if (seconds.value > 59) {
          minutes.value++;
          seconds.value = 0;
        }
  
        let countdown = '';
  
        countdown +=  getAs2Digits(minutes.value) + ':' + getAs2Digits(seconds.value);
        return countdown;
      }
  
      function getAs2Digits(number: number) {
        return ('00' + number).slice(-2);
      }

      async function savePhone() {
        await get(notiref).then(async (snap) => {
          if(!snap.exists()) {
            await set(notiref, {
              Id: appointmentId,
              PatientId: userId,
              DoctorId: store.getters.getUser.Id,
              Specialty: specialty,
              Type: 2,
              Phones: [phone.value]
            });
          } else {
            let phones = snap.val().Phones ? snap.val().Phones : [] as any;
            update(notiref, { Phones: [...phones, phone.value] })
          }
        })
      }
  
      async function sendInteraction() {
        const interaction = {
          StartedBy: store.getters.getUser.Id,
          Timestamp: new Date().toISOString(),
          Type: 'startCall'
        };
  
        await get(notiref).then(async (snap) => {
          let interactions = snap.val().Interactions ? snap.val().Interactions : [] as any;
          update(notiref, { Interactions: [...interactions, interaction], PhoneAnswered: phone.value })
        })
      }
  
      async function sendEndInteraction() {
        const interaction = {
          StartedBy: store.getters.getUser.Id,
          Timestamp: new Date().toISOString(),
          Type: 'endCall'
        };
  
        await get(notiref).then((snap) => {
          let interactions = snap.val().Interactions;
          update(notiref, { Interactions: [...interactions, interaction] })
        })
      }

      function isNumber(evt: KeyboardEvent) {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "#"]
        const keyPressed = evt.key;
    
        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault();
        } 
      }  
  
      return { phone, pressButton, erase, startCall, closeKeyboard, keyboard, onCall, countdown, isNumber }
    },
  })
