export default {
  topbar: {
    items: ["Idioma", "Finalizar sessão"],
  },
  sideNav: {
    items: ['Início', 'Callcenter', 'Agenda', 'Usuários', 'Info', 'Estatisticas', 'Pesquisas'],
    items2: ['Dashboard', 'Consultório', 'Pesquisas', 'Estatisticas']
  },
  login: {
    welcome: 'Bem-vindo ao',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eu luctus lorem. Fusce sed malesuada.',
    form: {
      title: 'Iniciar sessão',
      labelEmail: 'E-mail',
      labelPassword: 'Senha',
      button: 'Entrar',
      or: 'Ou',
    },
    forgotPass: 'Esqueceu sua senha',
    signUp: {
      text: "Não tem uma conta?",
      link: 'Inscrever-se',
    }
  },
  register: {
    back: "Voltar",
    title: "Dados de cadastro",
    subtitle: {
      text: "Você já possui uma conta?",
      link: "Iniciar sessão",
    },
    fields: [
      "Nomes",
      "Sobrenomes",
      "Tipo de documento",
      "Número do documento",
      "Número do registro médico (CRM)",
      "Código do país ",
      "Telefone",
      "Papel",
      "Especialidade",
      "Dias de trabalho",
      "Horas por dia",
      "Duração da consulta (minutos)",
    ],
    roles: ['Médico', 'Enfermeiro(a)', 'Super Administrador', 'Administrador', 'Administrador Assistente', 'Treinado', 'Orientador em Saúde'],
    specialties: ['Médico geral', 'Enfermeiro', 'Orientador em Saúde'],
    weekdays: ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'],
    workingHours: 'Faixa Horária',
    terms: {
      text: 'Li e aceito os',
      link: 'termos e condições',
    },
    privacy: {
      text: 'Li e aceito a',
      link: 'política de privacidade',
    },
    messages: ['Deve preencher todos os campos', 'Deve aceitar os termos e condições', 'Deve aceitar a política de privacidade', 'Usuário criado com sucesso', 'Você deve escolher horas válidas em seus horários'],
    button: 'Continuar',
  },
  noAccess: {
    title: {
      part1: 'Seu cadastro como médico em',
      part2: 'está em processo de aprovação',
    },
    text: {
      part1: 'Nas próximas 24 horas você receberá um email',
      part2: 'com o resultado desse processo',
    },
    button: 'Retornar ao início da sessão',
  },
  callcenterInfo: {
    upcoming: {
      title: "Clique nos horários para ver as informações da consulta",
      subtitle: "Você pode ligar ou falar por chat com seus pacientes",
    },
    finished: {
      title: "Clique nos horários para ver as informações da consulta finalizada",
      subtitle: "Do lado direito é possível visualizar os dados do paciente, documentos e interações ocorridas durante a consulta.",
    },
    unAttended: {
      title: "Clique nos horários para ver as informações da consulta cancelada",
      subtitle: "Você pode escolher o motivo pelo qual o agendamento foi cancelado.",
    },
    patient: {
      title: "Digite o código do paciente",
      text: "Deve digitar o código do paciente para acessar sua história clínica (prontuário)",
      placeholder: "Digite o código",
      button: "Aceitar",
      wrongPatient: "Código do paciente incorreto",
      noCode: "Deve digitar o código do paciente",
    },
  },
  callsTabs: ['Chamada atual', 'Finalizadas', 'Não atendidas'],
  appointments: {
    noNew: "Sem novas consultas",
    state: "Ativo",
    calling: "entrada",
    types: ["Videochamada", "Chamada", "Chat"],
  },
  schedule: {
    welcome: 'Seja bem-vindo(a)',
    calendarTitle: "Calendário de consultas",
    tabs: ["Próximos", "Finalizadas", "Não atendidas"],
    online: 'On-line',
    disconnected: 'Desconectado',
    noRequirements: 'Sem requisitos',
    waiting: 'Na sala de espera',
    notLogged: 'Não logado',
    newMsg: 'novas mensagens',
    messages: {
      error1: {
        msg: 'Você só pode acessar os compromissos de hoje',
      },
      error2: {
        msg1: 'Você poderá entrar no compromisso quando faltarem ',
        msg2: ' minutos para o horário marcado',
      }
    },
    loadingCallText: 'Aguarde enquanto o paciente se conecta à videochamada, isso pode levar alguns minutos.',
    noAppointment: 'Não há consulta ativa',
  },
  contactCenter: {
    types: ['Chamada recebida', 'Em progresso', 'Chamada em curso', 'Chamada finalizada'],
  },
  hceExit : {
    header: 'Tem certeza que quer sair?, você perderá todo o seu progresso',
    text: 'Saindo do prontuário',
    buttons: ['Sim', 'Não'],
  },
  callExit: {
    validate: {
      text: 'Tem certeza de que deseja encerrar a consulta?',
      buttons: ['Sim', 'Não'],
    },
    reason: {
      title: 'Qual foi a razão pela qual esta nomeação terminou?',
      buttons: ['Cancelar', 'Confirme'],
      alert: 'Você deve escolher o motivo pelo qual este compromisso terminou',
    }
  },
  chatPlaceHolder: 'Escreve uma mensagem',
  agendaTabs: ["Consulta", "Dados básicos", "Histórico de consultas"],
  hceHeader: {
    ageLabel: 'Idade',
    years: 'Anos',
    company: 'Companhia',
    button: 'Agendar',
  },
  appointmentTabs: ["Antecedentes pessoais", "Sinais vitais", "Histórico de consultas", "Nova consulta"],
  background: {
    option: 'Consultar',
    actions: 'Ações',
    btnClose: 'Encerrar',
    titles: ['Patologias', 'Alergias', 'Antecedentes familiares', 'Internações', 'Toxicológicos', 'Vacinas', 'Cirurgias', 'Trauma', 'Transfusões', 'Medicamentos diários', 'Riscos encontrados'],
    pathology: {
      title: 'Nova patologia',
      labels: ['Nome', 'Diagnosticado por', 'Diagnosticado em', 'Observação'],
      msg: 'Patologia adicionada com sucesso'
    },
    allergy: {
      title: 'Nova alergia',
      labels: ['Nome', 'Diagnosticado por', 'Diagnosticado em', 'Tratamento', 'Frequência (Opcional)'],
      msg: 'A alergia foi adicionada com sucesso'
    },
    family: {
      title: 'Novo membro familiar',
      labels: ['Nome do membro da família', 'Parentesco', 'Doença', 'Condições de saúde'],
      msg: 'Familiar foi adicionado com sucesso'
    },
    risk: {
      title: 'Novo risco',
      labels: ['Tipo', 'Risco', 'Observação'],
      tableTitles: ['Tipo de risco', 'Data', 'Nível do risco'],
      msg: 'Risco adicionado com sucesso'
    },
    hospi: {
      title: 'Nova internação',
      labels: ['Data de início', 'Data do fim', 'Médico responsável', 'Local', 'Causa', 'Resumo da internação (.pdf)'],
      button: 'Anexar',
      buttonD: 'Baixar arquivo',
      msg: 'A hospitalização foi adicionada com sucesso'
    },
    toxic: {
      title: 'Novo Toxicológico',
      labels: ['Tipo', 'O que você consome?', 'Grau de severidade', 'Data do início', 'Data do fim', 'Descrição do consumo'],
      tableTitles: ['Tipo de consumo'],
      msg: 'Toxicologia adicionada com sucesso'
    },
    immu: {
      title: 'Nova Imunização (Vacina)',
      labels: ['Nome', 'Data', 'Farmacêutica', 'Número da dose', 'Observação'],
      tableTitles: ['Data da vacinação', 'Dose'],
      msg: 'A imunização foi adicionada com sucesso'
    },
    surgery: {
      title: 'Nova Cirurgia',
      labels: ['Tipo', 'Nome', 'Data', 'Observação'],
      msg: 'SA cirurgia foi adicionada com sucesso'
    },
    trauma: {
      title: 'Novo Trauma',
      labels: ['Nome', 'Data', 'Observação', 'Tipo'],
      msg: 'O trauma foi adicionado com sucesso'
    },
    transfusion: {
      title: 'Nova Transfusão',
      labels: ['Tipo', 'Quantidade (volume)', 'Data', 'Observação'],
      msg: 'Transfusão adicionada com sucesso'
    },
    medicine: {
      title: 'Novo medicamento',
      labels: ['Causa ou patologia', 'Data da prescrição'],
      tableTitles: ['Princípio Ativo', 'Causa', 'Data do início', 'Frequência - Duração', 'Unidade', 'Dose'],
      msg: 'Droga adicionada com sucesso'
    },
  },
  vitals: {
    titles: ['Pressão arterial', 'Glicemia', 'Oximetria', 'Frequência cardíaca', 'Frequência respiratória', 'Temperatura', 'Peso', 'Altura', 'Circunferência abdominal'],
    open: 'Abrir',
    add: 'Adicionar',
    dates: ['De', 'Até'],
    modals: {
      titles: ['Adicionar nova pressão arterial', 'Adicionar Glicemia', 'Adicionar Oximetria', 'Adicionar Frequência Cardíaca', 'Adicionar Frequência respiratória', 'Adicionar Temperatura', 'Adicionar Peso', 'Adicionar Altura', 'Adicionar Circunferência abdominal'],
      generalMsg: 'A medida foi adicionada com sucesso',
      validateMsg: 'Você deve escrever uma medida',
      pressureLabels: ['Sistólica', 'Diastólica'],
      label: 'Medida'
    }
  },
  newCon: {
    titles: ['Motivo da consulta (queixa) / Evolução', 'Revisão por sistemas', 'Exame Físico', 'Diagnóstico', 'Prescrição / Receitas', 'Pedidos médicos', 'Atestados / Relatórios Médicos', 'Resumo Médico'],
    add: 'Adicionar',
    button: 'Salvar e Finalizar',
    btnSave: 'Salvar',
    btnCancel: 'Cancelar',
    btnNext: 'Próximo',
    action: 'Ações',
    loading: 'Carregando, por favor aguarde.',
    errormsg: 'Deve preencher todos os campos',
    options: {
      edit: 'Editar',
      delete: 'Excluir',
      addMedicine: 'Adicionar medicamento',
      addExam: 'Adicionar exame ou procedimento',
    },
    addMsgs: {
      success: 'Sua consulta foi agendada com sucesso',
      error: 'Ocorreu um erro',
      validations: ['Você deve colocar ao menos um diagnóstico', 'Certifique-se de adicionar os medicamentos à prescrição', 'Certifique-se de adicionar laboratórios/exames ao pedido médico'],
    },
    addNote: {
      choose: {
        title: 'Escolha o tipo de observação',
        items: ['Motivo da consulta', 'Evolução médica'],
      },
      reason: {
        title: 'Adicionar o motivo da consulta',
        inputLabel: 'Motivo da consulta',
        textareaLabel: 'Doença atual',
      },
      evolutionNote: {
        title: 'Adicionar evolução médica',
        label: 'Subjetivo / Objetivo',
      },
      tableTitles: ['Tipo', 'Motivo da consulta', 'Doença atual'],
    },
    addRevision: {
      title: 'Nova revisão por sistemas',
      switchLabel: 'Nega',
      fields: ['Estado geral', 'Órgãos sensoriais', 'Cardiorespiratório', 'Endocrinológico', 'Gastrointestinal', 'Genitourinário', 'Musculoesquelético', 'Neurológico', 'Psíquico / Mental']
    },
    addExam: {
      title: 'Novo Exame Físico',
      switchLabel: 'Estado normal (Exame Físico sem alterações)',
      fields: ['Cabeça', 'Pescoço', 'Olhos', 'Nariz', 'Boca', 'Orelhas / Ouvidos', 'Cardiorespiratório', 'Tórax', 'Abdome', 'Genital', 'Extremidades', 'Psíquico / Mental', 'Neurológico', 'Pele']
    },
    addDiagnosis: {
      title: 'Diagnóstico',
      selects: ['Diagnóstico Principal', 'Diagnóstico Secundário (Opcional)', 'Diagnóstico Secundário (Opcional)'],
      tableTitles: ['Diagnóstico principal', 'Diagnóstico Secundário'],
      error: 'Deve escolher um diagnóstico principal',
    },
    addPrescription: {
      title: 'Adicionar prescrição (receita)',
      inputLabel: 'Destino da prescrição (receita)',
      tableTitles: ['Prescrição (Receita)', 'Destino', 'Medicamentos'],
      subTableTitles: ['Princípio Ativo', 'Frequência', 'Duração', 'Unidade', 'Dose'],
      errors: ['Deve escrever o destino da prescrição (receita)', 'Deve escolher um disgnóstico para a prescrição (receita)'],
    },
    addOrder: {
      title: 'Adicionar pedido',
      inputLabel: 'Destino do pedido',
      tableTitles: ['Pedido', 'Destino do pedido', 'Laboratórios'],
      subTableTitles: ['Diagnóstico', 'Laboratório'],
      errors: ['Deve escrever o destino do pedido', 'Deve escrever o tipo de ordem'],
    },
    addLab: {
      title: 'Novo exame ou procedimento',
      labels: ['Tipo de pedido', 'Nome'],
      selectItems: ['Procedimientos', 'Laboratório', 'Imagens', 'Especialidades médicas'],
      errors: ['Deve escrever o diagnóstico associado ao procedimento', 'Deverá escrever o nome do procedimento']
    },
    addMedicine: {
      title1: 'Novo medicamento',
      title2: 'Indique a posologia',
      tableTitles: ['Princípio Ativo', 'Produto', 'Descrição', 'Apresentação', 'Unidade', 'Dose'],
      formulaLabels: ['Quantidade', 'Frequência', 'Quando', 'Duração'],
      step: 'Passo',
      days: 'Dias',
    },
    addDocument: {
      title: 'Escolha qual documento você gostaria de enviar',
      options: ['Atestado médico', 'Relatório médico'],
      emptySelect: 'Não existem opções disponíveis',
      disability: {
        title: 'Novo atestado médico',
        labels: ['Diagnóstico', 'Diagnóstico associado ao atestado', 'Dias de atestado', 'Data de início', 'Data do fim'],
        tableTitles: ['Tipo', 'Dias de atestado', 'Data de início', 'Data do fim'],
      },
      certificate: {
        title: 'Novo relatório médico',
        labels: ['Diagnóstico', 'Relatório médico:'],
        tableTitles: ['Tipo', 'Diagnóstico', 'Relatório médico'],
      },
      textAreaPlaceholder: '(Máximo 255 caracteres)',
    },
    addAnalysis: {
      title: 'Deve adicionar um comentário médico',
      tableTitle: 'Descrição',
      error: 'Deve escrever um comentário médico',
    }
  },
  seeCon: {
    principalTitle: 'Data da consulta',
    diagnose: 'Diagnósticos',
    orders: 'Pedidos',
    prescriptions: 'Prescrições',
    download: 'Baixar arquivo',
    professional: 'Profissional',
    errorDownload: 'Erro ao baixar o arquivo',
  },
  patientData: {
    tabs: ["Dados básicos", "Histórico de consultas"],
    fields: [
      "Nome",
      "Sobrenome",
      "Data de nascimento",
      "Idade",
      "Tipo de documento",
      "Número do documento",
      "Email",
      "Telefone",
      "Celular",
      "País",
      "Cidade",
      "Endereço",
      "Papel",
    ],
    backlabels: ['Deste', 'Até']
  },
  call: {
    noCamera: {
      text: "Sua câmera está desabilitada",
      button: "Ligar câmera",
      button2: "Desativar câmera",
    },
    requirements: {
      success: ["Consentimento confirmado ", "Pagamento efetuado"],
      error: ["Consentimento recusado ", "Pagamento pendente"],
    },
    enable: "Habilitar paciente",
    buttons: ["Iniciar consulta", "Iniciar chamada", "Mensagens", "Iniciar chat"],
    error: 'Periféricos não detectados. Verifique a conectividade ou permissões destes e atualize a página.',
    markUnattended: 'Marcar consulta como perdida',
    confirmUnattended: 'Tem certeza de que deseja marcar este compromisso como "Não atendido"?',
    errorCall: {
      title: 'Erro na videochamada',
      text: 'Ocorreu um erro ao conectar-se à videochamada. Você deseja ligar para o paciente por telefone.',
      buttons: ['Ligue para telefone', 'Tente Novamente', 'Cancelar'],
    },
    errorPeripherals: {
      title: 'Erro periférico',
      text: 'Lamentamos, mas ocorreu um erro com seus periféricos de áudio ou vídeo. Verifique se tudo está conectado corretamente e tente novamente. Se o problema persistir, entre em contato com nosso suporte técnico para resolver o problema o mais rápido possível.',
      buttons: ['Ligue para telefone', 'Tente Novamente', 'Cancelar'],
    },
  },
  history: {
    title: "Informação da consulta",
    subtitle: "Clique no nome do paciente para ver informações da consulta",
  },
  userList: {
    placeholder: 'Procurar',
    tableTitles: ['Sobrenome', 'Nome', 'Idade', 'Número de Identidade', 'Sexo', 'Telefone', 'Cidade', 'Endereço', 'Ações'],
    dropdownOptions: ['Agendar consulta virtual (Telemedicina)', 'Agendar consulta presencial', 'Agendar consulta domiciliar'],
    searchOptions: ['Nome', 'Sobrenome', 'Número de Identidade', 'Email', 'Telefone', 'País'],
    optionsLabel: 'Procura por',
    errorSearch: 'Você deve escolher uma opção para pesquisar',
    errorhour: 'Você deve escolher um dos horários',
    errorTimezone: 'Você deve escolher um fuso horário',
    modalftf: {
      title: 'Agendar consulta presencial',
      step: ['Passo', 'de 3'],
      services: {
        title: 'Qual serviço você deseja?',
        items: ['Consulta médica', 'Exames', 'Vacinas']
      },
      appointmentTitles: ['Quem será atendido?', 'Médico', 'Data da consulta', 'Horário da consulta', 'Data e hora locais da consulta'],
      doctorsTitle: 'Qual profissional você deseja consultar?',
      dateTitle: 'Escolha a data da sua consulta',
      scheduleTitle: 'Escolha o horário da sua consulta',
      timezone: 'Insira a cidade do fuso horário',
      buttons: {
        cancel: 'Cancelar',
        back: 'Voltar',
        next: 'Próximo',
        add: 'Agendar consulta',
      },
      months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      success: 'Sua consulta presencial foi agendada com sucesso!',
    },
    modalVirtual: {
      title: 'Agendar consulta virtual',
      success: 'A consulta virtual foi agendada com sucesso!',
    },
    modalHome: {
      title: 'Agendar consulta domiciliar',
      success: 'A consulta ao domicílio foi agendada com sucesso!',
    },
    footer: 'no total',
  },
  reports: {
    tableTitles: ['Tipo', 'Custo', 'Qualidade', 'Total', 'Total de horas'],
  },
  notifications: {
    onWaitingRoom: {
      title: 'Paciente na sala de espera',
      text: 'O paciente está esperando por você na sala',
    },
    onCall: {
      title: 'Chamada iniciada',
      text: 'O paciente está te chamando',
    },
    onFinishedCall: {
      title: 'Chamada finalizada',
      text: 'O paciente desligou a chamada',
    },
    onFile: {
      title: 'Arquivo recebido',
      text: 'O paciente carregou um arquivo.',
    }
  },
  unAttended: {
    title: 'Qual foi o motivo pelo qual esta consulta não foi realizada?',
    options: ['Usuário não preencheu os requisitos', 'Usuário não respondeu', 'Número de telefone errado'],
    history: 'Histórico',
    confirmTitle: 'Você conseguiu atender o paciente por telefone?',
  },
  phonepad: {
    calling: 'Chamando',
  },
}; 