
import { defineComponent, ref } from 'vue'
import CallItem from './CallItem.vue'

export default defineComponent({
  nama: 'CallEntries',
  components: {
    CallItem
  },
  setup(props, { emit }) {
    let items = ref([{
      Id: 'abc123',
      UserName: 'Mia Colonia'
    }]);

    return { items }
  },
})
