<template>
  <div id="virtual-appointment">
    <div class="row">
      <div class="col s12">
        <user-info id="user-info" :username="user.Name + ' ' + user.LastName" :avatar="user.Avatar">
          <p> {{ $t('hceHeader.ageLabel') }}: {{ calculateAge(user.Birthdate) }} {{ $t('hceHeader.years') }} | {{user.Document.DocumentType}}: {{ user.Document.DocumentNumber }} | {{ user.Phone }} | {{ $t('hceHeader.company') }}: <span> {{ user.Properties.CompanyName }} </span></p>
        </user-info>
      </div>
      <div class="col s12 call-cont">
        <div class="call-data" id="call-container">
          <div id="local-media" class="camera-cont">
            <div v-if="!previewVideo">
              <img :src="require('@/assets/images/Camera.png')" alt="">
              <p>{{ $t('call.noCamera.text') }}</p>
              <button class="waves-effect waves-light btn" @click="preview">{{ $t('call.noCamera.button') }} </button>
            </div>
            <div id="disable-cam" v-else>
              <button class="waves-effect waves-light btn" @click="preview">{{ $t('call.noCamera.button2') }} </button>
            </div>
          </div>
          <div class="requirements">
            <div class="requirement" v-if="consent == 'true'">
              <i class="material-icons check-box">check_box</i>
              <p> {{ $t('call.requirements.success[0]') }} </p>
            </div>
            <div class="requirement" v-else-if="consent == 'false'">
              <i class="material-icons cancel">cancel</i>
              <p> {{ $t('call.requirements.error[0]') }} </p>
            </div>
            <div class="requirement" v-else>
              <i class="material-icons error">error</i>
              <p> Consentimiento informado sin aceptar </p>
            </div>
            <div class="requirement" v-if="quota">
              <i class="material-icons check-box">check_box</i>
              <p> {{ $t('call.requirements.success[1]') }} </p>
            </div>
            <div class="requirement" v-else>
              <i class="material-icons error">error</i>
              <p> {{ $t('call.requirements.error[1]') }} </p>
            </div>
          </div>

          <div class="row buttons" v-if="!finished">
            <div class="col s12">
              <button class="waves-effect waves-light btn" @click="goToCall" :class="{ disabled : !online, available: online && consent === 'true' && quota }"><i class="material-icons">phone</i>
                {{ $t('call.buttons[0]') }} </button>
            </div>
            <div class="col s12">
              <button id="message-btn" class="waves-effect waves-light btn" @click="openChat" :disabled="!online"><i class="material-icons">mail</i>
                {{ $t('call.buttons[2]') }}
              </button>
            </div>
          </div>

          <div class="unattended-check d-flex">
            <p>{{ $t('call.markUnattended') }}</p>
            <div class="check-box" @click="unattendCall"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import store from '@/store'
  import UserInfo from './UserInfo.vue'
  import { testCamera } from '../../../../core/vonage'
  import { database } from '@/core/firebase'
  import { set, get, ref, update } from '@firebase/database'
  import { httpKeycV1 } from '@/core/http';

  export default({
    components: {
      UserInfo
    },
    name: 'VirtualAppointment',
    data() {
      return {
        enable: false,
        roomId: '',
        callType: null,
        previewVideo: null,
        active: false,
        finished: false,
      }
    },
    props: {
      user: Object,
      online: Boolean,
      appointment: Object,
    },
    computed: {
      userToken() {
        return store.getters.getToken;
      },
      notifications() {
        return store.getters.getNotifications
      },
      notiLength() {
        return store.getters.getNotifications.length
      },
      quota() {
        return this.appointment.quota.trim().toLowerCase() == 'true'
      },
      consent() {
        return this.appointment.consent.trim().toLowerCase()
      },
    },
    watch: {
      notiLength() {
        this.activeCall()
      },
      appointment() {
        this.active = false
        this.enable = false
        this.verifyEnable();
      }
    },
    methods: {
      calculateAge(dateString) {
        var birthday = new Date(dateString);
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      },

      activeCall() {
        for (let i = 0; i < this.notifications.length; i++) {
          const n = this.notifications[i];
          if(n.data.notificationCode == "VD001" && n.data.appointmentId === this.appointment.id) {
            this.callType = n.data.callType
            this.roomId = n.data.twilioCallRoom
            this.enable = true
            if(!this.active) this.active = true;
            break;
          }
        }
      },

      verifyEnable() {
        for (let i = 0; i < this.notifications.length; i++) {
          const n = this.notifications[i];
          if(n.data.notificationCode == "DT001" && n.data.appointmentId === this.appointment.id) {
            this.active = true;
            break;
          }
        }
      },

      async enableCall() {
        const { id, user, specialty } = this.appointment;
        let notiref = ref(database, 'notification/'+id);

        const notification = {
          data: {
            notificationCode: "DT001",
            title: "The doctor is ready!",
            message: "The doctor is waiting in the consulting room",
            appointmentId: id,
          },
          properties: {
            groupId: "calls",
            order: 2,
            showNotification: true,
            viewed: false,
          },
          timestamp: new Date().toISOString(),
        };

        let notiSnapshot = await get(notiref);

        if(!notiSnapshot.exists()) {
          await set(notiref, {
            Id: id,
            PatientId: user.Id,
            DoctorId: store.getters.getUser.Id,
            Specialty: specialty,
            AppId: store.getters.getPlan.AppId,
            CompanyId: store.getters.getCompany.Id, 
            PlanId: store.getters.getPlan.Id,
            Notifications: []
          })
        };

        const notifications = notiSnapshot.val().Notifications ? notiSnapshot.val().Notifications : [];
        notifications.push(notification);
        await update(notiref, {
          Notifications: notifications
        });
      },

      async enablePatient() {
        await this.enableCall();
        const headers = {
          'Authorization': `Bearer ${this.userToken}`,
          'appid': process.env.VUE_APP_APP_ID,
          'companyid': process.env.VUE_APP_COMPANY_ID,
          'Content-Type': 'application/json'
        };

        let id = this.appointment.id

        try {
          await axios.post(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/v1/notification/doctorcallacceptance', id, {
            headers,
          }).then((response) => {
            console.log(response)
          })
        } catch (error) {
          console.log(error)
        }
      },
      preview() {
        if(!this.previewVideo) {
          this.previewVideo = testCamera();
        } else {
          this.previewVideo.destroyPublisher();
          this.previewVideo = null;
        }
      },
      async goToCall() {
        await this.enablePatient();
        let params = { token: this.userToken, type: this.callType, appointmentId: this.appointment.id, specialty: this.appointment.specialty, patientId: this.appointment.user.Id }
        this.$emit('open-call', params)
      },
      async markUnAttended() {
        const body = {
          StatusId: "5",
          AppointmentId: this.appointment.id,
          Annotation: '',
          UserId: this.appointment.user.Id,
          CompanyId: process.env.VUE_APP_COMPANY_ID,
          AppId: process.env.VUE_APP_APP_ID,
        }

        try {
          await httpKeycV1.post('/api/agenda/appointment-status', body).then((response) => {
            console.log(response)
            if (response.status == 200) {
              this.$emit('unattended', this.appointment)
            }
          })
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },
      unattendCall() {
        this.$confirm.require({
          message: this.$t('call.confirmUnattended'),
          acceptClass: 'btn-text go',
          rejectClass: 'btn-text cancel',
          acceptLabel: this.$t('callExit.validate.buttons[1]'),
          rejectLabel: this.$t('callExit.validate.buttons[0]'),
          accept: () => {
          },
          reject: async () => {
            await this.markUnAttended();
          }
        }) 
      },
      openChat() {
        this.$emit('open-chat')
      },
    },
    mounted() {
      this.activeCall()
      this.verifyEnable();
      if (this.appointment.status == 'Attended') {
        this.finished = true
      } else {
        this.finished = false
      }
    },
    updated() {
      this.activeCall()
      if (this.appointment.status == 'Attended') {
        this.finished = true
      } else {
        this.finished = false
      }
    },
  })
</script>

<style scoped>
  #virtual-appointment {
    height: 100%;
    overflow: hidden;
  }

  #virtual-appointment>.row {
    height: 100%;
  }

  .call-cont {
    height: calc(100% - 87px);
    overflow-y: auto;
  }

  .title {
    background-color: #EFF0F4;
    padding-left: 30px;
    height: 60px;
    display: flex;
    align-items: center;
  }
  .title h3 {
    margin: 0;
  }

  .material-icons {
    margin-right: 6px;
  }

  .requirement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
  }

  .requirement p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .call-data {
    justify-content: center;
  }

  .camera-cont p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #F6F6F8;
  }

  .camera-cont button {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary);
    background-color: #F6F6F8;
    border: 1px solid #C4C9D1;
  }

  button {
    border-radius: 8px;
    box-shadow: none;
    font-weight: 500;
  }

  .buttons .btn {
    background-color: var(--primary);
    color: #FBFCFF !important;
    width: 60%;
    margin-bottom: 12px;
    height: 40px;
    border-radius: 30px;
  }

  .buttons .btn.disabled {
    background-color: #E6F1F8 !important;
    color: #A5B3BB !important;
  }

  .buttons .btn.available {
    background-color: #2EE57C !important;
    color: #FBFCFF !important;
  }

  .buttons #message-btn {
    background-color: transparent !important;
    color: #3D4346 !important;
    border: 1px solid #A5B3BB;
  }

  .switch-cont {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .switch-cont span {
    margin-right: 20px;
  }

  #user-info {
    cursor: unset;
    border-bottom: none !important;
  }

  #user-info:deep(h3) {
    font-size: 18px !important;
  }

  #user-info p {
    font-weight: 400;
    color: #757879;
  }

  #local-media {
    position: relative;
  }

  #disable-cam {
    position: absolute;
    bottom: 10px;
  }

  .unattended-check {
    justify-content: center;
  }

  .unattended-check .check-box {
    width: 17px;
    height: 17px;
    background: #CAD8DF;
    border-radius: 2px;
    margin: 3px 0px  30px 7px;
    cursor: pointer;
  }
  
  span {
    color: var(--primary);
    font-weight: 600;
  }
</style>