import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78ef9ce3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "agenda-tabs" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col s12" }
const _hoisted_4 = { class: "tabs" }
const _hoisted_5 = { class: "tab col s3" }
const _hoisted_6 = { href: "#consultation" }
const _hoisted_7 = { class: "tab col s3" }
const _hoisted_8 = { href: "#basic" }
const _hoisted_9 = { class: "tab col s3" }
const _hoisted_10 = { href: "#historical" }
const _hoisted_11 = {
  class: "col s12",
  id: "consultation"
}
const _hoisted_12 = {
  class: "col s12",
  id: "basic"
}
const _hoisted_13 = {
  id: "historical",
  class: "col s12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_call = _resolveComponent("call")!
  const _component_patient_data = _resolveComponent("patient-data")!
  const _component_history = _resolveComponent("history")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t('agendaTabs[0]')), 1)
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('agendaTabs[1]')), 1)
          ]),
          _createElementVNode("li", _hoisted_9, [
            _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.$t('agendaTabs[2]')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_call, { user: _ctx.user }, null, 8, ["user"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_patient_data, { user: _ctx.user }, null, 8, ["user"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_history)
      ])
    ])
  ]))
}