<template>
  <div id="reports">
    <div class="row">
      <div class="col s12">
        <div class="row">

          <div class="col s4">
            <div class="stat d-flex">
              <img :src="require('@/assets/images/ASchedule.png')" alt="schedule">
              <div>
                <p class="left-align">Citas agendadas</p>
                <h1 class="left-align"> {{ doctorReport.length }} </h1>
              </div>
            </div>
          </div>

          <div class="col s4">
            <div class="stat d-flex">
              <img :src="require('@/assets/images/AComplete.png')" alt="schedule">
              <div>
                <p class="left-align">Citas completadas</p>
                <h1 class="left-align"> {{ completed }} </h1>
              </div>
            </div>
          </div>

          <div class="col s4">
            <div class="stat d-flex">
              <img :src="require('@/assets/images/ANoComplete.png')" alt="schedule">
              <div>
                <p class="left-align">Citas no completadas</p>
                <h1 class="left-align">{{ doctorReport.length - completed }}</h1>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col s12">
        <div class="report">
          <h3 class="left-align">Reporte de {{ month }}</h3>
          <Chart ref="chartStats" type="line" :data="chartData" :options="chartOptions" />
          <div class="row">
            <div class="col s10">
              <div class="d-flex range-inputs">
                <h3>Rango de fechas:</h3>
                <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" class="range" @dayclick="dayClick">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="row">
                      <div class="col s6">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="rounded-input"
                        />
                      </div>
                      <div class="col s6">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="rounded-input"
                        />
                      </div>                   
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="col s2">
              <button class="waves-effect waves-light btn export" @click="downloadFile">Exportar</button>
            </div>
            <div class="col s12" v-if="false">
              <div class="fix-width scroll-inner">
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t("reports.tableTitles[0]") }}</th>
                      <th>{{ $t("reports.tableTitles[1]") }}</th>
                      <th>{{ $t("reports.tableTitles[2]") }}</th>
                      <th>{{ $t("reports.tableTitles[3]") }}</th>
                      <th>{{ $t("reports.tableTitles[4]") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Jesus Beltran</td>
                      <td>30.000 COP</td>
                      <td>21</td>
                      <td>30.000 COP</td>
                      <td>1.5 Horas</td>
                    </tr>
                    <tr class="last-row">
                      <td><b>Total:</b></td>
                      <td></td>
                      <td><b>21</b></td>
                      <td></td>
                      <td><b>1.5 Horas</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col s12" v-if="false">
              <button class="waves-effect waves-light btn see-info">ver citas</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import store from '@/store'
import exportFromJSON from "export-from-json";
export default {
  setup() {
    const chartStats = ref()

    const addChartData = (chart, label, data) => {
      chart.data.labels.push(label)
      chart.data.datasets[0].data.push(data)
      chart.update()
    }

    const clearChartData = (chart) => {
      chart.data.labels = []
      chart.data.datasets[0].data = []
    }

    return {
      chartStats,
      addChartData,
      clearChartData,
      chartData: {
        labels: [],
        datasets: [{
          label: '',
          data: [],
          fill: false,
          borderColor: '#62B4FF',
          tension: 0
        }]
      },
    }
  },
  mounted() {
    this.dayClick();
  },
  data() {
    return {
      chartOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#4F4F4F'
            }
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Fecha',
              color: '#798291',
              padding: 20,
              font: {
                size: 18,
                weight: '500'
              },
            },
            ticks: {
              color: '#979797',
            },
            grid: {
              color: '#EFF3F9',
              borderColor: '#07EE6A'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Numero de citas',
              color: '#242A33',
              padding: 20,
              font: {
                size: 18,
                weight: '500'
              },
            },
            ticks: {
              color: '#979797',
            },
            grid: {
              color: '#EFF3F9',
            }
          }
        }
      },
      range: {
        start: new Date('2022-01-02'),
        end: new Date()
      },
      doctorReport: [],
      dataChart: [],
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    userToken() {
      return store.getters.getToken;
    },
    completed() {
      let sum = 0;
      for (let i = 0; i < this.doctorReport.length; i++) {
        const element = this.doctorReport[i];
        for (let j = 0; j < element.status.length; j++) {
        const e = element.status[j];
        if (e.id_status === "4") sum++;
        }
      }
      return sum
    },
    month() {
      let start = this.range.start;
      let end = this.range.end;
      let date = new Date();
      start.setHours(0,0,0,0)
      end.setHours(0,0,0,0)
      date.setHours(0,0,0,0)
      let text = ''
      if(start.getTime() === date.getTime() && end.getTime() === date.getTime()) text = 'hoy'
      else if(start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) text = start.toLocaleString('default', { month: 'long' }) + ' ' + start.getFullYear()
      else text = start.toLocaleString('default', { month: 'long' }) + ' ' + start.getFullYear() + ' - ' + end.toLocaleString('default', { month: 'long' }) + ' ' + end.getFullYear()
      return text
    }
  },
  methods: {
    async dayClick() {
      await this.getSpecificDoctorReports();
      this.setChartData();
    },
    setChartData() {
      this.dataChart = []
      this.clearChartData(this.chartStats.chart)
      let data = this.doctorReport;
      data = data.sort((a, b) => { return new Date(a.schedule.dateSchedule) - new Date(b.schedule.dateSchedule)})
      for (let d = this.range.start; d <= this.range.end; d.setDate(d.getDate() + 1)) {
        let date = new Date(d)
        date.setHours(0,0,0,0)
        let sum = 0;
        for (let i = 0; i < data.length; i++) {
          let scheduleDate = new Date(data[i].schedule.dateSchedule)
          scheduleDate.setHours(0,0,0,0)
          if(scheduleDate.getTime() === date.getTime()) sum++;
        }
        if(sum > 0) {
          this.dataChart.push({
            date,
            cont: sum,
          })
        }
      }

      for (let i = 0; i < this.dataChart.length; i++) {
        const e = this.dataChart[i];
        this.addChartData(this.chartStats.chart, e.date.getDate() + ' ' + e.date.toLocaleString('default', { month: 'short' }), e.cont)
      }
    },
    downloadFile() {
      const data = this.doctorReport;
      const fileName = 'doctor-report';
      const exportType = exportFromJSON.types.csv;

      exportFromJSON({ data, fileName, exportType })
    },
    async getVideoReports() {
      const headers = {
        'Authorization': `Bearer ${this.userToken}`,
      };
  
      let response = await axios.get(process.env.VUE_APP_BOPS_REP_V1_API_URL + '/api/video', {
        headers,
      });
      console.log(response.data);
    },
    async getEmailReports() {
      const headers = {
        'Authorization': `Bearer ${this.userToken}`,
      };
  
      let response = await axios.get(process.env.VUE_APP_BOPS_REP_V1_API_URL + '/api/email', {
        headers,
        params: {
          company: process.env.VUE_APP_COMPANY_ID,
        }
      });
      console.log(response.data);
    },
    async getSpecificDoctorReports() {
      const headers = {
        'Authorization': `Bearer ${this.userToken}`,
        'appid': process.env.VUE_APP_APP_ID,
        'companyid': process.env.VUE_APP_COMPANY_ID,
      };
  
      let response = await axios.get(process.env.VUE_APP_BOPS_REP_V1_API_URL + '/api/doctor/specific', {
        headers,
        params: {
          from: this.convertDate(this.range.start),
          to: this.convertDate(this.range.end),
        }
      });

      this.doctorReport = response.data;
    },
    async getDoctorReports() {
      const headers = {
        'Authorization': `Bearer ${this.userToken}`,
        'appid': process.env.VUE_APP_APP_ID,
        'companyid': process.env.VUE_APP_COMPANY_ID,
      };
  
      let response = await axios.get(process.env.VUE_APP_BOPS_REP_V1_API_URL + '/api/doctor', {
        headers,
        params: {
          from: '2022-01-01',
          to: '2022-10-28',
        }
      });
      console.log(response.data);
    },
    async getCompanyReports() {
      const headers = {
        'Authorization': `Bearer ${this.userToken}`,
        'appid': process.env.VUE_APP_APP_ID,
        'companyid': process.env.VUE_APP_COMPANY_ID,
      };
  
      let response = await axios.get(process.env.VUE_APP_BOPS_REP_V1_API_URL + '/api/compensar', {
        headers,
      });
      console.log(response.data);
    },
    convertDate(date) {
      return date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate())
    },
    addZero(date) {
      if ((date / 10) < 1)
        return '0' + date

      return date
    },
  }
}
</script>

<style scoped>
  #reports {
    margin-left: 67px;
    padding: 24px;
  }

  .report, .stat {
    box-shadow: 0px 4px 18px rgba(208, 210, 211, 0.6);
    border-radius: 15px;
  }

  .stat {
    align-items: center;
    padding: 30px 35px;
  }

  .stat img {
    margin-right: 28px;
  }

  .stat p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #798291;
  }

  .stat h1 {
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
    color: #242A33;
    margin: 0 !important;
  }

  .report {
    padding: 35px;
  }

  .report h3 {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #242A33;
  }

  .last-row {
    border-bottom: none !important;
  }

  .export {
    background: #454B55 !important;
    border-radius: 8px;
  }

  .see-info {
    background: #E4E7EC;
    border-radius: 8px;
    color: #242A33;
    width: 100%;
    font-weight: 600;
  }

  .range-inputs {
    align-items: center;
  }

  .range-inputs .range {
    width: 80%;
  }

  .range-inputs h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #798291;
    margin-right: 15px !important;
  }

  .range-inputs .row,
  .range-inputs input {
    margin-bottom: 0 !important;
  }

  .range-inputs input {
    border-radius: 8px !important;
  }
</style>