import axios from "axios";
import store from "@/store";

export const getUserData = async (token: any) => {
  const response = await axios.get(
    process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/user",
    {
      headers: {
        Authorization: `Bearer ${token}`,
        appid: process.env.VUE_APP_APP_ID,
        companyid: process.env.VUE_APP_COMPANY_ID,
      },
    }
  );

  const user = response.data
  store.commit('SET_USER', user)
};
