import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa4a1e64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "patient-data" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col s12" }
const _hoisted_4 = { class: "tabs" }
const _hoisted_5 = { class: "tab col s6" }
const _hoisted_6 = { href: "#basic" }
const _hoisted_7 = { class: "tab col s6" }
const _hoisted_8 = { href: "#historical" }
const _hoisted_9 = {
  class: "col s12",
  id: "basic"
}
const _hoisted_10 = { id: "basic-info" }
const _hoisted_11 = { class: "info-field" }
const _hoisted_12 = { class: "title-field" }
const _hoisted_13 = { class: "data" }
const _hoisted_14 = { class: "info-field" }
const _hoisted_15 = { class: "title-field" }
const _hoisted_16 = { class: "data" }
const _hoisted_17 = { class: "info-field" }
const _hoisted_18 = { class: "title-field" }
const _hoisted_19 = { class: "data" }
const _hoisted_20 = { class: "info-field" }
const _hoisted_21 = { class: "title-field" }
const _hoisted_22 = { class: "data" }
const _hoisted_23 = { class: "info-field" }
const _hoisted_24 = { class: "title-field" }
const _hoisted_25 = { class: "data" }
const _hoisted_26 = { class: "info-field" }
const _hoisted_27 = { class: "title-field" }
const _hoisted_28 = { class: "data" }
const _hoisted_29 = { class: "info-field" }
const _hoisted_30 = { class: "title-field" }
const _hoisted_31 = { class: "data" }
const _hoisted_32 = { class: "info-field" }
const _hoisted_33 = { class: "title-field" }
const _hoisted_34 = { class: "data" }
const _hoisted_35 = { class: "info-field" }
const _hoisted_36 = { class: "title-field" }
const _hoisted_37 = { class: "data" }
const _hoisted_38 = { class: "info-field" }
const _hoisted_39 = { class: "title-field" }
const _hoisted_40 = { class: "data" }
const _hoisted_41 = { class: "info-field" }
const _hoisted_42 = { class: "title-field" }
const _hoisted_43 = { class: "data" }
const _hoisted_44 = { class: "info-field" }
const _hoisted_45 = { class: "title-field" }
const _hoisted_46 = { class: "data" }
const _hoisted_47 = {
  id: "historical",
  class: "col s12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_History = _resolveComponent("History")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t('patientData.tabs[0]')), 1)
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('patientData.tabs[1]')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('patientData.fields[0]')), 1),
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.user.Name), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('patientData.fields[1]')), 1),
            _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.user.LastName), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('patientData.fields[2]')), 1),
            _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.user.Birthdate.split('T')[0]), 1)
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t('patientData.fields[3]')), 1),
            _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.calculateAge(_ctx.user.Birthdate)), 1)
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('patientData.fields[4]')), 1),
            _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.user.Document.DocumentType), 1)
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.$t('patientData.fields[5]')), 1),
            _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.user.Document.DocumentNumber), 1)
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('patientData.fields[6]')), 1),
            _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.user.Email), 1)
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.$t('patientData.fields[7]')), 1),
            _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.user.Phone), 1)
          ]),
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.$t('patientData.fields[8]')), 1),
            _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.user.Phone), 1)
          ]),
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.$t('patientData.fields[9]')), 1),
            _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.user.Country), 1)
          ]),
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.$t('patientData.fields[10]')), 1),
            _createElementVNode("p", _hoisted_43, _toDisplayString(_ctx.user.City), 1)
          ]),
          _createElementVNode("div", _hoisted_44, [
            _createElementVNode("p", _hoisted_45, _toDisplayString(_ctx.$t('patientData.fields[11]')), 1),
            _createElementVNode("p", _hoisted_46, _toDisplayString(_ctx.user.Address), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_47, [
        _createVNode(_component_History)
      ])
    ])
  ]))
}