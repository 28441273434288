
  import {
    defineComponent
  } from 'vue'

  export default defineComponent({
    name: 'BopSwitch',
    data() {
      return {
        switchState: false,
      }
    },
    props: {
      label: String,
      initialState: Boolean,
    },
    mounted() {
      this.switchState = this.initialState ? this.initialState : false
    }
  })
