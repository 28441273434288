
import { defineComponent } from 'vue'

interface CallInfo {
  Id: string,
  UserName: string,
}

export default defineComponent({
  nama: 'CallItem',
  props: {
    CallInfo: {
      type: Object as () => CallInfo,
      required: true,
    }
  },
  setup(props, { emit }) {
    
  },
})
