<template>
  <div class="page-container">
    <Login />
  </div>
</template>

<script>
import Login from "../../components/themes/1/Login/Login.vue";
export default {
  name: "LoginPage",
  components: {
    Login,
  },
};
</script>
