<template>
  <div id="schedule">
    <div class="row row-cont d-flex">
      <div class="agenda">
        <ProgressSpinner v-if="load" style="margin-top: 100px;"/>
        <div class="row" v-show="!load">
          <div class="col s12">
            <ul class="collapsible">
              <li>
                <div class="collapsible-header title">
                  <h3>{{ $t('schedule.calendarTitle') }}</h3> <i class="material-icons">keyboard_arrow_down</i>
                </div>
                <div class="collapsible-body">
                  <div class="calendar">
                    <v-date-picker :locale="locale" class="calendar-bops" title-position="left" v-model="dateRange" is-expanded is-range
                      @dayclick="getAgenda" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col s12">
            <ul class="tabs">
              <li class="tab col s3"><a href="#actual"> {{ $t('callsTabs[0]') }} </a></li>
              <li class="tab col s3" @click="select(0)"><a class="active" href="#upcoming"> {{ $t('schedule.tabs[0]') }} </a></li>
              <li class="tab col s3" @click="select(1)"><a href="#finished"> {{ $t('schedule.tabs[1]') }} </a></li>
              <li class="tab col s3" @click="select(2)"><a href="#unattended"> {{ $t('schedule.tabs[2]') }} </a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tabs-cont" v-show="!load">
        <div id="actual" class="col s12" :class="{ noRoom: !hasSession }">
          <div id="call-view-loader" v-if="isWaiting">
            <div class="row">
              <div class="col s12">
                <h3>{{ $t('schedule.loadingCallText') }}</h3>
                <ProgressSpinner />
              </div>
            </div>
          </div>
          <div id="call-view" v-else-if="!isWaiting && hasSession">
            <div id="remotevideo">
              <div id="localvideo" class="cont-cam"></div>
            </div>
            <div class="controls d-flex">
              <button class="waves-effect waves-light btn btn-text"><i
                  class="material-icons">radio_button_checked</i></button>  
              <button class="waves-effect waves-light btn btn-text" v-if="cam" @click="changeCam(false)"><i
                  class="material-icons">videocam</i></button>
              <button class="waves-effect waves-light btn btn-text" v-else @click="changeCam(true)"><i
                  class="material-icons">videocam_off</i></button>
              <button class="waves-effect waves-light btn btn-text" v-if="mic" @click="changeMic(false)"><i
                  class="material-icons">mic</i></button>
              <button class="waves-effect waves-light btn btn-text" v-else @click="changeMic(true)"><i
                  class="material-icons">mic_off</i></button>
              <button class="waves-effect waves-light btn btn-text"><i
                  class="material-icons">note_add</i></button>   
              <button class="waves-effect waves-light btn btn-text disconnect" @click="disconnect"><i
                  class="material-icons">call_end</i></button>              
            </div>
          </div>
          <div class="no-call d-flex" v-else-if="!isWaiting">
            <img :src="require('@/assets/images/NoCall.png')" alt="">
            <h3>{{ $t('schedule.noAppointment') }}</h3>
          </div>
        </div>
        <div id="upcoming" class="col s12">
          <user-info v-for="appointment in agenda" :key="appointment.id" class="info-appointment"
             :active="appointment.active" :username="appointment.user.Name + ' ' + appointment.user.LastName" :appointmentId="appointment.id" :avatar="appointment.user.Avatar" :subtitle="appointment.date.split('T')[0].replaceAll('-', '/') + ' - ' + schedule(appointment)" :type="appointment.type" @click="answerCall(appointment)" :online="appointment.online" :status="userStatus(appointment)">
          </user-info>
        </div>
        <div id="finished" class="col s12">
          <user-info v-for="appointment in finished" :key="appointment.id" class="info-appointment"
             :active="appointment.active" :username="appointment.user.Name + ' ' + appointment.user.LastName" :appointmentId="appointment.id" :avatar="appointment.user.Avatar" :subtitle="appointment.date.split('T')[0].replaceAll('-', '/') + ' - ' + schedule(appointment)" :type="appointment.type" @click="seeFinishedCall(appointment)">
          </user-info>
        </div>
        <div id="unattended" class="col s12">
          <user-info v-for="appointment in unattended" :key="appointment.id" class="info-appointment"
             :active="appointment.active" :username="appointment.user.Name + ' ' + appointment.user.LastName" :appointmentId="appointment.id" :avatar="appointment.user.Avatar" :subtitle="appointment.date.split('T')[0].replaceAll('-', '/') + ' - ' + schedule(appointment)" :type="appointment.type" @click="notAnswered(appointment)">
          </user-info>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserInfo from './UserInfo.vue'
  import { joinSession, setAudio, setVideo, hangUpSession } from '../../../../core/vonage'
  import { ref, get, update } from '@firebase/database'
  import { database } from '@/core/firebase'
  import store from '@/store'
  import ProgressSpinner from 'primevue/progressspinner';

  export default({
    name: 'Schedule',
    components: {
      UserInfo,
      ProgressSpinner,
    },
    props: {
      agenda: Array,
      finished: Array,
      unattended: Array,
      callParams: Object,
      loading: Boolean,
      changed: Boolean,
      toFinished: Boolean,
    },
    data() {
      return {
        dateRange: {
          start: new Date(),
          end: new Date(),
        },
        cam: true,
        mic: true,
        room: null,
        hasCall: false,
        load: false,
        session: null,
        hasSession: null,
        isWaiting: false
      }
    },
    watch: {
      notiLength() {
        this.activeCall();
        this.disconnectCall();
      },
      loading(val) {
        this.load = val
      },
      callParams(val) {
        if(val != null) {
          this.isWaiting = true;
          var tab = document.querySelector(".tabs");
          var instance = M.Tabs.getInstance(tab)
          instance.select('actual')

          var collap = document.querySelector('.collapsible');
          var collapInst = M.Collapsible.getInstance(collap)
          collapInst.close(0)
        }
      },
      changed(val) {
        if (val) {
          var tab = document.querySelector(".tabs");
          var instance = M.Tabs.getInstance(tab);
          instance.select('unattended')
        }
      },
      toFinished(val) {
        if (val) {
          var tab = document.querySelector(".tabs");
          var instance = M.Tabs.getInstance(tab);
          instance.select('finished')
        }
      }
    },
    computed: {
      locale() {
        return this.$i18n.locale
      },
      notifications() {
        return store.getters.getNotifications.sort((a, b) => { return b.date - a.date })
      },
      notiLength() {
        return store.getters.getNotifications.length
      },
    },
    methods: {
      async activeCall() {
        if(this.callParams) {
          let { appointmentId } = this.callParams
          let isFinished = await this.isFinished(appointmentId);
          if(isFinished) return;
          
          for (let i = 0; i < this.notifications.length; i++) {
            const n = this.notifications[i];
            if(n.data.notificationCode == "VD001" && n.data.appointmentId === appointmentId && !this.hasSession) {
              this.hasSession = true;
              this.isWaiting = false;
              this.session = await joinSession(n.data.twilioCallRoom);
              this.session.session.on('sessionDisconnected', () => {
                this.hasSession = false;
                this.session = null;
                this.$emit('end-call');
              })
              break;
            }
          }
        }
      },
      getAgenda() {
        let from = new Date(this.dateRange.start.setHours(0, 0, 0)).toISOString().split('.')[0].replaceAll('-', '/');
        let to = new Date(this.dateRange.end.setHours(23, 59, 59)).toISOString().split('.')[0].replaceAll('-', '/');

        const params = {
          from: from,
          to: to,
        }

        this.$emit('getAgenda', params)
      },

      changeMic(state) {
        this.mic = state;
        setAudio(this.session.publisher, state);
      },

      changeCam(state) {
        this.cam = state;
        setVideo(this.session.publisher, state);
      },

      disconnectCall() {
        if(this.callParams) {
          let { appointmentId } = this.callParams
          for (let i = 0; i < this.notifications.length; i++) {
            const n = this.notifications[i];
            if(n.data.notificationCode == "VD002" && n.data.appointmentId === appointmentId) {
              hangUpSession(this.session.session);          
            }
          }
        }
      },
      
      disconnect() {
        return this.$confirm.require({
          message: this.$t('callExit.validate.text'),
          acceptClass: 'btn-text go',
          rejectClass: 'btn-text cancel',
          acceptLabel: this.$t('callExit.validate.buttons[1]'),
          rejectLabel: this.$t('callExit.validate.buttons[0]'),
          accept: () => {
          },
          reject: async () => {
            window.removeEventListener('beforeunload', this.beforeExit, true);
            await this.sendInteraction();
            hangUpSession(this.session.session);
            await this.notificationDisconnect();
            this.hasSession = false;
            this.session = null;
          }
        }) 
      },

      async isFinished(appointmentId) {
        const interactionReference = ref(database, 'interaction/' + appointmentId);

        const interactionSnapshot = await get(interactionReference);

        if(interactionSnapshot.exists()) {
          let interactions = interactionSnapshot.val().Interactions ? interactionSnapshot.val().Interactions : [];
          for (let i = 0; i < interactions.length; i++) {
            const e = interactions[i];
            if(e.Type == "endCall") return true;
          }
        }
        return false
      },

      async notificationDisconnect() {
        let { appointmentId } = this.callParams
        let notiref = ref(database, 'notification/'+appointmentId);

        const notification = {
          data: {
            notificationCode: "VD003",
            title: "Call finished",
            message: "Doctor finished the call",
          },
          properties: {
            groupId: "calls",
            order: 4,
            showNotification: false,
            viewed: false,
          },
          timestamp: new Date().toISOString(),
        };

        let notiSnapshot = await get(notiref);

        const notifications = notiSnapshot.val().Notifications;
        await update(notiref, {
          Notifications: [...notifications, notification]
        });
      },

      async sendInteraction() {
        let { appointmentId } = this.callParams
        const notiref = ref(database, 'interaction/' + appointmentId);

        const interaction = {
          StartedBy: store.getters.getUser.Id,
          Timestamp: new Date().toISOString(),
          Type: 'endCall'
        };

        await get(notiref).then((snap) => {
          let interactions = snap.val().Interactions;
          update(notiref, { Interactions: [...interactions, interaction] })
        })
      },

      beforeExit: function handler (event) {
        event.preventDefault();
        return event.returnValue = this.$t('callExit.validate.text')
      },

      schedule(appointment) {
        var start = this.convertHour(appointment.timestart)
        var end = this.convertHour(appointment.timeend)
        return start + ' - ' + end
      },

      convertHour(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        var strTime = this.addZero(hours) + ':' + this.addZero(minutes) + ' ' + ampm;
        return strTime;
      },

      answerCall(call) {
        this.$emit('answer', call);
      },
      seeFinishedCall(call) {
        this.$emit('finished', call);
      },
      notAnswered(call) {
        this.$emit('not-attended', call);
      },
      select(type) {
        this.$emit('change-tab', type)
      },
      userStatus(appointment) {
        if(appointment.quota && appointment.consent) {
          const quota = appointment.quota.trim().toLowerCase()
          const consent = appointment.consent.trim().toLowerCase()
          if (quota == 'true' && consent == 'true') {
            return 'success'
          }

          if (quota == 'false' || consent == 'not signed') {
            return 'warning'
          }

          if (consent == 'false') {
            return 'error'
          }
        }
      },
      convertDate(date) {
        return date.getFullYear() + '/' + this.addZero(date.getMonth() + 1) + '/' + this.addZero(date.getDate())
      },
      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },
    },
    mounted() {
      M.AutoInit();
      var tab = document.querySelector(".tabs");
      M.Tabs.init(tab);
      var instance = M.Tabs.getInstance(tab)
      instance.select('upcoming')

      var collap = document.querySelectorAll('.collapsible');
      M.Collapsible.init(collap);

      this.getAgenda()
    },
  })
</script>

<style scoped>
  #schedule,
  #schedule>.row {
    height: 100%;
  }

  .row-cont {
    flex-flow: column;
  }

  .agenda {
    flex: 0 1 auto;
  }

  .agenda .row {
    margin-bottom: 0 !important;
  }

  .tabs-cont {
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .title {
    background-color: #EFF0F4;
    padding-left: 30px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px 0 0 0;
  }

  .collapsible {
    margin: 0 !important;
    box-shadow: none;
    border: 0 !important;
  }

  .collapsible-header,
  .collapsible-body {
    border-bottom: 1px solid #E1E2E6;
  }

  .collapsible-body {
    padding: 1rem 2rem 0;
  }

  #call-view,
  #actual {
    height: 100%;
  }

  .controls {
    justify-content: center;
    background-color: #363B42;
    width: 350px;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .controls .btn {
    margin: 0 5px;
  }

  .controls .btn .material-icons {
    margin-right: 0 !important;
    line-height: 10px;
    height: 14px;
    font-size: 21px !important;
  }

  .controls .disconnect {
    color: #EE1919 !important;
  }

  #call-view-loader {
    margin-top: 75px;
    padding: 0 45px;
  }

  #call-view-loader h3 {
    margin-bottom: 30px !important;
  }

  #localvideo {
    width: 180px;
    height: 150px;
    margin: 0;
    background: url('../../../../assets/images/videocall-background.svg') no-repeat center center;
    background-size: cover;
    background-color: #242A33;
    position: absolute !important;
    bottom: 65px !important;
    right: 20px !important;
    z-index: 1000;
  }

  #remotevideo,
  .noRoom {
    height: 100%;
  }

  #remotevideo:deep(.media-container) {
    background: url('../../../../assets/images/videocall-background.svg') no-repeat center center;
    background-size: cover;
    height: 100%;
  }

  .no-call {
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 100%;
  }

  .no-call h3 {
    margin-top: 20px !important;
    font-size: 20px;
    line-height: 27px;
    color: #AAACAE;
  }
</style>