<template>
  <div id="overview">
    <div class="row">
      <div class="col s12">
        <div class="d-flex title-sec">
          <h3>
            <i class="material-icons-outlined"> monitor_heart </i>
            Vitales
          </h3>
        </div>
        <div class="d-flex">
          <overview-item :title="vitals.title" :unit="vitals.unit" :date="vitals.date" :value="vitals.value"></overview-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverviewItem from '../OverviewItem.vue'

export default {
  name: 'Overview',
  components: {
    OverviewItem
  },
  data() {
    return {
      vitals: {
        title: 'Temperatura',
        unit: 'C',
        date: 'Ene 17, 2019 08:00',
        value: '37.4'
      }
    }
  },
}
</script>

<style scoped>
  #overview {
    margin-left: 67px;
    padding: 24px;
    overflow: hidden;
    height: 90vh;
  }
</style>
