<template>
  <div id="call">
    <Dialog v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :closable="false"
      :style="{width: '50vw'}" :modal="true" :draggable="false">
      <h3 class="left-align"> {{ $t('callExit.reason.title') }} </h3>
      <Dropdown id="reason-exit" v-model="reason" :options="wrapUpCodes" optionLabel="Description" :emptyMessage="$t('newCon.addDocument.emptySelect')" />
      <template #footer>
        <button class="waves-effect waves-light btn btn-rounded btn-primary" @click="updateAppointment">{{ $t('callExit.reason.buttons[1]') }}</button>
      </template>
    </Dialog>
    <div class="row">
      <div class="col s12">
        <div class="title">
          <i class="material-icons">smartphone</i>
          <h3 class="left-align"> {{ user.Name }} {{ user.LastName }} ({{ user.Phone }})</h3>
        </div>
      </div>
      <div class="col s12">
        <div class="call-data" id="call-container">
          <div id="local-media" class="camera-cont">
            <div v-if="!previewVideo">
              <img :src="require('@/assets/images/Camera.png')" alt="">
              <p>{{ $t('call.noCamera.text') }}</p>
              <button class="waves-effect waves-light btn" @click="preview">{{ $t('call.noCamera.button') }} </button>
            </div>
          </div>
          <div class="requirements">
            <div class="requirement" v-if="consent">
              <i class="material-icons check-box">check_box</i>
              <p> {{ $t('call.requirements.success[0]') }} </p>
            </div>
            <div class="requirement" v-else>
              <i class="material-icons cancel">cancel</i>
              <p> {{ $t('call.requirements.error[0]') }} </p>
            </div>
            <div class="requirement" v-if="quota">
              <i class="material-icons check-box">check_box</i>
              <p> {{ $t('call.requirements.success[1]') }} </p>
            </div>
            <div class="requirement" v-else>
              <i class="material-icons error">error</i>
              <p> {{ $t('call.requirements.error[1]') }} </p>
            </div>
          </div>

          <div class="switch-cont d-flex" v-if="online && !hasCall && quota && consent">
            <span> {{ $t('call.enable') }} </span>
            <InputSwitch v-model="active" :disabled="active" @change="enablePatient"/>
          </div>

          <div class="row buttons" v-if="!hasCall && !finished">
            <div class="col s12">
              <button class="waves-effect waves-light btn" @click="goToCall" :class="{ disabled : !enable || !online }"><i class="material-icons">phone</i>
                {{ $t('call.buttons[0]') }} </button>
            </div>
            <div class="col s12">
              <button id="message-btn" class="waves-effect waves-light btn" @click="openChat"><i class="material-icons">mail</i>
                {{ $t('call.buttons[2]') }}
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import store from '@/store'
  import router from '@/router';
  import Dialog from 'primevue/dialog';
  import codes from "@/data/codes"
  import { previewCamera } from '@/core/twilio'

  export default({
    components: {
      Dialog
    },
    name: 'Call',
    data() {
      return {
        enable: false,
        roomId: '',
        callType: null,
        previewVideo: null,
        active: false,
        wrapUpCodes: [],
        displayModal: false,
        reason: null,
        finished: false,
        codes: codes,
        hasCall: false,
      }
    },
    props: {
      user: Object,
      online: Boolean,
      appointment: Object,
    },
    computed: {
      userToken() {
        return store.getters.getToken;
      },
      notifications() {
        return store.getters.getNotifications
      },
      notiLength() {
        return store.getters.getNotifications.length
      },
      quota() {
        return this.appointment.quota.trim().toLowerCase() == 'true'
      },
      consent() {
        return this.appointment.consent.trim().toLowerCase() == 'true'
      },
      realCodes() {
        const locale = this.$i18n.locale
        if (locale == 'es') {
          return this.codes.codesEs
        } else if (locale == 'en') {
          return this.codes.codesEn
        } else {
          return this.codes.codesPr
        }
      },
    },
    watch: {
      notiLength() {
        this.activeCall()
      },
    },
    methods: {
      activeCall() {
        for (let i = 0; i < this.notifications.length; i++) {
          const n = this.notifications[i];
          if(n.data.notificationCode == "VD001") {
            this.callType = n.data.callType
            this.roomId = n.data.twilioCallRoom
            this.enable = true
          }
        }
      },
      async enablePatient() {
        const headers = {
          'Authorization': `Bearer ${this.userToken}`,
          'appid': process.env.VUE_APP_APP_ID,
          'companyid': process.env.VUE_APP_COMPANY_ID,
          'Content-Type': 'application/json'
        };

        let id = this.appointment.id

        try {
          await axios.post(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/v1/notification/doctorcallacceptance', id, {
            headers,
          }).then((response) => {
            console.log(response)
          })
        } catch (error) {
          console.log(error)
        }
      },
      preview() {
        this.previewVideo = previewCamera()
      },
      goToCall() {
        let route = router.resolve({ name: 'CallView', query: { token: this.userToken, roomId: this.roomId, type: this.callType } })
        let call = window.open(route.href, "_blank", "width=450,height=600,top=100,status=no,location=no,toolbar=no,menubar=no")
        this.hasCall = true
        var timer = setInterval(() => { 
            if(call.closed) {
              clearInterval(timer);
              this.displayModal = true
            }
        }, 1000);
      },
      async updateAppointment() {
        if (!this.reason) {
          return M.toast({
            html: this.$t("callExit.reason.alert"),
          });
        }

        const headers = {
          'Authorization': `Bearer ${this.userToken}`,
          'appid': process.env.VUE_APP_APP_ID,
          'companyid': process.env.VUE_APP_COMPANY_ID,
        };

        const body = {
          StatusId: "4",
          AppointmentId: this.appointment.id,
          Annotation: this.reason.InternalCode
        }

        try {
          await axios.post(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/agenda/appointment-status', body, {
            headers,
          }).then((response) => {
            console.log(response)
            if (response.status == 200) {
              this.displayModal = false
              this.enable = false
              this.finished = true
              this.$emit('refresh-calls')
            }
          })
        } catch (error) {
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },
      openChat() {
        this.$emit('open-chat')
      },
      async getWrapUpCodes() {
        const headers = {
          'Authorization': `Bearer ${this.userToken}`,
          'appid': process.env.VUE_APP_APP_ID,
          'companyid': process.env.VUE_APP_COMPANY_ID,
        };

        let response = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/agenda/wrapup-codes', {
          headers,
        })

        let data = response.data

        for (let i = 0; i < data.length; i++) {
          const elem = data[i];
          this.wrapUpCodes.push({
            Label: this.realCodes[i],
            InternalCode: elem.InternalCode
          })
        }
      }
    },
    mounted() {
      this.activeCall()
      this.getWrapUpCodes()
      if (this.appointment.status == 'Attended') {
        this.finished = true
      } else {
        this.finished = false
      }
    },
    updated() {
      this.activeCall()
      if (this.appointment.status == 'Attended') {
        this.finished = true
      } else {
        this.finished = false
      }
    },
  })
</script>

<style scoped>
  #call {
    height: 70vh;
  }

  .title {
    background-color: #EFF0F4;
    padding-left: 30px;
    height: 60px;
    display: flex;
    align-items: center;
  }
  .title h3 {
    margin: 0;
  }

  .material-icons {
    margin-right: 6px;
  }

  .requirement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
  }

  .requirement p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .call-data {
    justify-content: center;
  }

  .camera-cont p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #F6F6F8;
  }

  .camera-cont button {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary);
    background-color: #F6F6F8;
    border: 1px solid #C4C9D1;
  }

  button {
    border-radius: 8px;
    box-shadow: none;
    font-weight: 500;
  }

  .buttons .btn {
    background-color: var(--primary);
    color: #FBFCFF !important;
    width: 60%;
    margin-bottom: 12px;
    height: 40px;
    border-radius: 30px;
  }

  .buttons .btn.disabled {
    background-color: #E6F1F8 !important;
    color: #A5B3BB !important;
  }

  .buttons #message-btn {
    background-color: transparent !important;
    color: #3D4346 !important;
    border: 1px solid #A5B3BB;
  }

  .switch-cont {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .switch-cont span {
    margin-right: 20px;
  }
</style>