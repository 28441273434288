
import { defineComponent } from 'vue'
import TopBar from '../../components/themes/2/TopBar.vue'
import SideNav from '../../components/themes/1/SideNav.vue'
import ContactCenter from '../../components/themes/2/ContactCenter/ContactCenter.vue'
export default defineComponent({
  name: 'ContactCenterView',
  components: {
    TopBar,
    ContactCenter,
    SideNav,
  }
})
