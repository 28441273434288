
  import {
    defineComponent
  } from "vue";
  import UserInfo from './UserInfo.vue'

  export default defineComponent({
    name: "CallCard",
    props: {
      id: Number,
      title: String,
      calls: Array,
    },
    components: {
      UserInfo,
    },
    methods: {
      answer(answered: boolean, itemid: number, callid: number) {
        this.$emit('answer-call', answered, itemid, callid)
      },
    }
  });
