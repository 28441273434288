import axios from "axios";
import store from "../store"

const config = {
  headers: {
    'appid': process.env.VUE_APP_APP_ID,
    'companyid': process.env.VUE_APP_COMPANY_ID,
  }
}

const httpRep = axios.create(config);
const httpKeycV1 = axios.create(config);
const httpKeycV2 = axios.create(config);
const httpUsrkc = axios.create(config);

httpRep.interceptors.request.use(function(config) {
  config.baseURL = process.env.VUE_APP_BOPS_REP_V1_API_URL;

  if(store.state.token) 
    config.headers!.Authorization = 'Bearer ' + store.getters.getToken;

  return config;
}) 

httpKeycV1.interceptors.request.use(function(config) {
  config.baseURL = process.env.VUE_APP_BOPS_KEYC_API_URL;

  if(store.state.token) 
    config.headers!.Authorization = 'Bearer ' + store.getters.getToken;

  return config;
}) 

httpKeycV2.interceptors.request.use(function(config) {
  config.baseURL = process.env.VUE_APP_BOPS_KEYC_API_V2_URL;

  if(store.state.token) 
    config.headers!.Authorization = 'Bearer ' + store.getters.getToken;

  return config;
}) 

httpUsrkc.interceptors.request.use(function(config) {
  config.baseURL = process.env.VUE_APP_BOPS_USRKC_V1_API_URL;

  if(store.state.token) 
    config.headers!.Authorization = 'Bearer ' + store.getters.getToken;

  return config;
}) 

export { httpRep, httpKeycV1, httpKeycV2, httpUsrkc };