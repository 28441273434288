<template>
  <div id="register-form">
    <div class="row">
      <div class="col s12 d-flex topbar">
        <router-link class="brand-logo" to="/" v-if="logoUrl">
          <img class="responsive-img" :src="logoUrl" alt="logo" />
        </router-link>
        <a v-else href="#!" class="brand-logo">Logo</a>
      </div>
      <div class="col s12 col-back">
        <div class="d-flex back" @click="logOut">
          <i class="material-icons">arrow_back</i>
          <h5>{{ $t("register.back") }}</h5>
        </div>
      </div>
      <div class="col s12">
        <div class="container">
          <h2 class="left-align">{{ $t("register.title") }}</h2>
          <p class="left-align">
            {{ $t("register.subtitle.text") }}
            <a href="#">{{ $t("register.subtitle.text") }}</a>
          </p>
          <div class="row">
            <form class="col s12" v-on:submit.prevent="register">
              <div class="row">
                <div class="file-field input-field d-flex">
                  <div class="camera-cont">
                    <img :src="require('@/assets/images/cameraicon.png')" alt="avatar" />
                  </div>
                  <input type="file" name="picture" id="picture" accept="image/*" v-on:change="selectImage($event)" />
                  <div class="file-path-wrapper">
                    <input class="file-path validate" type="text" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <input id="names" type="text" class="validate" v-model="form.names" disabled />
                  <label for="names">{{ $t("register.fields[0]") }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <input id="surnames" type="text" class="validate" v-model="form.surnames" disabled />
                  <label for="surnames">{{ $t("register.fields[1]") }}</label>
                </div>
              </div>

              <div class="row" v-if="countryId === 'COL'">
                <div class="input-field col s12">
                  <select v-model="form.documentType">
                    <option value="CC">Cédula de ciudadanía</option>
                    <option value="CE">Cédula de extrangería</option>
                  </select>
                  <label>{{ $t("register.fields[2]") }}</label>
                </div>
              </div>

              <div class="row" v-else>
                <div class="input-field col s12">
                  <select v-model="form.documentType">
                    <option :value="type.Id" v-for="(type, index) in identificationTypes" :key="index"> {{
                      type.Description }} </option>
                  </select>
                  <label>{{ $t("register.fields[2]") }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <input id="documentNumber" type="text" class="validate" v-model="form.documentNumber"
                    v-on:keypress="isNumber($event)" />
                  <label for="documentNumber">{{
                    $t("register.fields[3]")
                  }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <input id="medicalRecord" type="text" class="validate" v-model="form.medicalRecord"
                    v-on:keypress="isNumber($event)" />
                  <label for="medicalRecord">{{
                    $t("register.fields[4]")
                  }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s2">
                  <span class="p-float-label">
                    <Dropdown id="indicative" v-model="form.indicative" :options="indicatives" optionLabel="label_es"
                      :emptyMessage="$t('newCon.addDocument.emptySelect')" :filter="true" />
                    <label for="indicative">{{ $t("register.fields[5]") }}</label>
                  </span>
                </div>
                <div class="input-field col s10">
                  <input id="phone" type="tel" class="validate" v-model="form.phone" />
                  <label for="phone">{{ $t("register.fields[6]") }}</label>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <span class="p-float-label">
                    <Dropdown id="indicative" v-model="form.role" :options="roles" optionLabel="Label"
                      :emptyMessage="$t('newCon.addDocument.emptySelect')" @change="selectingRol" :filter="true" />
                    <label for="indicative">{{ $t("register.fields[7]") }}</label>
                  </span>
                </div>
              </div>

              <div class="row" v-if="specialties.length > 0" style="margin-bottom: 15px !important;">
                <div class="input-field col s12">
                  <span class="p-float-label">
                    <MultiSelect id="specialty" display="chip" v-model="form.specialties" :options="specialties"
                      optionLabel="label" optionValue="id" :emptyMessage="$t('newCon.addDocument.emptySelect')" />
                    <label for="specialty">{{ $t("register.fields[8]") }}</label>
                  </span>
                </div>
              </div>

              <div class="row" style="margin: 15px -0.75rem !important;">
                <div class="input-field col s12">
                  <span class="p-float-label">
                    <MultiSelect id="weekdays" display="chip" v-model="form.weekdays" :options="weekdays"
                      optionLabel="label" :emptyMessage="$t('newCon.addDocument.emptySelect')" />
                    <label for="weekdays">{{ $t("register.fields[9]") }}</label>
                  </span>
                </div>
              </div>

              <div class="row" v-if="form.weekdays.length > 0">
                <div class="col s12">
                  <p class="left-align" style="margin-bottom: 10px !important;">{{ $t("register.fields[10]") }}</p>
                  <div class="hours d-flex">
                    <div class="time-pickers d-flex align-center" v-for="days in form.weekdays" :key="days.id">
                      <p> {{ days.label }} </p>
                      <div class="time-picker d-flex align-center">
                        <Dropdown :id="'startHour' + days.id" v-model="days.hourStart" :options="hours"
                          :emptyMessage="$t('newCon.addDocument.emptySelect')" optionLabel="label"
                          optionValue="value" />
                        <p>-</p>
                        <Dropdown :id="'endHour' + days.id" v-model="days.hourEnd" :options="hours"
                          :emptyMessage="$t('newCon.addDocument.emptySelect')" optionLabel="label"
                          optionValue="value" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12">
                  <input id="duration" type="text" class="validate" v-model="form.duration"
                    v-on:keypress="isNumber($event)" />
                  <label for="duration">{{ $t("register.fields[11]") }}</label>
                </div>
              </div>

              <div class="row">
                <div class="col s12">
                  <div class="d-flex checkbox">
                    <label>
                      <input type="checkbox" class="filled-in" v-model="form.terms" />
                      <span></span>
                    </label>
                    <p>
                      {{ $t("register.terms.text") }}
                      <a href="#">{{ $t("register.terms.link") }}</a>
                    </p>
                  </div>
                </div>
                <div class="col s12">
                  <div class="d-flex checkbox">
                    <label>
                      <input type="checkbox" class="filled-in" v-model="form.policy" />
                      <span></span>
                    </label>
                    <p>
                      {{ $t("register.privacy.text") }}
                      <a href="#">{{ $t("register.privacy.link") }}</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <input type="submit" class="waves-effect waves-light btn btn-primary btn-rounded"
                    :value="$t('register.button')" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import countries from "@/data/countries.json"
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import { getUserData } from "@/core/user";
import { httpKeycV1 } from "@/core/http";
import { auth } from "../../../../core/firebase";

export default ({
  name: "RegisterForm",
  components: {
    Dropdown,
    MultiSelect
  },
  data() {
    return {
      form: {
        names: "",
        surnames: "",
        documentType: "",
        documentNumber: "",
        medicalRecord: "",
        indicative: null,
        phone: "",
        terms: false,
        policy: false,
        picture: null,
        role: null,
        specialties: null,
        weekdays: [],
        duration: "",
      },
      date: new Date(),
      countries: countries,
      roles: [],
      specialties: [],
    };
  },
  computed: {
    logoUrl() {
      return store.getters.getCompanyLogo;
    },
    userToken() {
      return store.getters.getToken;
    },
    indicatives() {
      let a = []
      for (let i = 0; i < this.countries.countries.length; i++) {
        const e = this.countries.countries[i];
        let country = {
          code: e.code,
          dial_code: e.dial_code,
          name_en: e.name_en,
          name_es: e.name_es,
          label_es: e.dial_code + ' ' + e.name_es,
          label_en: e.dial_code + ' ' + e.name_en,
        }
        a.push(country)
      }
      return a
    },
    weekdays() {
      let a = [];
      for (let i = 0; i < 7; i++) {
        let day = {
          id: i + 1,
          label: this.$t('register.weekdays[' + i + ']'),
        }
        a.push(day);
      }
      return a
    },
    hours() {
      let a = [];
      for (let i = 0; i < 24; i++) {
        let hour = ('0' + i).slice(-2);
        a.push({
          value: hour,
          label: hour + ':00',
        });
      }
      return a
    },
    countryId() {
      return store.getters.getCountry.Id;
    },
    identificationTypes() {
      return store.getters.getCountry.IdentificationTypes;
    },
    idToken() {
      return store.getters.getInstance.idToken
    },
  },
  methods: {
    logOut() {
      signOut(auth);
      const useKeycloak = process.env.VUE_APP_USE_KEYCLOAK === "true";
      console.log(`useKeycloak: ${useKeycloak}`);
      if (useKeycloak) {
        const encodeRedirectUri = window.location.href.split('#')[0].replaceAll(':', '%3A').replaceAll('/', '%2F')

        window.location.href = process.env.VUE_APP_KEYCLOAK_URL + '/realms/' + process.env.VUE_APP_KEYCLOAK_REALM + '/protocol/openid-connect/logout?post_logout_redirect_uri=' + encodeRedirectUri + '&id_token_hint=' + this.idToken
      } else {
        console.log("logout without kc");
        this.$store.commit('RESET_STATE');
        this.$router.replace("/");
      }
    },
    isNumber(evt) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
      ];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    isIndicative(evt) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "+",
      ];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }

      if (this.form.indicative === "" && keyPressed != "+") {
        evt.preventDefault();
      }
    },
    selectImage(e) {
      console.log(e.target.files[0]);
      this.form.picture = e.target.files[0];
    },
    async register() {
      const {
        names,
        surnames,
        documentType,
        documentNumber,
        medicalRecord,
        indicative,
        phone,
        terms,
        policy,
        picture,
        role,
        specialties,
        weekdays,
        duration
      } = this.form;

      if (
        !names ||
        names.trim() == "" ||
        !surnames ||
        surnames.trim() == "" ||
        !documentType ||
        documentType.trim() == "" ||
        !documentNumber ||
        documentNumber.trim() == "" ||
        !medicalRecord ||
        medicalRecord.trim() == "" ||
        !indicative ||
        !phone ||
        phone.trim() == "" ||
        !role ||
        !duration || duration.trim() == "" ||
        weekdays.length < 0 || !this.validateHours(weekdays)
      ) {
        return M.toast({
          html: this.$t('register.messages[0]'),
        });
      }

      if (!terms) {
        return M.toast({
          html: this.$t('register.messages[1]'),
        });
      }

      if (!policy) {
        return M.toast({
          html: this.$t('register.messages[2]'),
        });
      }

      let schedules = this.parseHours(weekdays);

      const body = {
        Name: names,
        Lastname: surnames,
        Phone: indicative.dial_code + ' ' + phone,
        Document: {
          DocumentType: documentType,
          DocumentNumber: documentNumber,
        },
        MedicalNumber: medicalRecord,
        Country: store.getters.getCountry.Id,
        PictureUrl: "",
        RoleId: role.Id,
        Schedule: {
          MONDAY: schedules[0],
          TUESDAY: schedules[1],
          WEDNESDAY: schedules[2],
          THURSDAY: schedules[3],
          FRIDAY: schedules[4],
          SATURDAY: schedules[5],
          SUNDAY: schedules[6]
        },
        ScheduleInterval: parseInt(duration, 10),
        Specialties: specialties
      };

      try {
        await httpKeycV1.post("/api/user/create-user", body)
          .then(async (response) => {
            if (response.status === 200) {
              const newBody = {
                Name: names,
                Lastname: surnames,
                Email: store.getters.getUser?.Email,
                Phone: indicative.dial_code + ' ' + phone,
                Document: {
                  DocumentType: documentType,
                  DocumentNumber: documentNumber,
                },
                MedicalNumber: medicalRecord,
                Country: store.getters.getCountry.Id,
                PictureUrl: "",
                RoleId: role.Id,
                HasAccess: true,
              };

              const params = {
                userId: store.getters.getUser?.Id
              }

              await httpKeycV1.put("/api/user", newBody, { params }).then(async (response) => {
                if (response.status == 200) {
                  M.toast({
                    html: this.$t('register.messages[3]'),
                  });
                  await getUserData(this.userToken);
                  this.$router.replace("/");
                }
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    fillFields() {
      const {
        Name,
        LastName
      } = store.getters.getUser??null;
      this.form.names = Name.split(" ")[0];
      this.form.surnames = LastName;
      let input = document.getElementById("names");
      input.labels[0].classList.add("active");

      input = document.getElementById("surnames");
      input.labels[0].classList.add("active");
    },
    validateHours(weekdays) {
      for (let i = 0; i < weekdays.length; i++) {
        const e = weekdays[i];
        if (!e.hourStart || !e.hourEnd) return false;
      }
      return true;
    },
    parseHours(weekdays) {
      let hours = [];
      for (let i = 0; i < 7; i++) {
        let day = weekdays.find(e => e.id === (i + 1))
        if (day) {
          hours.push({
            InitialTime: parseInt(day.hourStart, 10),
            EndTime: parseInt(day.hourEnd, 10),
          });
        } else {
          hours.push({
            InitialTime: 0,
            EndTime: 0,
          });
        }
      }
      return hours;
    },
    async getRoles() {
      let response = await httpKeycV1.get('/api/role');
      let roles = response.data;
      for (let i = 0; i < roles.length; i++) {
        const e = roles[i];
        e.Label = this.$t('register.roles[' + (e.Id - 1) + ']')
      }
      this.roles = roles;
    },
    async selectingRol(event) {
      let roleId = event.value.Id.toString();
      let response = await httpKeycV1.get('/api/user/specialties', {
        params: { roleId }
      });
      let specialties = [];
      for (let i = 0; i < response.data.length; i++) {
        const id = response.data[i];
        let label = '';
        if (id === 'GENERAL DOCTOR') {
          label = this.$t('register.specialties[0]');
        } else if (id === 'NURSE') {
          label = this.$t('register.specialties[1]');
        } else {
          label = id;
        }
        specialties.push({
          id,
          label
        });
      }
      this.form.specialties = [];
      this.specialties = specialties;
    }
  },
  async mounted() {
    this.fillFields();
    M.AutoInit();
    var select = document.querySelectorAll("select");
    M.FormSelect.init(select);
    await this.getRoles();
  },
});
</script>

<style scoped>
.topbar {
  padding: 15px 33px;
  background-color: #ffffff;
}

#register-form {
  background-color: #fbfcff;
}

h2 {
  font-family: "Lato";
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  font-style: normal;
  margin: 65px 0 5px !important;
}

p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #5c6470;
}

.input-field>label {
  font-weight: 400;
  font-size: 16px;
  color: #848f96;
}

.input-field>label.active {
  color: #909194 !important;
}

.btn {
  box-shadow: 0px 2px 15px rgba(158, 0, 255, 0.2);
  width: 420px;
  font-family: "Lato";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  height: 45px;
}

form .row {
  margin-bottom: 0 !important;
}

.checkbox {
  margin: 5px 0;
}

.checkbox p {
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #454b55;
}

.file-path-wrapper {
  display: none !important;
}

.file-field {
  background-image: url("../../../../assets/images/PictureButton.png");
  background-color: #e1e2e6;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 90px;
  height: 90px;
  border-radius: 50px;
  align-items: flex-end;
  margin: 30px 0;
}

.camera-cont {
  background-color: #1b1d1f;
  height: 26px;
  width: 100%;
  mix-blend-mode: overlay;
}

#register-form:deep(.p-dropdown) {
  background-color: transparent;
}

#register-form:deep(.p-multiselect) {
  width: 100% !important;
  border: none;
  box-shadow: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #a5b3bb !important;
  background-color: transparent;
}

#register-form:deep(.p-multiselect .p-multiselect-label) {
  text-align: left;
}

.time-picker {
  border: 1px solid #a5b3bb !important;
  border-radius: 8px !important;
  margin-left: 10px !important;
  margin-bottom: 5px;
  margin-right: 25px !important;
}

.time-picker:deep(.p-dropdown) {
  border-bottom: none !important;
}

.time-picker:deep(.p-dropdown .p-dropdown-trigger) {
  display: none !important;
}

.align-center {
  align-items: center;
}

.col-back {
  border-bottom: 1px solid #eff0f4;
}
</style>