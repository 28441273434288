import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/1/Home.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/care-studio",
    name: "CareStudioView",
    component: () =>
      import(
        /* webpackChunkName: "care-studio" */ "../views/CareStudioView.vue"
      ),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/call-center",
    name: "CallCenter",
    component: () =>
      import(/* webpackChunkName: "callcenter" */ "../views/CallCenter.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/users-list",
    name: "UserList",
    component: () =>
      import(/* webpackChunkName: "userlist" */ "../views/1/UserList.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
  },
  {
    path: "/account-in-process",
    name: "NoAccess",
    component: () =>
      import(/* webpackChunkName: "noAccess" */ "../views/NoAccess.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/call",
    name: "CallView",
    component: () =>
      import(/* webpackChunkName: "call" */ "../views/CallView.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
];

const routes1: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/1/Home.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/1/Register.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/1/LoginView.vue"),
  },
  {
    path: "/users-list",
    name: "UserList",
    component: () =>
      import(/* webpackChunkName: "userlist" */ "../views/1/UserList.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/keyboard",
    name: "KeyboardView",
    component: () =>
      import(/* webpackChunkName: "keyboard" */ "../views/1/KeyboardView.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
  },
];

const routes2: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/2/Home.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/2/Register.vue"),
  },
  {
    path: "/users-list",
    name: "UserList",
    component: () =>
      import(/* webpackChunkName: "userlist" */ "../views/1/UserList.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/call",
    name: "CallView",
    component: () =>
      import(/* webpackChunkName: "call" */ "../views/2/CallView.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/keyboard",
    name: "KeyboardView",
    component: () =>
      import(/* webpackChunkName: "call" */ "../views/2/KeyboardView.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/2/ReportsView.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/admin-reports",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/2/AdminView.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/file",
    name: "FileViewer",
    component: () =>
      import(/* webpackChunkName: "fileviewer" */ "../views/2/FileViewer.vue"),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/contact-center",
    name: "ContactCenter",
    component: () =>
      import(
        /* webpackChunkName: "contactcenter" */ "../views/2/ContactCenterView.vue"
      ),
    meta: {
      formReadyRequired: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: process.env.VUE_APP_THEME == "SCHEDULE_COMPLETE" ? routes1 : routes2,
});

router.beforeEach((to, from, next) => {
  const formReadyRequired = to.matched.some(
    (record) => record.meta.formReadyRequired
  );
  const hasFormReady = store.getters.hasFormReady ?? null;
  const specialties = store.getters.agendaSpecialties;
  const token = store.getters.getToken;
  const useKeycloak = process.env.VUE_APP_USE_KEYCLOAK === "true";

  if ((token === null || token === undefined) && !useKeycloak && (to.path !== '/login' && to.path !== '/register')) {
    console.log("go to login");
    return next("/login");
  }

  if (to.name === "UserList" && specialties && specialties.length <= 0) {
    return next("/");
  }

  if (!hasFormReady && formReadyRequired) return next("/register");

  // if (hasFormReady && !formReadyRequired)
  //   return next("/");

  return next();
});

export default router;
