
  import {
    defineComponent
  } from 'vue'
  import Call from './Call.vue'
  import PatientData from './PatientData.vue';
  import History from './History.vue';

  export default defineComponent({
    name: 'AgendaTabs',
    components: {
      Call,
      PatientData,
      History
    },
    props: {
      user: Object as any,
    },
    mounted() {
      M.AutoInit();
      var tab = document.querySelectorAll(".tabs");
      M.Tabs.init(tab);
    }
  })
