
  import {
    defineComponent
  } from 'vue'
  import History from './History.vue';

  export default defineComponent({
    name: 'PatientData',
    components: {
      History
    },
    props: {
      user: Object as any,
    },
    mounted() {
      M.AutoInit();
      var tab = document.querySelectorAll(".tabs");
      M.Tabs.init(tab);
    },
    methods: {
      calculateAge(dateString) {
        var birthday = new Date(dateString);
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      },
    },
  })
