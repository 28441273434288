import { getSessionGrant } from "../services/vonage";
import OT from "@opentok/client";
import store from "../store";

const LOCAL_MEDIA_ID = 'localvideo';
const REMOTE_MEDIA_ID = 'remotevideo';

const apikey = store.getters.getCompany.Vonage ? store.getters.getCompany.Vonage.Key : process.env.VUE_APP_VONAGE_APIKEY;

function getMediaContainer(id: string) {
  return document.getElementById(id)!;
};

export async function joinSession(sessionId: string) {
  const token = await getSessionGrant(sessionId);
  
  var session = OT.initSession(apikey, sessionId);

  const localMediaContainer = getMediaContainer(LOCAL_MEDIA_ID);
  const remoteMediaContainer = getMediaContainer(REMOTE_MEDIA_ID);

  session.on('streamCreated', function(event) {
    session.subscribe(event.stream, remoteMediaContainer, {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      style: {
        buttonDisplayMode: 'off',
      },
      audioVolume: 100,
    }, function(error) {
      if(error) console.log(error?.message);
    });
  });

  var publisher = OT.initPublisher(localMediaContainer, {
    insertMode: 'append',
    width: '100%',
    height: '100%',
    style: {
      buttonDisplayMode: 'off',
    },
    videoFilter: {
      type: 'backgroundReplacement',
      backgroundImgUrl: store.getters.getCompany.Properties.BackgroundCallUrl,
    },
    mirror: false,
  }, function(error) {
    if (error) console.log(error);
  });

  session.connect(token, function(error) {
    if(error) {
      console.log(error)
    } else {
      session.publish(publisher, function(error) { if(error) console.log(error.message) });
    }
  });

  return { session, sessionId, publisher }
}

export function testCamera() {
  const localMediaContainer = getMediaContainer('local-media');
  var publisher = OT.initPublisher(localMediaContainer, {
    insertMode: 'append',
    width: '100%',
    height: '100%',
    style: {
      buttonDisplayMode: 'off',
    },
    videoFilter: {
      type: 'backgroundReplacement',
      backgroundImgUrl: store.getters.getCompany.Properties.BackgroundCallUrl,
    },
    mirror: false,
  }, function(error) {
    if(error) console.log(error)
  });

  function destroyPublisher() {
    publisher.destroy();
  }
  return { destroyPublisher }
}

export function setAudio(publisher: OT.Publisher, status: boolean) {
  publisher.publishAudio(status);
}

export function setVideo(publisher: OT.Publisher, status: boolean) {
  publisher.publishVideo(status);
}

export function hangUpSession(session: OT.Session) {
  session.disconnect();
}