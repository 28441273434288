<template>
  <div id="care-studio">
    <div class="row">
      <div class="col s12">
        <overview></overview>
      </div>
    </div>
  </div>
</template>

<script>
import Overview from './Tabs/Overview.vue'

export default {
  name: 'CareStudio',
  components: {
    Overview,
  }
}
</script>
