
  import {
    defineComponent
  } from 'vue'
  import UserInfo from './UserInfo.vue'
  import NewConsultation from '../MedicalRecord/NewConsultation.vue'
  import SeeConsultationsVue from '../MedicalRecord/SeeConsultations.vue';
  import Background from '../MedicalRecord/Background.vue';
  import Vitals from '../MedicalRecord/Vitals.vue';

  export default defineComponent({
    name: 'Appointment',
      components: {
        UserInfo,
        NewConsultation,
        SeeConsultationsVue,
        Background,
        Vitals
      },
    data() {
      return {
        newCon: false,
      }
    },
    props: {
      user: Object as any,
      consultation: Object as any,
    },
    mounted() {
      M.AutoInit();
      var tab = document.querySelectorAll(".tabs");
      M.Tabs.init(tab);
    },
    unmounted() {
      window.removeEventListener('beforeunload', this.beforeExit)
    },
    created() {
      window.addEventListener('beforeunload', this.beforeExit)
    },
    methods: {
      calculateAge(dateString) {
        var birthday = new Date(dateString);
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      },
      
      beforeExit: function handler (event) {
        event.preventDefault();
        return event.returnValue = '¿Está seguro de salir?, perderá todo su progreso'
      },
    }
  })
