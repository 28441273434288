<template>
  <div class="admin-reports-2">
    <top-bar />
    <side-nav />
    <admin-reports />
  </div>
</template>

<script>
  import TopBar from '@/components/themes/2/TopBar.vue'
  import SideNav from '@/components/themes/2/SideNav.vue'
  import AdminReports from '@/components/themes/2/Reports/AdminReports.vue';

  export default {
    name: 'AdminView',
    components: {
      TopBar,
      SideNav,
      AdminReports
    }
  }
</script>