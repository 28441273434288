
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "NoAccess",
  computed: {
    logoUrl() {
      return store.getters.getCompanyLogo;
    },
    companyName() {
      const companyid = process.env.VUE_APP_COMPANY_ID.toLowerCase();

      return companyid.charAt(0).toUpperCase() + companyid.slice(1);
    },
  },
});
