
import { defineComponent } from 'vue'
import { database } from '../../../../core/firebase';
import { ref, onChildAdded } from '@firebase/database';

export default defineComponent({
  name: 'UserInfo',
  props: {
    online: Boolean,
    username: String,
    avatar: String,
    status: String,
    type: Number,
    subtitle: String,
    appointmentId: String,
    active: Boolean,
  },
  watch: {
    active(val) {
      if(val) {
        this.newMsgs = 0;
      }
    }
  },
  data() {
    return {
      newMsgs: 0,
    }
  },
  mounted() {
    let grandref = ref(database, 'chat/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID + '/' + this.appointmentId + '/Preconsultation/');
    onChildAdded(grandref, (snap) => {
      if(snap.val().Role == 2 && !snap.val().Viewed && !this.active)
        this.newMsgs += 1;
    }); 
  },
})
