<template>
  <div class="home-1">
    <top-bar />
    <side-nav />
    <agenda ref="agenda" />
  </div>
</template>

<script>
import Agenda from '@/components/themes/1/Agenda/Agenda.vue';
import TopBar from '@/components/themes/1/TopBar.vue'
import SideNav from '@/components/themes/1/SideNav.vue'

export default ({
  name: 'Home',
  components: {
    TopBar,
    SideNav,
    Agenda,
  },
  beforeRouteLeave(to, from, next) {
    var code = this.$refs.agenda.validCode
    if (code) {
      this.$confirm.require({
        message: this.$t('hceExit.header'),
        header: this.$t('hceExit.text'),
        acceptLabel: this.$t('hceExit.buttons[0]'),
        rejectLabel: this.$t('hceExit.buttons[1]'),
        acceptClass: 'btn-primary',
        accept: () => {
          next();
        },
        reject: () => {
          next(false);
        }
      })
    } else {
      next();
    }
  }
});
</script>
