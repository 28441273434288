import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9f78a7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "appointment" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col s12" }
const _hoisted_4 = { class: "col s12" }
const _hoisted_5 = { class: "tabs" }
const _hoisted_6 = { class: "tab col s3" }
const _hoisted_7 = { href: "#medHistory" }
const _hoisted_8 = { class: "tab col s3" }
const _hoisted_9 = { href: "#monitoring" }
const _hoisted_10 = { class: "tab col s3" }
const _hoisted_11 = { href: "#appointmentHistory" }
const _hoisted_12 = {
  key: 0,
  class: "tab col s3"
}
const _hoisted_13 = { href: "#newAppointment" }
const _hoisted_14 = {
  id: "medHistory",
  class: "col s12"
}
const _hoisted_15 = {
  id: "monitoring",
  class: "col s12"
}
const _hoisted_16 = {
  id: "appointmentHistory",
  class: "col s12"
}
const _hoisted_17 = {
  id: "newAppointment",
  class: "col s12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_info = _resolveComponent("user-info")!
  const _component_background = _resolveComponent("background")!
  const _component_vitals = _resolveComponent("vitals")!
  const _component_see_consultations_vue = _resolveComponent("see-consultations-vue")!
  const _component_new_consultation = _resolveComponent("new-consultation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_user_info, {
          id: "user-info",
          username: _ctx.user.Name + ' ' + _ctx.user.LastName,
          avatar: _ctx.user.Avatar
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, " Edad: " + _toDisplayString(_ctx.calculateAge(_ctx.user.Birthdate)) + " años | " + _toDisplayString(_ctx.user.Document.DocumentType) + ": " + _toDisplayString(_ctx.user.Document.DocumentNumber) + " | " + _toDisplayString(_ctx.user.Phone), 1)
          ]),
          _: 1
        }, 8, ["username", "avatar"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", _hoisted_5, [
          _createElementVNode("li", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('appointmentTabs[0]')), 1)
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t('appointmentTabs[1]')), 1)
          ]),
          _createElementVNode("li", _hoisted_10, [
            _createElementVNode("a", _hoisted_11, _toDisplayString(_ctx.$t('appointmentTabs[2]')), 1)
          ]),
          (_ctx.consultation.status == 'Active')
            ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t('appointmentTabs[3]')), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_background, {
          patient: _ctx.user,
          consultationId: _ctx.consultation.id
        }, null, 8, ["patient", "consultationId"])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_vitals, {
          patient: _ctx.user,
          consultationId: _ctx.consultation.id
        }, null, 8, ["patient", "consultationId"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_see_consultations_vue, {
          patient: _ctx.user,
          newCon: _ctx.newCon,
          onRefreshed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newCon = false))
        }, null, 8, ["patient", "newCon"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_new_consultation, {
          patient: _ctx.user,
          consultationId: _ctx.consultation.id,
          consultationType: _ctx.consultation.type,
          onNewCon: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newCon = true))
        }, null, 8, ["patient", "consultationId", "consultationType"])
      ])
    ])
  ]))
}