<template>
  <div id="chat" :class="{ minimize: minimize }">
    <div class="row">
      <div class="col s12">
        <div class="chat-title d-flex">
          <h3 class="left-align"><i class="material-icons circle">circle</i> {{ name }} </h3>
          <div class="buttons">
            <a class="btn btn-text minimize-btn" @click="minimizeChat"><i class="material-icons">minimize</i></a>
            <a class="btn btn-text" @click="close"><i class="material-icons">close</i></a>
          </div>
        </div>
        <div class="chat-content" v-if="!minimize">
          <div class="messages">
            <div class="message-cont" v-for="(msg, index) in messages" :key="index" :class="{ 'message-get': msg.Role == 2, 'message-send': msg.Role == 1 }">
              <div class="message">
                <p> {{ msg.Body }} </p>
                <p class="hour"> {{ getHour(msg.Timestamp) }} </p>
              </div>
            </div>
          </div>
          <div class="d-flex input-box">
            <EmojiPicker @select="onSelectEmoji" v-show="showEmojis" :native="false" />
            <a @click="togglePicker"><i class="material-icons-outlined">emoji_emotions</i></a>
            <input type="text" v-model="message" :placeholder="$t('chatPlaceHolder')" @keyup.enter="sendMessage" />
            <a @click="sendMessage"><i class="material-icons send">send</i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/dist/style.css"
import { database } from '@/core/firebase'
import { set, ref, get, update, onChildAdded } from '@firebase/database'
export default {
  name: 'Chat',
  components: {
    EmojiPicker
  },
  props: {
    name: String,
    consultation: Object,
    currentUser: Object,
  },
  mounted() {
    this.getMessages()
    this.getNewMsg()
  },
  data() {
    return {
      minimize: false,
      message: '',
      messages: [],
      showEmojis: false,
    }
  },
  methods: {
    close() {
      this.$emit('close-chat')
    },
    minimizeChat() {
      this.minimize = !this.minimize
    },
    getNewMsg() {
      let chatref = ref(database, 'chat/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID + '/' + this.consultation.id + '/Preconsultation/')
      onChildAdded(chatref, (snap) => {
        this.messages.push(snap.val())
      })
    },
    onSelectEmoji(emoji) {
      this.message += emoji.i;
    },
    async getMessages() {
      let chatref = ref(database, 'chat/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID + '/' + this.consultation.id)
      await get(chatref).then((snap) => {
        if(snap.exists()) {
          this.messages = snap.val().Preconsultation
          setTimeout(() => {
            document.querySelector(".messages").scrollTop = document.querySelector(".messages").scrollHeight
          }, 100)
        }
      })
    },
    async sendMessage() {
      if(this.message.trim() == '' || !this.message) {
        return null
      }

      let chatref = ref(database, 'chat/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID + '/' + this.consultation.id)

      await get(chatref).then(async (snap) => {
        let msg = {
          Body: this.message,
          Role: 1,
          Timestamp: new Date(),
        }   
        console.log(msg)

        if(snap.exists()) {
          let array = snap.val().Preconsultation
          array.push(msg)
          update(chatref, {
            Preconsultation: array
          })
        } else {
          let msgs = []
          let chat = {
            Id: this.consultation.id,
            PatientId: this.consultation.user.Id,
            DoctorId: this.currentUser.Id,
            Preconsultation: msgs,
          }

          await set(chatref, chat)

          let array = []
          array.push(msg)
          update(chatref, {
            Preconsultation: array
          })
        }
      })
      
      this.message = ''
      this.getMessages()

      setTimeout(() => {
        document.querySelector(".messages").scrollTop = document.querySelector(".messages").scrollHeight
      }, 200)
    },
    togglePicker() {
      this.showEmojis = !this.showEmojis
    },
    addZero(date) {
      if ((date / 10) < 1)
        return '0' + date

      return date
    },
    getHour(d) {
      let date = new Date(d)
      return this.addZero(date.getHours())+':'+this.addZero(date.getMinutes())
    }
  }
}
</script>

<style scoped>
  #chat {
    background: #FFFFFF;
    box-shadow: 0px -2px 18px rgba(36, 42, 51, 0.08);
    border-radius: 8px 8px 0px 0px;
    width: 416px;
    height: 449px;
    position: fixed;
    bottom: 0;
    right: 10%;
    z-index: 999;
  }

  .btn {
    padding: 0 5px !important;
  }

  .minimize-btn {
    height: 20px;
    line-height: 10px;
  }

  #chat.minimize {
    height: 50px;
  }

  .col {
    padding: 0 !important;
  }

  .chat-title {
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #E4E7EC;
    padding: 7px 20px;
  }

  .chat-title h3 {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #242A33;
  }

  .circle {
    color: #07EE6A;
    font-size: 14px;
    margin-right: 8px;
  }

  .chat-content {
    padding: 20px 20px 14px;
  }

  .messages {
    height: 320px;
    overflow-y: auto;
  }

  .messages::-webkit-scrollbar {
    display: none;
  }

  .messages {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .message-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .message {
    margin-bottom: 15px;
  }

  .message p {
    font-size: 14px;
    line-height: 18px;
  }

  .message p.hour {
    font-size: 10px !important;
    line-height: 15px !important;
    text-align: right !important;
  }

  .message-send {
    align-items: flex-end;
  }

  .message-get {
    align-items: flex-start;
  }

  .message-get .message {
    width: fit-content;
    padding: 10px 17px;
    background-color: #DBEEFF;
    border-radius: 0px 8px 8px 8px;
  }

  .message-send .message {
    width: fit-content;
    padding: 10px 17px;
    background-color: #62B4FF;
    border-radius: 8px 0px 8px 8px;
  }

  .message-send p {
    color: #FFFFFF;
    text-align: right;
  }

  .message-get p {
    text-align: left;
  }

  .input-box {
    width: 100%;
    background: #F6F6F8;
    border-radius: 8px;
    align-items: center;
    bottom: 0;
    left: 0;
    padding: 0 10px;
  }

  .input-box input {
    border-bottom: none !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    margin-left: 15px;
  }

  .input-box a {
    cursor: pointer;
  }

  .input-box .send {
    color: var(--primary);
  }
</style>