<template>
  <div id="history">
    <div class="row" id="record-list" v-if="!active">
      <div class="col s12">
        <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="row">
              <div class="col s6">
                <span class="p-float-label p-input-icon-right">
                  <i class="pi pi-calendar" />
                  <InputText id="inputtext-right" :value="inputValue.start" v-on="inputEvents.start" class="p-filled" />
                  <label for="inputtext-right">{{ $t("patientData.backlabels[0]") }}</label>
                </span>
              </div>
              <div class="col s6">
                <span class="p-float-label p-input-icon-right">
                  <i class="pi pi-calendar" />
                  <InputText id="inputtext-right" :value="inputValue.end" v-on="inputEvents.end" class="p-filled" />
                  <label for="inputtext-right">{{ $t("patientData.backlabels[1]") }}</label>
                </span>
              </div>
            </div>
          </template>
        </v-date-picker>
      </div>
      <div class="col s12">
        <div class="record-card" v-for="(item, index) in appointments" :key="index" @click="seeAppointment(item)">
          <h3 class="left-align"> {{ item.name }} - {{ item.profession }} </h3>
          <h5 class="left-align"> {{ item.date }} </h5>
        </div>
      </div>
    </div>
    <div class="row" id="individual" v-else>
      <div class="col s12">
        <div class="d-flex title">
          <i class="material-icons" @click="active = null">arrow_back</i>
          <h5>{{ active.name }} - {{ active.date.split('-')[0] }}</h5>
        </div>
      </div>
      <div class="col s12">
        <div class="options d-flex">
          <div class="option" v-for="(option, index) in options" :key="index" @click="selected = option.type" :class="{ active: selected == option.type}">
          <p>
            {{ option.label }}
          </p>
          </div>
        </div>
        <div class="documents">
          <div class="record-card d-flex" v-for="(doc, index) in filterDocuments" :key="index">
            <div class="d-flex">
              <img :src="require('@/assets/images/PDFIcon.png')" alt="icon">
              <div>
                <h3 class="left-align"> {{ doc.name }} </h3>
                <h5 class="left-align"> {{ doc.date }} </h5>
              </div>
            </div>
            <i class="material-icons">visibility</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default({
    name: 'History',
    data() {
      return {
        range: {
          start: new Date(),
          end: new Date()
        },
        options: [{
          label: 'Orden clínica',
          type: 1,
        },
        {
          label: 'Incapacidad',
          type: 2,
        }, 
        {
          label: 'Medicamentos',
          type: 3
        },
        {
          label: 'Compartidos',
          type: 4,
        }],
        active: null,
        selected: 1,
        appointments: [
          {
            id: 0,
            name: 'Camila Sierra',
            date: '10/11/2021 - 11:10 am',
            profession: 'Cardiologo',
          },
          {
            id: 1,
            name: 'Danilo Perez',
            date: '20/9/2021 - 02:10 pm',
            profession: 'Neurocirujano',
          },
        ],
        documents: [{
          name: '028839.pdf',
          type: 3,
          date: '20/9/2021 - 02:10 pm'
        },
        {
          name: '028839.pdf',
          type: 1,
          date: '20/9/2021 - 02:10 pm'
        }]
      }
    },
    computed: {
      locale() {
        return this.$i18n.locale
      },
      filterDocuments() {
        let a = this.documents
        return a.filter((a) => a.type == this.selected)
      }
    },
    methods: {
      seeAppointment(item) {
        this.active = item
      },
      back() {
        this.active = null
      },    
    }
  })
</script>

<style scoped> 
  #history {
    padding-top: 15px;
  }

  #record-list {
    padding: 21px 30px 17px 30px;
  }

  #history:deep(.p-float-label input.p-filled ~ label) {
    top: 3px !important;
  }

  #history:deep(.p-input-icon-right > .p-inputtext) {
    padding-right: 0 !important;
  }

  .record-card {
    box-shadow: 0px 3px 12px rgba(36, 42, 51, 0.12);
    border-radius: 8px;
    padding: 18px 21px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .record-card h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #242A33;
  }

  .record-card h5 {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #798291;
  }

  .title {
    align-items: center;
    border-bottom: 1px solid #E1E2E6;
    margin-bottom: 10px;
    padding: 0px 20px 15px;
  }

  .title i {
    cursor: pointer;
  }

  .title h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #242A33;
    margin-left: 23px !important;
  }

  .options {
    overflow-x: auto;
    justify-content: space-between;
    padding: 0 25px;
  }

  .options .option {
    background: #E1E2E6;
    border-radius: 8px;
    padding: 6px 16px 8px;
    cursor: pointer;
  }

  .options .option.active {
    background: #1B1D1F !important;
  }

  .options .option p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #5D6062;
  }

  .options .option.active p {
    color: #FBFCFF !important;
  }

  #individual>.col {
    padding-right: 0 !important;
  }

  .documents {
    padding: 20px 25px;
  }

  .documents .record-card {
    align-items: center;
    justify-content: space-between;
    cursor: default;
  }

  .documents .record-card .d-flex {
    align-items: center;  
  }

  .documents .record-card .d-flex img {
    margin-right: 15px;
  }

  .documents .record-card i {
    cursor: pointer;
  }
</style>