<template>
  <div class="overview-item">
    <h1 class="left-align"> {{ title }} <span> {{ unit }} </span> </h1>
    <p class="left-align"> {{ date }} </p>
    <h1 class="left-align"> {{ value }} </h1>
  </div>
</template>

<script>
export default {
  name: 'OverviewItem',
  props: {
    title: String,
    unit: String,
    date: String,
    value: String,
  }
}
</script>

<style scoped>
.overview-item {
  border: 1px solid #C5C6CA;
  border-radius: 12px;
  padding: 15px;
}

.overview-item h1 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 !important;
  color: #1B1D1F;
}

.overview-item h1 span {
  font-size: 18px;
  color: #798291;
}

.overview-item p {
  font-size: 14px;
  color: #798291;
}
</style>
