
  import TopBar from '@/components/TopBar.vue'
  import SideNav from '@/components/SideNav.vue'
  import CareStudio from '@/components/CareStudio/CareStudio.vue'
  import {
    defineComponent
  } from 'vue'

  export default defineComponent({
    components: {
      TopBar,
      SideNav,
      CareStudio
    },
    name: 'CareStudioView',
    data() {
      return {
        search: '',
      }
    },
  })
