import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "call-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_bar = _resolveComponent("top-bar")!
  const _component_side_nav = _resolveComponent("side-nav")!
  const _component_calls_group = _resolveComponent("calls-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_top_bar),
    _createVNode(_component_side_nav),
    _createVNode(_component_calls_group)
  ]))
}