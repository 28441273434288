<template>
  <div id="call">
    <Dialog v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :closable="false"
      :style="{width: '50vw'}" :modal="true" :draggable="false">
      <h3 class="left-align"> {{ $t('callExit.reason.title') }} </h3>
      <Dropdown id="reason-exit" v-model="reason" :options="wrapUpCodes" optionLabel="Label" :emptyMessage="$t('newCon.addDocument.emptySelect')" />
      <template #footer>
        <button class="waves-effect waves-light btn btn-rounded btn-primary" @click="updateAppointment">{{ $t('callExit.reason.buttons[1]') }}</button>
      </template>
    </Dialog>
    <ConfirmDialog class="call-exit"></ConfirmDialog>
    <div class="row">
      <div class="col s12">
        <div class="title">
          <i class="material-icons">smartphone</i>
          <h3 class="left-align"> {{ user.Name }} {{ user.LastName }} ({{ user.Phone }})</h3>
        </div>
      </div>
      <div class="col s12 call-cont">
        <div class="call-data" id="call-container">
          <div id="local-media" class="camera-cont">
            <div v-if="!previewVideo">
              <img :src="require('@/assets/images/Camera.png')" alt="">
              <p>{{ $t('call.noCamera.text') }}</p>
              <button class="waves-effect waves-light btn" @click="preview">{{ $t('call.noCamera.button') }} </button>
            </div>
            <div id="disable-cam" v-else>
              <button class="waves-effect waves-light btn" @click="preview">{{ $t('call.noCamera.button2') }} </button>
            </div>
          </div>
          <div class="requirements">
            <div class="requirement" v-if="consent == 'true'">
              <i class="material-icons check-box">check_box</i>
              <p> {{ $t('call.requirements.success[0]') }} </p>
            </div>
            <div class="requirement" v-else-if="consent == 'false'">
              <i class="material-icons cancel">cancel</i>
              <p> {{ $t('call.requirements.error[0]') }} </p>
            </div>
            <div class="requirement" v-else>
              <i class="material-icons error">error</i>
              <p> Consentimiento informado sin aceptar </p>
            </div>
            <div class="requirement" v-if="quota">
              <i class="material-icons check-box">check_box</i>
              <p> {{ $t('call.requirements.success[1]') }} </p>
            </div>
            <div class="requirement" v-else>
              <i class="material-icons error">error</i>
              <p> {{ $t('call.requirements.error[1]') }} </p>
            </div>
          </div>

          <div class="row buttons" v-if="!hasCall && !finished">
            <div class="col s12">
              <button class="waves-effect waves-light btn" @click="goToCall" :class="{ disabled : !online, available: online && !hasCall && consent === 'true' && quota }"><i class="material-icons-round">videocam</i>
                Iniciar videollamada </button>
            </div>
            <div class="col s12">
              <button id="message-btn" class="waves-effect waves-light btn" @click="openChat" :disabled="!online"><i class="material-icons-outlined">mail</i>
                Enviar mensaje
              </button>
            </div>

          </div>

          <div class="unattended-check d-flex" v-if="showCheck && !hasCall">
            <p>Marcar como no atendida</p>
            <div class="check-box" @click="unattendCall"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { httpKeycV1, httpKeycV2 } from '@/core/http';
  import store from '@/store'
  import router from '@/router';
  import Dialog from 'primevue/dialog';
  import codes from "@/data/codes"
  import { testCamera } from '@/core/vonage';
  import { database } from '@/core/firebase'
  import { set, get, ref, update } from '@firebase/database'

  export default({
    components: {
      Dialog
    },
    name: 'Call',
    data() {
      return {
        enable: false,
        roomId: '',
        callType: null,
        previewVideo: null,
        active: false,
        wrapUpCodes: [],
        displayModal: false,
        reason: null,
        finished: false,
        codes: codes,
        hasCall: false,
        opentab: false,
      }
    },
    props: {
      user: Object,
      online: Boolean,
      appointment: Object,
    },
    computed: {
      currentUser() {
        return store.getters.getUser;
      },
      userToken() {
        return store.getters.getToken;
      },
      notifications() {
        return store.getters.getNotifications.sort((a, b) => { return b.date - a.date })
      },
      notiLength() {
        return store.getters.getNotifications.length
      },
      quota() {
        return this.appointment.quota.trim().toLowerCase() == 'true'
      },
      consent() {
        return this.appointment.consent.trim().toLowerCase()
      },
      realCodes() {
        const locale = this.$i18n.locale
        if (locale == 'es') {
          return this.codes.codesEs
        } else if (locale == 'en') {
          return this.codes.codesEn
        } else {
          return this.codes.codesPr
        }
      },
      showCheck() {
        return process.env.VUE_APP_SHOW_PHONECALL && process.env.VUE_APP_SHOW_PHONECALL === 'true';
      }
    },
    watch: {
      notiLength() {
        this.activeCall()
      },
      appointment() {
        this.active = false
        this.enable = false
        this.verifyEnable()
      }
    },
    methods: {
      activeCall() {
        for (let i = 0; i < this.notifications.length; i++) {
          const n = this.notifications[i];
          if(n.data.notificationCode == "VD001" && n.data.appointmentId === this.appointment.id) {
            this.callType = n.data.callType
            this.roomId = n.data.twilioCallRoom
            this.enable = true
            if(!this.active) this.active = true
            break;
          }
        }
      },

      verifyEnable() {
        for (let i = 0; i < this.notifications.length; i++) {
          const n = this.notifications[i];
          if(n.data.notificationCode == "DT001" && n.data.appointmentId === this.appointment.id) {
            this.active = true;
            break;
          }
        }
      },

      async enableCall() {
        const { id, user, specialty } = this.appointment;
        let notiref = ref(database, 'notification/'+id);

        const notification = {
          data: {
            notificationCode: "DT001",
            title: "The doctor is ready!",
            message: "The doctor is waiting in the consulting room",
            appointmentId: id,
          },
          properties: {
            groupId: "calls",
            order: 2,
            showNotification: true,
            viewed: false,
          },
          timestamp: new Date().toISOString(),
        };

        let notiSnapshot = await get(notiref);

        if(!notiSnapshot.exists()) {
          await set(notiref, {
            Id: id,
            PatientId: user.Id,
            DoctorId: store.getters.getUser.Id,
            Specialty: specialty,
            AppId: store.getters.getPlan.AppId,
            CompanyId: store.getters.getCompany.Id, 
            PlanId: store.getters.getPlan.Id,
            Notifications: []
          })
        };

        const notifications = notiSnapshot.val().Notifications ? notiSnapshot.val().Notifications : [];
        notifications.push(notification);
        await update(notiref, {
          Notifications: notifications
        });
      },
      async enablePatient() {
        await this.enableCall();
        let id = this.appointment.id

        try {
          await httpKeycV1.post('/api/v1/notification/doctorcallacceptance', id, {
            headers: { 'Content-Type': 'application/json' }
          }).then((response) => {
            console.log(response)
          })
        } catch (error) {
          this.active = false
          console.log(error)
          return M.toast({
            html: 'Error al habilitar al paciente',
          });
        }
      },
      preview() {
        if(!this.previewVideo) {
          this.previewVideo = testCamera();
        } else {
          this.previewVideo.destroyPublisher();
          this.previewVideo = null;
        }
      },
      async goToCall() {
        await this.enablePatient();
        let route = router.resolve({ name: 'CallView', query: { token: this.userToken, appointmentId: this.appointment.id, patientId: this.appointment.user.Id, consultationDate: this.appointment.date } })
        let call = window.open(route.href, "_blank", "width=450,height=600,top=100,status=no,location=no,toolbar=no,menubar=no")
        this.hasCall = true
        var timer = setInterval(() => { 
            if(call.closed) {
              clearInterval(timer);
              this.displayModal = true
            }
        }, 1000);
      },
      async saveAppointment() {
        const body = {
          ConsultationId: this.appointment.id,
          ConsultationDate: new Date().toISOString(),
          PatientId: this.user.Id,
          DoctorId: store.getters.getUser.Id,
          Type: 2,
          Status: "4",
          Note: null,
          ReviewBySystems: null,
          PhisicalExam: null,
          Diagnoses: [],
          MedicalDisability: null,
          MedicalCertificate: null,
          Recipes: [],
          Orders: [],
          Analysis: null,
          Annotation: this.appointment.specialty,
        }
        
        try {
          let response = await httpKeycV2.post('/api/consultation', body)
          console.log(response)
        } catch (error) {
          console.log(error)
        }
      },
      async updateAppointment() {
        if (!this.reason) {
          return M.toast({
            html: this.$t("callExit.reason.alert"),
          });
        }

        const body = {
          StatusId: "4",
          AppointmentId: this.appointment.id,
          Annotation: this.reason.InternalCode,
          UserId: this.appointment.user.Id,
          CompanyId: process.env.VUE_APP_COMPANY_ID,
          AppId: process.env.VUE_APP_APP_ID,
        }

        try {
          await httpKeycV1.post('/api/agenda/appointment-status', body).then((response) => {
            if (response.status == 200) {
              this.displayModal = false
              this.enable = false
              this.finished = true
              this.$emit('refresh-calls')
            }
          })
          await this.saveAppointment();
        } catch (error) {
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },
      async markUnAttended() {
        const body = {
          StatusId: "5",
          AppointmentId: this.appointment.id,
          Annotation: '',
          UserId: this.appointment.user.Id,
          CompanyId: process.env.VUE_APP_COMPANY_ID,
          AppId: process.env.VUE_APP_APP_ID,
        }

        try {
          await httpKeycV1.post('/api/agenda/appointment-status', body).then((response) => {
            console.log(response)
            if (response.status == 200) {
              this.$emit('unattended', this.appointment)
            }
          })
        } catch (error) {
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },
      unattendCall() {
        this.$confirm.require({
          message: '¿Estas seguro que deseas marcar esta cita como “No atendida”?',
          acceptClass: 'btn-text go',
          rejectClass: 'btn-text cancel',
          acceptLabel: this.$t('callExit.validate.buttons[1]'),
          rejectLabel: this.$t('callExit.validate.buttons[0]'),
          accept: () => {
          },
          reject: async () => {
            await this.markUnAttended();
          }
        }) 
      },
      openChat() {
        this.$emit('open-chat')
      },
      async getWrapUpCodes() {
        let response = await httpKeycV1.get('/api/agenda/wrapup-codes')

        let data = response.data

        for (let i = 0; i < data.length; i++) {
          const elem = data[i];
          this.wrapUpCodes.push({
            Label: this.realCodes[i],
            InternalCode: elem.InternalCode
          })
        }
      },
      async closeRoom() {
        let body = {
          ScheduleId: this.appointment.id,
          RoomId: this.roomId
        }

        try {
          await httpKeycV1.post('/api/v1/twillio/finishcall', body).then((response) => {
            console.log(response)
          })
        } catch (error) {
          console.log(error)
        }
      },
      openKeyboard() {
        let route = router.resolve({ name: 'KeyboardView', query: { appointmentId: this.appointment.id, userId: this.appointment.user.Id, specialty: this.appointment.specialty } })
        let call = window.open(route.href, "_blank", "left=150,width=350,height=450,top=100,status=no,location=no,toolbar=no,menubar=no,scrollbars=no,resizable=no")
        this.opentab = true
        var timer = setInterval(() => { 
            if(call.closed) {
              clearInterval(timer);
              this.$confirm.require({
                message: '¿Logró atender al paciente mediante llamada telefónica?',
                acceptClass: 'btn-text go',
                rejectClass: 'btn-text cancel',
                acceptLabel: this.$t('callExit.validate.buttons[0]'),
                rejectLabel: this.$t('callExit.validate.buttons[1]'),
                accept: () => {
                  this.displayModal = true;
                  this.opentab = false;
                },
                reject: () => {
                  this.opentab = false;
                }
              }) 
            }
        }, 1000);
      },
    },
    mounted() {
      this.activeCall()
      this.getWrapUpCodes()
      this.verifyEnable()
      if (this.appointment.status == 'Attended') {
        this.finished = true
      } else {
        this.finished = false
      }
    },
    updated() {
      this.activeCall()
      if (this.appointment.status == 'Attended') {
        this.finished = true
      } else {
        this.finished = false
      }
    },
  })
</script>

<style scoped>
  #call {
    height: 100%;
    overflow: hidden;
  }

  #call>.row {
    height: 100%;
  }

  .call-cont {
    height: calc(100% - 60px);
    overflow-y: auto;
  }

  .title {
    background-color: #EFF0F4;
    padding-left: 30px;
    height: 60px;
    display: flex;
    align-items: center;
  }
  .title h3 {
    margin: 0;
  }

  .material-icons,
  .material-icons-outlined,
  .material-icons-round {
    margin-right: 6px;
    vertical-align: sub !important;
  }

  .requirement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
  }

  .requirement p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .call-data {
    justify-content: center;
  }

  .camera-cont p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #F6F6F8;
  }

  .camera-cont button {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary);
    background-color: #F6F6F8;
    border: 1px solid #C4C9D1;
  }

  button {
    border-radius: 8px;
    box-shadow: none;
    font-weight: 500;
  }

  .buttons .btn {
    background-color: var(--primary);
    color: #FBFCFF !important;
    width: 60%;
    margin-bottom: 12px;
    height: 40px;
    border-radius: 8px;
    text-transform: none;
    font-family: 'Poppins';
  }

  .buttons .btn.disabled {
    background-color: #E6F1F8 !important;
    color: #A5B3BB !important;
  }

  .buttons .btn.available {
    background-color: #2EE57C !important;
    color: #FBFCFF !important;
  }

  .buttons #message-btn {
    background-color: transparent !important;
    color: #3D4346 !important;
    border: 1px solid #A5B3BB;
  }

  .switch-cont {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .switch-cont span {
    margin-right: 20px;
  }

  #local-media {
    position: relative;
  }

  #disable-cam {
    position: absolute;
    bottom: 10px;
  }
  
  .camera-cont {
    width: 225px !important;
  }

  .unattended-check {
    justify-content: center;
  }

  .unattended-check .check-box {
    width: 17px;
    height: 17px;
    background: #CAD8DF;
    border-radius: 2px;
    margin: 3px 0px  0px 7px;
    cursor: pointer;
  }
</style>