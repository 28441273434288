import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey           : process.env.VUE_APP_APIKEY,
  authDomain       : process.env.VUE_APP_AUTHDOMAIN,
  databaseURL      : process.env.VUE_APP_DATABASEURL,
  projectId        : process.env.VUE_APP_PROJECTId,
  storageBucket    : process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId            : process.env.VUE_APP_APPId,
  measurementId    : process.env.VUE_APP_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const database = getDatabase(app)
const auth = getAuth(app)

export { database, auth }
