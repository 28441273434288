
  import {
    defineComponent
  } from "vue";

  export default defineComponent({
    props: {
      type: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        options: [{
          title: this.$t('callcenterInfo.upcoming.title'),
          subtitle: this.$t('callcenterInfo.upcoming.subtitle'),
          img: 'CallcenterIcon.png',
        }, {
          title: this.$t('callcenterInfo.finished.title'),
          subtitle: this.$t('callcenterInfo.finished.subtitle'),
          img: 'CallCenterEnded.png',
        }, {
          title: this.$t('callcenterInfo.unAttended.title'),
          subtitle: this.$t('callcenterInfo.unAttended.subtitle'),
          img: 'CallCenterCancel.png',
        },],
      }
    },
    watch: {
      '$i18n.locale': function () {
        this.options[0].title = this.$t('callcenterInfo.upcoming.title');
        this.options[0].subtitle = this.$t('callcenterInfo.upcoming.subtitle');
        this.options[1].title = this.$t('callcenterInfo.finished.title');
        this.options[1].subtitle = this.$t('callcenterInfo.finished.subtitle');
        this.options[2].title = this.$t('callcenterInfo.unAttended.title');
        this.options[2].subtitle = this.$t('callcenterInfo.unAttended.subtitle');
      }
    },
  });
