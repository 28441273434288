import frequency from '@/data/frequency.json'
import when from '@/data/when.json'
import axios from 'axios'

export default {
  data() {
    return {
      frequency: frequency,
      when: when,
    }
  },
  computed: {
    frequencies() {
      const locale = this.$i18n.locale
      if (locale == 'es') {
        return this.frequency.FrequencyEs
      } else if (locale == 'en') {
        return this.frequency.FrequencyEn
      } else {
        return this.frequency.FrequencyPr
      }
    },
    whenList() {
      const locale = this.$i18n.locale
      if (locale == 'es') {
        return this.when.whenEs
      } else if (locale == 'en') {
        return this.when.whenEn
      } else {
        return this.when.whenPr
      }
    }
  },
  methods: {
    async getCie10(event) {
      let term = event.query
      if (term.length >= 3) {
        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
          appid: process.env.VUE_APP_APP_ID,
          companyid: process.env.VUE_APP_COMPANY_ID,
        };


        const params = {
          term: term,
          count: 50,
          appid: process.env.VUE_APP_APP_ID,
          companyid: process.env.VUE_APP_COMPANY_ID,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/cie10", {
            headers,
            params
          }
        );

        let data = response.data
        let newData = []
        for (let i = 0; i < data.length; i++) {
          const element = {
            Label: data[i].Code + ' - ' + data[i].Name,
            Code: data[i].Code,
            Name: data[i].Name,
          };
          newData.push(element)
        }

        this.cie10info = newData
      }
    },

    async getMedicines(string) {

      if (this.search.length >= 3) {
        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
          appid: process.env.VUE_APP_APP_ID,
          companyid: process.env.VUE_APP_COMPANY_ID,
        };

        const params = {
          term: string,
          count: 20,
          appid: process.env.VUE_APP_APP_ID,
          companyid: process.env.VUE_APP_COMPANY_ID,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/medicines", {
            headers,
            params
          }
        );

        this.medicines = response.data

      }
    },

    toggle(event, ref, index) {
      this.$refs[ref][index].toggle(event);
    },

    isNumber(evt) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
      const keyPressed = evt.key;
  
      if (!keysAllowed.includes(keyPressed)) {
          evt.preventDefault();
      }
    },

    convertDate(date) {
      return date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate())
    },

    addZero(date) {
      if ((date / 10) < 1)
        return '0' + date

      return date
    },
  }
}