<template>
  <div id="vitals">
    <ul class="collapsible">
      <li class="appointment-note">
        <div class="note-header collapsible-header" @click="getPressure">
          <p>{{ $t("vitals.titles[0]") }}</p>
          <a>
            {{ $t("vitals.open") }}
            <i class="material-icons">expand_more</i>
          </a>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col s8">
              <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" @dayclick="getPressure" >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row">
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="p-filled"
                        />
                        <label for="hospi-start">{{ $t("vitals.dates[0]") }}</label>
                      </span>
                    </div>
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="p-filled"
                        />
                        <label for="hospi-end">{{ $t("vitals.dates[1]") }}</label>
                      </span>
                    </div>                   
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="input-field col s4">
              <a class="btn btn-outlined btn-rounded waves-effect waves-ligh modal-trigger" href="#addPressure-modal"><i class="material-icons">add</i>
              {{ $t("newCon.add") }}</a>
            </div>
          </div>
          <Chart ref="chartPressure" type="line" :data="pressureVitals" :options="pressureOptions" />
        </div>
      </li>
      
      <li class="appointment-note">
        <div class="note-header collapsible-header" @click="getGluco">
          <p>{{ $t("vitals.titles[1]") }}</p>
          <a>
            {{ $t("vitals.open") }}
            <i class="material-icons">expand_more</i>
          </a>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col s8">
              <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" @dayclick="getGluco" >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row">
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="p-filled"
                        />
                        <label for="hospi-start">{{ $t("vitals.dates[0]") }}</label>
                      </span>
                    </div>
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="p-filled"
                        />
                        <label for="hospi-end">{{ $t("vitals.dates[1]") }}</label>
                      </span>
                    </div>                   
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="input-field col s4">
              <a class="btn btn-outlined btn-rounded waves-effect waves-ligh modal-trigger" href="#addGluco-modal"><i class="material-icons">add</i>
              {{ $t("newCon.add") }}</a>
            </div>
          </div>
          <Chart ref="chartGluco" type="line" :data="glucoVitals" :options="glucoOptions" />
        </div>
      </li>

      <li class="appointment-note">
        <div class="note-header collapsible-header" @click="getOxi">
          <p>{{ $t("vitals.titles[2]") }}</p>
          <a>
            {{ $t("vitals.open") }}
            <i class="material-icons">expand_more</i>
          </a>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col s8">
              <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" @dayclick="getOxi" >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row">
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="p-filled"
                        />
                        <label for="hospi-start">{{ $t("vitals.dates[0]") }}</label>
                      </span>
                    </div>
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="p-filled"
                        />
                        <label for="hospi-end">{{ $t("vitals.dates[1]") }}</label>
                      </span>
                    </div>                   
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="input-field col s4">
              <a class="btn btn-outlined btn-rounded waves-effect waves-ligh modal-trigger" href="#addOxi-modal"><i class="material-icons">add</i>
              {{ $t("newCon.add") }}</a>
            </div>
          </div>
          <Chart ref="chartOxi" type="line" :data="oxiVitals" :options="oxiOptions" />
        </div>
      </li>

      <li class="appointment-note">
        <div class="note-header collapsible-header" @click="getHeart">
          <p>{{ $t("vitals.titles[3]") }}</p>
          <a>
            {{ $t("vitals.open") }}
            <i class="material-icons">expand_more</i>
          </a>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col s8">
              <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" @dayclick="getHeart" >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row">
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="p-filled"
                        />
                        <label for="hospi-start">{{ $t("vitals.dates[0]") }}</label>
                      </span>
                    </div>
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="p-filled"
                        />
                        <label for="hospi-end">{{ $t("vitals.dates[1]") }}</label>
                      </span>
                    </div>                   
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="input-field col s4">
              <a class="btn btn-outlined btn-rounded waves-effect waves-ligh modal-trigger" href="#addHeart-modal"><i class="material-icons">add</i>
              {{ $t("newCon.add") }}</a>
            </div>
          </div>
          <Chart ref="chartHeart" type="line" :data="heartVitals" :options="pressureOptions" />
        </div>
      </li>

      <li class="appointment-note">
        <div class="note-header collapsible-header" @click="getBreath">
          <p>{{ $t("vitals.titles[4]") }}</p>
          <a>
            {{ $t("vitals.open") }}
            <i class="material-icons">expand_more</i>
          </a>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col s8">
              <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" @dayclick="getBreath">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row">
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="p-filled"
                        />
                        <label for="hospi-start">{{ $t("vitals.dates[0]") }}</label>
                      </span>
                    </div>
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="p-filled"
                        />
                        <label for="hospi-end">{{ $t("vitals.dates[1]") }}</label>
                      </span>
                    </div>                   
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="input-field col s4">
              <a class="btn btn-outlined btn-rounded waves-effect waves-ligh modal-trigger" href="#addBreath-modal"><i class="material-icons">add</i>
              {{ $t("newCon.add") }}</a>
            </div>
          </div>
          <Chart ref="chartBreath" type="line" :data="breathVitals" :options="breathOptions" />
        </div>
      </li>

      <li class="appointment-note">
        <div class="note-header collapsible-header" @click="getTemp">
          <p>{{ $t("vitals.titles[5]") }}</p>
          <a>
            {{ $t("vitals.open") }}
            <i class="material-icons">expand_more</i>
          </a>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col s8">
              <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" @dayclick="getTemp" >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row">
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="p-filled"
                        />
                        <label for="hospi-start">{{ $t("vitals.dates[0]") }}</label>
                      </span>
                    </div>
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="p-filled"
                        />
                        <label for="hospi-end">{{ $t("vitals.dates[1]") }}</label>
                      </span>
                    </div>                   
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="input-field col s4">
              <a class="btn btn-outlined btn-rounded waves-effect waves-ligh modal-trigger" href="#addTemp-modal"><i class="material-icons">add</i>
              {{ $t("newCon.add") }}</a>
            </div>
          </div>
          <Chart ref="chartTemp" type="line" :data="tempVitals" :options="tempOptions" />
        </div>
      </li>

      <li class="appointment-note">
        <div class="note-header collapsible-header" @click="getWeight">
          <p>{{ $t("vitals.titles[6]") }}</p>
          <a>
            {{ $t("vitals.open") }}
            <i class="material-icons">expand_more</i>
          </a>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col s8">
              <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" @dayclick="getWeight" >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row">
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="p-filled"
                        />
                        <label for="hospi-start">{{ $t("vitals.dates[0]") }}</label>
                      </span>
                    </div>
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="p-filled"
                        />
                        <label for="hospi-end">{{ $t("vitals.dates[1]") }}</label>
                      </span>
                    </div>                   
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="input-field col s4">
              <a class="btn btn-outlined btn-rounded waves-effect waves-ligh modal-trigger" href="#addWeight-modal"><i class="material-icons">add</i>
              {{ $t("newCon.add") }}</a>
            </div>
          </div>
          <Chart ref="chartWeight" type="line" :data="weightVitals" :options="weightOptions" />
        </div>
      </li>

      <li class="appointment-note">
        <div class="note-header collapsible-header" @click="getHeight">
          <p>{{ $t("vitals.titles[7]") }}</p>
          <a>
            {{ $t("vitals.open") }}
            <i class="material-icons">expand_more</i>
          </a>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col s8">
              <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" @dayclick="getHeight" >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row">
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="p-filled"
                        />
                        <label for="hospi-start">{{ $t("vitals.dates[0]") }}</label>
                      </span>
                    </div>
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="p-filled"
                        />
                        <label for="hospi-end">{{ $t("vitals.dates[1]") }}</label>
                      </span>
                    </div>                   
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="input-field col s4">
              <a class="btn btn-outlined btn-rounded waves-effect waves-ligh modal-trigger" href="#addHeight-modal"><i class="material-icons">add</i>
              {{ $t("newCon.add") }}</a>
            </div>
          </div>
          <Chart ref="chartHeight" type="line" :data="heightVitals" :options="cmOptions" />
        </div>
      </li>

      <li class="appointment-note">
        <div class="note-header collapsible-header" @click="getPeri">
          <p>{{ $t("vitals.titles[8]") }}</p>
          <a>
            {{ $t("vitals.open") }}
            <i class="material-icons">expand_more</i>
          </a>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col s8">
              <v-date-picker :locale="locale" v-model="range" is-range :max-date="new Date()" @dayclick="getPeri" >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="row">
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="p-filled"
                        />
                        <label for="hospi-start">{{ $t("vitals.dates[0]") }}</label>
                      </span>
                    </div>
                    <div class="col s6">
                      <span class="p-float-label">
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="p-filled"
                        />
                        <label for="hospi-end">{{ $t("vitals.dates[1]") }}</label>
                      </span>
                    </div>                   
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="input-field col s4">
              <a class="btn btn-outlined btn-rounded waves-effect waves-ligh modal-trigger" href="#addPeri-modal"><i class="material-icons">add</i>
              {{ $t("newCon.add") }}</a>
            </div>
          </div>
          <Chart ref="chartPeri" type="line" :data="periVitals" :options="cmOptions" />
        </div>
      </li>
    </ul>

    <!-- Add pressure modal -->
    <div id="addPressure-modal" class="modal modal-small">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("vitals.modals.titles[0]") }}</h4>
        <div class="row">
          <div class="input-field col s12 m12 l6 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText id="measure-pressure-1" name="measure-pressure-1" type="text" v-model="measurePress1" autocomplete="off"/>
                <label for="measure-pressure-1">{{ $t("vitals.modals.pressureLabels[0]") }}</label>
              </span>
            </div>
            <p>mmhg</p>
          </div>
          <div class="input-field col s12 m12 l6 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText id="measure-pressure-2" name="measure-pressure-2" type="text" v-model="measurePress2" autocomplete="off"/>
                <label for="measure-pressure-2">{{ $t("vitals.modals.pressureLabels[1]") }}</label>
              </span>
            </div>
            <p>mmhg</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel")}}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addPressure">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end Add pressure modal -->

    <!-- Add gluco modal -->
    <div id="addGluco-modal" class="modal modal-small">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("vitals.modals.titles[1]") }}</h4>
        <div class="row">
          <div class="input-field col s12 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText id="measure-gluco" name="measure-gluco" type="text" v-model="measureGluco" autocomplete="off"/>
                <label for="measure-gluco">{{ $t("vitals.modals.label") }}</label>
              </span>
            </div>
            <p>mg/dl</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel")}}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addGluco">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end Add gluco modal -->

    <!-- Add oxi modal -->
    <div id="addOxi-modal" class="modal modal-small">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("vitals.modals.titles[2]") }}</h4>
        <div class="row">
          <div class="input-field col s12 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText id="measure-oxi" name="measure-oxi" type="text" v-model="measureOxi" autocomplete="off"/>
                <label for="measure-oxi">{{ $t("vitals.modals.label") }}</label>
              </span>
            </div>
            <p>%</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel")}}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addOxi">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end Add oxi modal -->

    <!-- Add heart modal -->
    <div id="addHeart-modal" class="modal modal-small">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("vitals.modals.titles[3]") }}</h4>
        <div class="row">
          <div class="input-field col s12 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText id="measure-heart" name="measure-heart" type="text" v-model="measureHeart" autocomplete="off"/>
                <label for="measure-heart">{{ $t("vitals.modals.label") }}</label>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel")}}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addHeart">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end Add heart modal -->

    <!-- Add Breath modal -->
    <div id="addBreath-modal" class="modal modal-small">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("vitals.modals.titles[4]") }}</h4>
        <div class="row">
          <div class="input-field col s12 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText id="measure-Breath" name="measure-Breath" type="text" v-model="measureBreath" autocomplete="off"/>
                <label for="measure-Breath">{{ $t("vitals.modals.label") }}</label>
              </span>
            </div>
            <p>PPM</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel")}}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addBreath">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end Add Breath modal -->

    <!-- Add temp modal -->
    <div id="addTemp-modal" class="modal modal-small">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("vitals.modals.titles[5]") }}</h4>
        <div class="row">
          <div class="input-field col s12 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText id="measure-temp" name="measure-temp" type="text" v-model="measureTemp" autocomplete="off"/>
                <label for="measure-temp">{{ $t("vitals.modals.label") }}</label>
              </span>
            </div>
            <p>C</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel")}}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addTemp">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end Add temp modal -->

    <!-- Add weight modal -->
    <div id="addWeight-modal" class="modal modal-small">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("vitals.modals.titles[6]") }}</h4>
        <div class="row">
          <div class="input-field col s12 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText v-on:keypress="isNumber($event)" id="measure-weight" name="measure-weight" type="number" v-model="measureWeight" autocomplete="off"/>
                <label for="measure-weight">{{ $t("vitals.modals.label") }}</label>
              </span>
            </div>
            <p>kg</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel")}}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addWeight">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end Add weight modal -->

    <!-- Add height modal -->
    <div id="addHeight-modal" class="modal modal-small">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("vitals.modals.titles[7]") }}</h4>
        <div class="row">
          <div class="input-field col s12 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText id="measure-height" name="measure-height" type="text" v-model="measureHeight" autocomplete="off"/>
                <label for="measure-height">{{ $t("vitals.modals.label") }}</label>
              </span>
            </div>
            <p>cm</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel")}}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addHeight">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end Add height modal -->

    <!-- Add peri modal -->
    <div id="addPeri-modal" class="modal modal-small">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("vitals.modals.titles[8]") }}</h4>
        <div class="row">
          <div class="input-field col s12 d-flex">
            <div class="wrap">
              <span class="p-float-label">
                <InputText id="measure-peri" name="measure-peri" type="text" v-model="measurePeri" autocomplete="off"/>
                <label for="measure-peri">{{ $t("vitals.modals.label") }}</label>
              </span>
            </div>
            <p>cm</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel")}}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addPeri">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end Add peri modal -->
    
  </div>

</template>

<script>
  import { ref } from 'vue'
  import axios from "axios";
  import store from "@/store";
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'Vitals',
    computed: {
      userToken() {
        return store.getters.getToken;
      },
      locale() {
        return this.$i18n.locale
      },
    },
    props: {
      patient: Object,
      consultationId: String,
    },
    setup() {
      const { t } = useI18n()
      const s = useStore()

      const chartPressure = ref()
      const chartGluco = ref()
      const chartOxi = ref()
      const chartHeart = ref()
      const chartBreath = ref()
      const chartTemp = ref()
      const chartWeight = ref()
      const chartHeight = ref()
      const chartPeri = ref()

      const changeLabels = (label1, label2) => {
        const chart = chartPressure.value.chart
        chart.data.datasets[0].label = label1
        chart.data.datasets[1].label = label2
        chart.update()
      }

      const clearChartData = (chart) => {
        chart.data.labels = []
        chart.data.datasets[0].data = []
      }

      const clearPressureData = () => {
        const chart = chartPressure.value.chart
        chart.data.labels = []
        chart.data.datasets[0].data = []
        chart.data.datasets[1].data = []
      }

      const addChartData = (chart, label, data) => {
        chart.data.labels.push(label)
        chart.data.datasets[0].data.push(data)
        chart.update()
      }

      const addPressureData = (label, data1, data2) => {
        const chart = chartPressure.value.chart
        chart.data.labels.push(label)
        chart.data.datasets[0].data.push(data1)
        chart.data.datasets[1].data.push(data2)
        chart.update()
      }

      const hexToRgb = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }

      const rgb = hexToRgb(s.getters.getColor.primary)
      const colorP = 'rgba('+ rgb.r +', ' + rgb.g + ', ' + rgb.b + ', 1)'
      const colorS = 'rgba('+ rgb.r +', ' + rgb.g + ', ' + rgb.b + ', 0.45)'

      return {
        t,
        chartPressure,
        chartGluco,
        chartOxi,
        chartHeart,
        chartBreath,
        chartTemp,
        chartWeight,
        chartHeight,
        chartPeri,
        clearChartData,
        clearPressureData,
        addChartData,
        addPressureData,
        changeLabels,
        pressureVitals: {
          labels: [],
          datasets: [{
            label: t('vitals.modals.pressureLabels[0]'),
            data: [],
            fill: false,
            borderColor: colorP,
            tension: .4
          }, {
            label: t('vitals.modals.pressureLabels[1]'),
            data: [],
            fill: false,
            borderColor: colorS,
            tension: .4
          }]
        },
        glucoVitals: {
          labels: [],
          datasets: [{
            label: '',
            data: [],
            fill: false,
            borderColor: s.getters.getColor.primary,
            tension: .4
          }]
        },
        oxiVitals: {
          labels: [],
          datasets: [{
            label: '',
            data: [],
            fill: false,
            borderColor: s.getters.getColor.primary,
            tension: .4
          }]
        },
        heartVitals: {
          labels: [],
          datasets: [{
            label: '',
            data: [],
            fill: false,
            borderColor: s.getters.getColor.primary,
            tension: .4
          }]
        },
        breathVitals: {
          labels: [],
          datasets: [{
            label: '',
            data: [],
            fill: false,
            borderColor: s.getters.getColor.primary,
            tension: .4
          }]
        },
        tempVitals: {
          labels: [],
          datasets: [{
            label: '',
            data: [],
            fill: false,
            borderColor: s.getters.getColor.primary,
            tension: .4
          }]
        },
        weightVitals: {
          labels: [],
          datasets: [{
            label: '',
            data: [],
            fill: false,
            borderColor: s.getters.getColor.primary,
            tension: .4
          }]
        },
        heightVitals: {
          labels: [],
          datasets: [{
            label: '',
            data: [],
            fill: false,
            borderColor: s.getters.getColor.primary,
            tension: .4
          }]
        },
        periVitals: {
          labels: [],
          datasets: [{
            label: '',
            data: [],
            fill: false,
            borderColor: s.getters.getColor.primary,
            tension: .4
          }]
        },
      }
    },
    watch: {
      '$i18n.locale': function () {
        let label1 = this.$t('vitals.modals.pressureLabels[0]')
        let label2 = this.$t('vitals.modals.pressureLabels[1]')
        this.changeLabels(label1, label2)
      }
    },
    data() {
      return {
        pressureOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#4F4F4F'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#979797',
              },
              grid: {
                color: '#EFF3F9'
              }
            },
            y: {
              ticks: {
                color: '#979797',
                callback: function (value, index, ticks) {
                  return value + '';
                }
              },
              grid: {
                color: '#EFF3F9'
              }
            }
          }
        },
        glucoOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#4F4F4F'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#979797',
              },
              grid: {
                color: '#EFF3F9'
              }
            },
            y: {
              ticks: {
                color: '#979797',
                callback: function (value, index, ticks) {
                  return value + ' mg/dl';
                }
              },
              grid: {
                color: '#EFF3F9'
              }
            }
          }
        },
        oxiOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#4F4F4F'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#979797',
              },
              grid: {
                color: '#EFF3F9'
              }
            },
            y: {
              ticks: {
                color: '#979797',
                callback: function (value, index, ticks) {
                  return value + '%';
                }
              },
              grid: {
                color: '#EFF3F9'
              }
            }
          }
        },
        tempOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#4F4F4F'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#979797',
              },
              grid: {
                color: '#EFF3F9'
              }
            },
            y: {
              ticks: {
                color: '#979797',
                callback: function (value, index, ticks) {
                  return value + 'C';
                }
              },
              grid: {
                color: '#EFF3F9'
              }
            }
          }
        },
        breathOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#4F4F4F'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#979797',
              },
              grid: {
                color: '#EFF3F9'
              }
            },
            y: {
              ticks: {
                color: '#979797',
                callback: function (value, index, ticks) {
                  return value + 'PPM';
                }
              },
              grid: {
                color: '#EFF3F9'
              }
            }
          }
        },
        weightOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#4F4F4F'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#979797',
              },
              grid: {
                color: '#EFF3F9'
              }
            },
            y: {
              ticks: {
                color: '#979797',
                callback: function (value, index, ticks) {
                  return value + 'kg';
                }
              },
              grid: {
                color: '#EFF3F9'
              }
            }
          }
        },
        cmOptions: {
          plugins: {
            legend: {
              labels: {
                color: '#4F4F4F'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#979797',
              },
              grid: {
                color: '#EFF3F9'
              }
            },
            y: {
              ticks: {
                color: '#979797',
                callback: function (value, index, ticks) {
                  return value + ' cm';
                }
              },
              grid: {
                color: '#EFF3F9'
              }
            }
          }
        },
        range: {
          start: null,
          end: null,
        },
        measurePress1: '',
        measurePress2: '',
        measureGluco: '',
        measureOxi: '',
        measureHeart: '',
        measureBreath: '',
        measureTemp: '',
        measureWeight: '',
        measureHeight: '',
        measurePeri: '',
      }
    },
    methods: {
      async getPressure() {
        this.clearPressureData()
        if(!this.range.start && !this.range.end) {
          return null
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        const params = {
          patientId: this.patient.Id,
          count: 100,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/arterial", {
            headers,
            params
          }
        );

        const data = response.data
        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let dateC = new Date(e.ConsultationDate).setHours(0,0,0,0)
          if(dateC >= this.range.start.setHours(0,0,0,0) && dateC <= this.range.end.setHours(0,0,0,0)) {
            let date = e.ConsultationDate.split("T")
            let label = date[0].split("-")[2] + '/' + date[0].split("-")[1] + ' - ' + date[1].split(":")[0] + ':' + date[1].split(":")[1]
            this.addPressureData(label, e.Pressure.Systolic, e.Pressure.Diastolic)
          }
        }
      },

      async addPressure() {
        if(this.measurePress1.trim() == "" || !this.measurePress1 || this.measurePress2.trim() == "" || !this.measurePress2) {
          return M.toast({
            html: this.$t("vitals.modals.validateMsg"),
          });
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds())

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          DoctorId: store.getters.getUser.Id,
          Pressure: {
            Systolic: parseInt(this.measurePress1),
            Diastolic: parseInt(this.measurePress2),
          }
        }

        var elem = document.getElementById("addPressure-modal");
        var instance = M.Modal.getInstance(elem);

        try {
          await axios.post(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/arterial", body,{
              headers,
            }
          ).then(response => {
            if(response.status == 200) {
              M.toast({
                html: this.$t("vitals.modals.generalMsg"),
              });
              instance.close()
              this.getPressure()
            }
          });
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },

      async getGluco() {
        this.clearChartData(this.chartGluco.chart)
        if(!this.range.start && !this.range.end) {
          return null
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        const params = {
          patientId: this.patient.Id,
          count: 100,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/glucometry", {
            headers,
            params
          }
        );

        const data = response.data

        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let dateC = new Date(e.ConsultationDate).setHours(0,0,0,0)
          if(dateC >= this.range.start.setHours(0,0,0,0) && dateC <= this.range.end.setHours(0,0,0,0)) {
            let date = e.ConsultationDate.split("T")
            let label = date[0].split("-")[2] + '/' + date[0].split("-")[1] + ' - ' + date[1].split(":")[0] + ':' + date[1].split(":")[1]
            this.addChartData(this.chartGluco.chart, label, e.Diagnose)
          }
        }
      },

      async addGluco() {
        if(this.measureGluco.trim() == "" || !this.measureGluco) {
          return M.toast({
            html: this.$t("vitals.modals.validateMsg"),
          });
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds())

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          DoctorId: store.getters.getUser.Id,
          Measurement: parseInt(this.measureGluco)
        }

        var elem = document.getElementById("addGluco-modal");
        var instance = M.Modal.getInstance(elem);

        try {
          await axios.post(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/glucometry", body,{
              headers,
            }
          ).then(response => {
            if(response.status == 200) {
              M.toast({
                html: this.$t("vitals.modals.generalMsg"),
              });
              instance.close()
              this.getGluco()
            }
          });
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },

      async getOxi() {
        this.clearChartData(this.chartOxi.chart)
        if(!this.range.start && !this.range.end) {
          return null
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        const params = {
          patientId: this.patient.Id,
          count: 100,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/oximetry", {
            headers,
            params
          }
        );

        const data = response.data

        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let dateC = new Date(e.ConsultationDate).setHours(0,0,0,0)
          if(dateC >= this.range.start.setHours(0,0,0,0) && dateC <= this.range.end.setHours(0,0,0,0)) {
            let date = e.ConsultationDate.split("T")
            let label = date[0].split("-")[2] + '/' + date[0].split("-")[1] + ' - ' + date[1].split(":")[0] + ':' + date[1].split(":")[1]
            this.addChartData(this.chartOxi.chart, label, e.Diagnose)
          }
        }
      },

      async addOxi() {
        if(this.measureOxi.trim() == "" || !this.measureOxi) {
          return M.toast({
            html: this.$t("vitals.modals.validateMsg"),
          });
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds())

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          DoctorId: store.getters.getUser.Id,
          Measurement: parseInt(this.measureOxi)
        }

        var elem = document.getElementById("addOxi-modal");
        var instance = M.Modal.getInstance(elem);

        try {
          await axios.post(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/oximetry", body,{
              headers,
            }
          ).then(response => {
            if(response.status == 200) {
              M.toast({
                html: this.$t("vitals.modals.generalMsg"),
              });
              instance.close()
              this.getOxi()
            }
          });
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },

      async getHeart() {
        this.clearChartData(this.chartHeart.chart)
        if(!this.range.start && !this.range.end) {
          return null
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        const params = {
          patientId: this.patient.Id,
          count: 100,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/heartRate", {
            headers,
            params
          }
        );

        const data = response.data

        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let dateC = new Date(e.ConsultationDate).setHours(0,0,0,0)
          if(dateC >= this.range.start.setHours(0,0,0,0) && dateC <= this.range.end.setHours(0,0,0,0)) {
            let date = e.ConsultationDate.split("T")
            let label = date[0].split("-")[2] + '/' + date[0].split("-")[1] + ' - ' + date[1].split(":")[0] + ':' + date[1].split(":")[1]
            this.addChartData(this.chartHeart.chart, label, e.Diagnose)
          }
        }
      },

      async addHeart() {
        if(this.measureHeart.trim() == "" || !this.measureHeart) {
          return M.toast({
            html: this.$t("vitals.modals.validateMsg"),
          });
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds())

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          DoctorId: store.getters.getUser.Id,
          Measurement: parseInt(this.measureHeart)
        }

        var elem = document.getElementById("addHeart-modal");
        var instance = M.Modal.getInstance(elem);

        try {
          await axios.post(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/heartRate", body,{
              headers,
            }
          ).then(response => {
            if(response.status == 200) {
              M.toast({
                html: this.$t("vitals.modals.generalMsg"),
              });
              instance.close()
              this.getHeart()
            }
          });
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },

      async getBreath() {
        this.clearChartData(this.chartBreath.chart)
        if(!this.range.start && !this.range.end) {
          return null
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        const params = {
          patientId: this.patient.Id,
          count: 100,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/breathingFrequency", {
            headers,
            params
          }
        );

        const data = response.data

        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let dateC = new Date(e.ConsultationDate).setHours(0,0,0,0)
          if(dateC >= this.range.start.setHours(0,0,0,0) && dateC <= this.range.end.setHours(0,0,0,0)) {
            let date = e.ConsultationDate.split("T")
            let label = date[0].split("-")[2] + '/' + date[0].split("-")[1] + ' - ' + date[1].split(":")[0] + ':' + date[1].split(":")[1]
            this.addChartData(this.chartBreath.chart, label, e.Diagnose)
          }
        }
      },

      async addBreath() {
        if(this.measureBreath.trim() == "" || !this.measureBreath) {
          return M.toast({
            html: this.$t("vitals.modals.validateMsg"),
          });
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds())

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          DoctorId: store.getters.getUser.Id,
          Measurement: parseInt(this.measureBreath)
        }

        var elem = document.getElementById("addBreath-modal");
        var instance = M.Modal.getInstance(elem);

        try {
          await axios.post(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/breathingFrequency", body,{
              headers,
            }
          ).then(response => {
            if(response.status == 200) {
              M.toast({
                html: this.$t("vitals.modals.generalMsg"),
              });
              instance.close()
              this.getBreath()
            }
          });
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },

      async getTemp() {
        this.clearChartData(this.chartTemp.chart)
        if(!this.range.start && !this.range.end) {
          return null
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        const params = {
          patientId: this.patient.Id,
          count: 100,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/temperature", {
            headers,
            params
          }
        );

        const data = response.data

        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let dateC = new Date(e.ConsultationDate).setHours(0,0,0,0)
          if(dateC >= this.range.start.setHours(0,0,0,0) && dateC <= this.range.end.setHours(0,0,0,0)) {
            let date = e.ConsultationDate.split("T")
            let label = date[0].split("-")[2] + '/' + date[0].split("-")[1] + ' - ' + date[1].split(":")[0] + ':' + date[1].split(":")[1]
            this.addChartData(this.chartTemp.chart, label, e.Diagnose)
          }
        }
      },

      async addTemp() {
        if(this.measureTemp.trim() == "" || !this.measureTemp) {
          return M.toast({
            html: this.$t("vitals.modals.validateMsg"),
          });
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds())

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          DoctorId: store.getters.getUser.Id,
          Measurement: parseInt(this.measureTemp)
        }

        var elem = document.getElementById("addTemp-modal");
        var instance = M.Modal.getInstance(elem);

        try {
          await axios.post(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/temperature", body,{
              headers,
            }
          ).then(response => {
            if(response.status == 200) {
              M.toast({
                html: this.$t("vitals.modals.generalMsg"),
              });
              instance.close()
              this.getTemp()
            }
          });
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },

      async getWeight() {
        this.clearChartData(this.chartWeight.chart)
        if(!this.range.start && !this.range.end) {
          return null
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        const params = {
          patientId: this.patient.Id,
          count: 100,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/weight", {
            headers,
            params
          }
        );

        const data = response.data

        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let dateC = new Date(e.ConsultationDate).setHours(0,0,0,0)
          if(dateC >= this.range.start.setHours(0,0,0,0) && dateC <= this.range.end.setHours(0,0,0,0)) {
            let date = e.ConsultationDate.split("T")
            let label = date[0].split("-")[2] + '/' + date[0].split("-")[1] + ' - ' + date[1].split(":")[0] + ':' + date[1].split(":")[1]
            this.addChartData(this.chartWeight.chart, label, e.Diagnose)
          }
        }
      },

      async addWeight() {
        if(this.measureWeight.trim() == "" || !this.measureWeight) {
          return M.toast({
            html: this.$t("vitals.modals.validateMsg"),
          });
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds())

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          DoctorId: store.getters.getUser.Id,
          Measurement: parseInt(this.measureWeight)
        }

        var elem = document.getElementById("addWeight-modal");
        var instance = M.Modal.getInstance(elem);

        try {
          await axios.post(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/weight", body,{
              headers,
            }
          ).then(response => {
            if(response.status == 200) {
              M.toast({
                html: this.$t("vitals.modals.generalMsg"),
              });
              instance.close()
              this.getWeight()
            }
          });
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },

      async getHeight() {
        this.clearChartData(this.chartHeight.chart)
        if(!this.range.start && !this.range.end) {
          return null
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        const params = {
          patientId: this.patient.Id,
          count: 100,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/size", {
            headers,
            params
          }
        );

        const data = response.data

        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let dateC = new Date(e.ConsultationDate).setHours(0,0,0,0)
          if(dateC >= this.range.start.setHours(0,0,0,0) && dateC <= this.range.end.setHours(0,0,0,0)) {
            let date = e.ConsultationDate.split("T")
            let label = date[0].split("-")[2] + '/' + date[0].split("-")[1] + ' - ' + date[1].split(":")[0] + ':' + date[1].split(":")[1]
            this.addChartData(this.chartHeight.chart, label, e.Diagnose)
          }
        }
      },

      async addHeight() {
        if(this.measureHeight.trim() == "" || !this.measureHeight) {
          return M.toast({
            html: this.$t("vitals.modals.validateMsg"),
          });
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds())

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          DoctorId: store.getters.getUser.Id,
          Measurement: parseInt(this.measureHeight)
        }

        var elem = document.getElementById("addHeight-modal");
        var instance = M.Modal.getInstance(elem);

        try {
          await axios.post(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/size", body,{
              headers,
            }
          ).then(response => {
            if(response.status == 200) {
              M.toast({
                html: this.$t("vitals.modals.generalMsg"),
              });
              instance.close()
              this.getHeight()
            }
          });
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },

      async getPeri() {
        this.clearChartData(this.chartPeri.chart)
        if(!this.range.start && !this.range.end) {
          return null
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        const params = {
          patientId: this.patient.Id,
          count: 100,
        }

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/abdominal", {
            headers,
            params
          }
        );

        const data = response.data

        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let dateC = new Date(e.ConsultationDate).setHours(0,0,0,0)
          if(dateC >= this.range.start.setHours(0,0,0,0) && dateC <= this.range.end.setHours(0,0,0,0)) {
            let date = e.ConsultationDate.split("T")
            let label = date[0].split("-")[2] + '/' + date[0].split("-")[1] + ' - ' + date[1].split(":")[0] + ':' + date[1].split(":")[1]
            this.addChartData(this.chartPeri.chart, label, e.Diagnose)
          }
        }
      },

      async addPeri() {
        if(this.measurePeri.trim() == "" || !this.measurePeri) {
          return M.toast({
            html: this.$t("vitals.modals.validateMsg"),
          });
        }

        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          lang: process.env.VUE_APP_LOCALE,
          country: process.env.VUE_APP_COUNTRY,
        };

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds())

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          DoctorId: store.getters.getUser.Id,
          Measurement: parseInt(this.measurePeri)
        }

        var elem = document.getElementById("addPeri-modal");
        var instance = M.Modal.getInstance(elem);

        try {
          await axios.post(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/vital/abdominal", body,{
              headers,
            }
          ).then(response => {
            if(response.status == 200) {
              M.toast({
                html: this.$t("vitals.modals.generalMsg"),
              });
              instance.close()
              this.getPeri()
            }
          });
        } catch (error) {
          console.log(error)
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },

      convertDate(date) {
        return date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate())
      },
      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },
      isNumber(evt) {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
        const keyPressed = evt.key;
    
        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault();
        }
      },
    },
    mounted() {
      M.AutoInit();

      var collap = document.querySelectorAll(".collapsible");
      M.Collapsible.init(collap);

      var modals = document.querySelectorAll(".modal");
      M.Modal.init(modals);
    },
  }
</script>

<style scoped>
  #vitals {
    position: relative;
    padding: 16px 20px;
    overflow: scroll;
    height: 85%;
  }

  .modal,
  #vitals:deep(.modal-overlay) {
    position: absolute !important;
  }

  #vitals:deep(.modal-overlay) {
    top: 0 !important;
    height: 100%;
  }

  .modal-footer .btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #ffffff !important;
    padding: 10px 24px;
    box-shadow: none !important;
  }

  .modal-content h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }

  .modal-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #909194;
    margin-bottom: 10px !important;
  }

  .modal .modal-footer {
    padding: 15px 24px 0;
    margin-bottom: 17px;
  }

  .modal-footer .modal-close {
    background: #e1e2e6;
    color: #656e73 !important;
    margin-right: 16px !important;
  }

  .collapsible {
    box-shadow: none !important;
    border: none !important;
  }

  .collapsible .collapsible-header {
    border-bottom: none !important;
    padding: 0 !important;
  }

  .btn-outlined {
    line-height: 30px !important;
  }

  .input-field.d-flex {
    align-items: center;
  }

  .input-field.d-flex input {
    width: 100%;
  }

  .wrap {
    flex: 1 1 auto;
  }
  
  .input-field.d-flex p {
    flex: 0 0 auto;
    margin: 5px 5px 0 !important;
  }

  @media (max-width: 882px) {
    .modal-small {
      width: 80% !important;
    }
  }
</style>