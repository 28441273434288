
  import {
    defineComponent
  } from "vue";

  export default defineComponent({
    data() {
      return {
        img: "CallcenterIcon.png",
      }
    },
  });
