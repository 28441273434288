<template>
  <div id="newConsultation">
    <div class="appointment-note">
      <div class="note-header">
        <p>{{ $t("newCon.titles[0]") }}</p>
        <a class="modal-trigger" href="#addNote-modal" v-if="notes.length <= 0" @click="newNote.hastype = false"><i
            class="material-icons">add</i>
          {{ $t("newCon.add") }}
        </a>
      </div>
      <div class="fix-width scroll-inner">
        <table v-if="notes.length > 0">
          <thead>
            <tr>
              <th>{{ $t("newCon.addNote.tableTitles[0]") }}</th>
              <th v-if="notes[0].type === $t('newCon.addNote.choose.items[0]')">
                {{ $t("newCon.addNote.tableTitles[1]") }}
              </th>
              <th>{{ $t("newCon.addNote.tableTitles[2]") }}</th>
              <th>{{ $t("newCon.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(note, index) in notes" :key="note.id">
              <td>{{ note.type }}</td>
              <td v-if="note.type === $t('newCon.addNote.choose.items[0]')">{{ note.reason }}</td>
              <td>{{ note.illness }}</td>
              <td>
                <div class="pos-rel">
                  <a class="waves-effect waves-ligh more" @click="toggle($event, 'menuNote', index)" aria-haspopup="true" aria-controls="overlay_menu1"><i class="material-icons">more_vert</i></a>
                  <Menu id="overlay_menu1" ref="menuNote" :model="itemsNote" :popup="true" >
                    <template #item="{item}">
                      <a class="p-menuitem-link" role="menuitem" tabindex="0" @click="item.command(note)">
                        <span class="p-menuitem-text">{{item.label}}</span>
                      </a>
                    </template>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="appointment-note">
      <div class="note-header">
        <p>{{ $t("newCon.titles[1]") }}</p>
        <a class="modal-trigger" href="#addRevision-modal" v-if="revisions.length <= 0"><i
            class="material-icons">add</i> {{ $t("newCon.add") }}
        </a>
      </div>
      <div class="fix-width scroll-inner">
        <table v-if="revisions.length > 0">
          <thead>
            <tr>
              <th v-if="revisions[0].denies">{{ $t("newCon.addRevision.switchLabel") }}</th>
              <th v-if="!revisions[0].denies && revisions[0].consti !== '' ">{{ $t("newCon.addRevision.fields[0]") }}
              </th>
              <th v-if="!revisions[0].denies && revisions[0].sense !== '' ">{{ $t("newCon.addRevision.fields[1]") }}
              </th>
              <th v-if="!revisions[0].denies && revisions[0].cardio !== '' ">{{ $t("newCon.addRevision.fields[2]") }}
              </th>
              <th v-if="!revisions[0].denies && revisions[0].endocrine !== '' ">{{ $t("newCon.addRevision.fields[3]") }}
              </th>
              <th v-if="!revisions[0].denies && revisions[0].gastro !== '' ">{{ $t("newCon.addRevision.fields[4]") }}
              </th>
              <th v-if="!revisions[0].denies && revisions[0].genito !== '' ">{{ $t("newCon.addRevision.fields[5]") }}
              </th>
              <th v-if="!revisions[0].denies && revisions[0].musculo !== '' ">{{ $t("newCon.addRevision.fields[6]") }}
              </th>
              <th v-if="!revisions[0].denies && revisions[0].neuro !== '' ">{{ $t("newCon.addRevision.fields[7]") }}
              </th>
              <th v-if="!revisions[0].denies && revisions[0].mental !== '' ">{{ $t("newCon.addRevision.fields[8]") }}
              </th>
              <th>{{ $t("newCon.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(revision, index) in revisions" :key="revision.id">
              <td v-if="revision.denies"> Sí </td>
              <td v-if="!revision.denies && revision.consti !== '' "> {{ revision.consti }}</td>
              <td v-if="!revision.denies && revision.sense !== '' "> {{ revision.sense}}</td>
              <td v-if="!revision.denies && revision.cardio !== '' "> {{ revision.cardio}}</td>
              <td v-if="!revision.denies && revision.endocrine !== '' "> {{ revision.endocrine}}</td>
              <td v-if="!revision.denies && revision.gastro !== '' "> {{ revision.gastro}}</td>
              <td v-if="!revision.denies && revision.genito !== '' "> {{ revision.genito}}</td>
              <td v-if="!revision.denies && revision.musculo !== '' "> {{ revision.musculo}}</td>
              <td v-if="!revision.denies && revision.neuro !== '' "> {{ revision.neuro}}</td>
              <td v-if="!revision.denies && revision.mental !== '' "> {{ revision.mental}}</td>
              <td>
                <div class="pos-rel">
                  <a class="waves-effect waves-ligh more" @click="toggle($event, 'menuRev', index)" aria-haspopup="true" aria-controls="overlay_menu2"><i class="material-icons">more_vert</i></a>
                  <Menu id="overlay_menu2" ref="menuRev" :model="itemsRev" :popup="true">
                    <template #item="{item}">
                      <a class="p-menuitem-link" role="menuitem" @click="item.command(revision)">
                        <span class="p-menuitem-text">{{item.label}}</span>
                      </a>
                    </template>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="appointment-note" v-if="consultationType != 2">
      <div class="note-header">
        <p>{{ $t("newCon.titles[2]") }}</p>
        <a class="modal-trigger" href="#addExam-modal" v-if="exams.length <= 0"><i class="material-icons">add</i>
          {{ $t("newCon.add") }}
        </a>
      </div>
      <div class="fix-width scroll-inner">
        <table v-if="exams.length > 0">
          <thead>
            <tr>
              <th v-if="exams[0].normal">{{ $t("newCon.addExam.switchLabel") }}</th>
              <th v-if="!exams[0].normal && exams[0].head !== '' ">{{ $t("newCon.addExam.fields[0]") }}</th>
              <th v-if="!exams[0].normal && exams[0].neck !== '' ">{{ $t("newCon.addExam.fields[1]") }}</th>
              <th v-if="!exams[0].normal && exams[0].eyes !== '' ">{{ $t("newCon.addExam.fields[2]") }}</th>
              <th v-if="!exams[0].normal && exams[0].nose !== '' ">{{ $t("newCon.addExam.fields[3]") }}</th>
              <th v-if="!exams[0].normal && exams[0].mouth !== '' ">{{ $t("newCon.addExam.fields[4]") }}</th>
              <th v-if="!exams[0].normal && exams[0].ears !== '' ">{{ $t("newCon.addExam.fields[5]") }}</th>
              <th v-if="!exams[0].normal && exams[0].cardio !== '' ">{{ $t("newCon.addExam.fields[6]") }}</th>
              <th v-if="!exams[0].normal && exams[0].thorax !== '' ">{{ $t("newCon.addExam.fields[7]") }}</th>
              <th v-if="!exams[0].normal && exams[0].abdomen !== '' ">{{ $t("newCon.addExam.fields[8]") }}</th>
              <th v-if="!exams[0].normal && exams[0].genital !== '' ">{{ $t("newCon.addExam.fields[9]") }}</th>
              <th v-if="!exams[0].normal && exams[0].extremities !== '' ">{{ $t("newCon.addExam.fields[10]") }}</th>
              <th v-if="!exams[0].normal && exams[0].mental !== '' ">{{ $t("newCon.addExam.fields[11]") }}</th>
              <th v-if="!exams[0].normal && exams[0].neuro !== '' ">{{ $t("newCon.addExam.fields[12]") }}</th>
              <th v-if="!exams[0].normal && exams[0].skin !== '' ">{{ $t("newCon.addExam.fields[13]") }}</th>
              <th>{{ $t("newCon.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(exam, index) in exams" :key="exam.id">
              <td v-if="exam.normal"> Sí </td>
              <td v-if="!exam.normal && exam.head !== '' "> {{ exam.head }}</td>
              <td v-if="!exam.normal && exam.neck !== '' "> {{ exam.neck}}</td>
              <td v-if="!exam.normal && exam.eyes !== '' "> {{ exam.eyes}}</td>
              <td v-if="!exam.normal && exam.nose !== '' "> {{ exam.nose}}</td>
              <td v-if="!exam.normal && exam.mouth !== '' "> {{ exam.mouth}}</td>
              <td v-if="!exam.normal && exam.ears !== '' "> {{ exam.ears}}</td>
              <td v-if="!exam.normal && exam.cardio !== '' "> {{ exam.cardio}}</td>
              <td v-if="!exam.normal && exam.thorax !== '' "> {{ exam.thorax}}</td>
              <td v-if="!exam.normal && exam.abdomen !== '' "> {{ exam.abdomen}}</td>
              <td v-if="!exam.normal && exam.genital !== '' "> {{ exam.genital}}</td>
              <td v-if="!exam.normal && exam.extremities !== '' "> {{ exam.extremities}}</td>
              <td v-if="!exam.normal && exam.mental !== '' "> {{ exam.mental}}</td>
              <td v-if="!exam.normal && exam.neuro !== '' "> {{ exam.neuro}}</td>
              <td v-if="!exam.normal && exam.skin !== '' "> {{ exam.skin}}</td>
              <td>
                <div class="pos-rel">
                  <a class="waves-effect waves-ligh more" @click="toggle($event, 'menuExam', index)" aria-haspopup="true" aria-controls="overlay_menu3"><i class="material-icons">more_vert</i></a>
                  <Menu id="overlay_menu3" ref="menuExam" :model="itemsExam" :popup="true">
                    <template #item="{item}">
                      <a class="p-menuitem-link" role="menuitem" @click="item.command(exam)">
                        <span class="p-menuitem-text">{{item.label}}</span>
                      </a>
                    </template>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="appointment-note">
      <div class="note-header">
        <p>{{ $t("newCon.titles[3]") }}</p>
        <a class="modal-trigger" href="#addDiagnosis-modal"><i class="material-icons">add</i> {{ $t("newCon.add") }}
        </a>
      </div>
      <div class="fix-width scroll-inner">
        <table v-if="diagnostics.length > 0">
          <thead>
            <tr>
              <th>{{ $t("newCon.addDiagnosis.tableTitles[0]") }}</th>
              <th>{{ $t("newCon.addDiagnosis.tableTitles[1]") }}</th>
              <th>{{ $t("newCon.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(diagnosis, index) in diagnostics" :key="diagnosis.id">
              <td>{{ diagnosis.principal.Label }}</td>
              <td v-if="diagnosis.secondary">{{ diagnosis.secondary.Label }}</td>
              <td v-else> </td>
              <td>
                <div class="pos-rel">
                  <a class="waves-effect waves-ligh more" @click="toggle($event, 'menuDiag', index)" aria-haspopup="true" :aria-controls="'diag_menu' + diagnosis.id"><i class="material-icons">more_vert</i></a>
                  <Menu :id="'diag_menu' + diagnosis.id" ref="menuDiag" :model="itemsDiag" :popup="true">
                    <template #item="{item}">
                      <a class="p-menuitem-link" role="menuitem" @click="item.command(diagnosis)">
                        <span class="p-menuitem-text">{{item.label}}</span>
                      </a>
                    </template>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="appointment-note">
      <div class="note-header">
        <p>{{ $t("newCon.titles[4]") }}</p>
        <a class="modal-trigger" href="#addPrescription-modal"><i class="material-icons">add</i> {{ $t("newCon.add") }}
        </a>
      </div>
      <div class="fix-width scroll-inner">
        <table v-if="prescriptions.length > 0">
          <thead>
            <tr>
              <th>{{ $t("newCon.addPrescription.tableTitles[0]") }}</th>
              <th>{{ $t("newCon.addPrescription.tableTitles[1]") }}</th>
              <th>{{ $t("newCon.addPrescription.tableTitles[2]") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(prescription, index) in prescriptions" :key="prescription.id">
              <td>{{ prescription.id }}</td>
              <td>{{ prescription.destiny }}</td>
              <td v-if="prescription.medicines.length <= 0"> </td>
              <td v-else>
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t("newCon.addPrescription.subTableTitles[0]") }}</th>
                      <th>{{ $t("newCon.addPrescription.subTableTitles[1]") }}</th>
                      <th>{{ $t("newCon.addPrescription.subTableTitles[2]") }}</th>
                      <th>{{ $t("newCon.addPrescription.subTableTitles[3]") }}</th>
                      <th>{{ $t("newCon.addPrescription.subTableTitles[4]") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="medicine in prescription.medicines" :key="medicine">
                      <td> {{ medicine.ActiveSubstance }} </td>
                      <td> {{ medicine.Frequency.Label }}</td>
                      <td> {{ medicine.Duration.Quantity }} {{ medicine.Duration.Type }} </td>
                      <td> {{ medicine.Unit }}</td>
                      <td> {{ medicine.Doze }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <div class="pos-rel">
                  <a class="waves-effect waves-ligh more" @click="toggle($event, 'menuPresc', index)" aria-haspopup="true" :aria-controls="'presc_menu' + prescription.id"><i class="material-icons">more_vert</i></a>
                  <Menu :id="'presc_menu' + prescription.id" ref="menuPresc" :model="itemsPresc" :popup="true">
                    <template #item="{item}">
                      <a class="p-menuitem-link" role="menuitem" @click="item.command(prescription)">
                        <span class="p-menuitem-icon material-icons" v-if="item.icon">add</span>
                        <span class="p-menuitem-text">{{item.label}}</span>
                      </a>
                    </template>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="appointment-note">
      <div class="note-header">
        <p>{{ $t("newCon.titles[5]") }}</p>
        <a class="modal-trigger" href="#addOrder-modal"><i class="material-icons">add</i> {{ $t("newCon.add") }}
        </a>
      </div>
      <div class="fix-width scroll-inner">
        <table v-if="orders.length > 0">
          <thead>
            <tr>
              <th>{{ $t("newCon.addOrder.tableTitles[0]") }}</th>
              <th>{{ $t("newCon.addOrder.tableTitles[1]") }}</th>
              <th>{{ $t("newCon.addOrder.tableTitles[2]") }}</th>
              <th>{{ $t("newCon.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in orders" :key="order.id">
              <td>{{ order.id }}</td>
              <td>{{ order.destiny }}</td>
              <td v-if="order.laboratories.length > 0">
                <table>
                  <thead>
                    <th>{{ $t("newCon.addOrder.subTableTitles[0]") }}</th>
                    <th>{{ $t("newCon.addOrder.subTableTitles[1]") }}</th>
                  </thead>
                  <tbody>
                    <tr v-for="lab in order.laboratories" :key="lab.Code">
                      <td>{{ lab.Diagnose.Label }} </td>
                      <td>{{ lab.Proc.Label }} </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td v-else> </td>
              <td>
                <div class="pos-rel">
                  <a class="waves-effect waves-ligh more" @click="toggle($event, 'menuOrder', index)" aria-haspopup="true" :aria-controls="'order_menu' + order.id"><i class="material-icons">more_vert</i></a>
                  <Menu :id="'order_menu' + order.id" ref="menuOrder" :model="itemsOrder" :popup="true">
                    <template #item="{item}">
                      <a class="p-menuitem-link" role="menuitem" @click="item.command(order)">
                        <span class="p-menuitem-icon material-icons" v-if="item.icon">add</span>
                        <span class="p-menuitem-text">{{item.label}}</span>
                      </a>
                    </template>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="appointment-note">
      <div class="note-header">
        <p>{{ $t("newCon.titles[6]") }}</p>
        <a class="modal-trigger" href="#addDocument-modal" v-if="!medicalDisability || !medicalCertificate"
          @click="newDocument.hastype = false"><i class="material-icons">add</i> {{ $t("newCon.add") }}
        </a>
      </div>
      <div class="fix-width scroll-inner">
        <table v-if="medicalDisability">
          <thead>
            <tr>
              <th>{{ $t("newCon.addDocument.disability.tableTitles[0]") }}</th>
              <th>{{ $t("newCon.addDocument.disability.tableTitles[1]") }}</th>
              <th>{{ $t("newCon.addDocument.disability.tableTitles[2]") }}</th>
              <th>{{ $t("newCon.addDocument.disability.tableTitles[3]") }}</th>
              <th>{{ $t("newCon.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ medicalDisability.type }}</td>
              <td>{{ medicalDisability.DisabilityDays }}</td>
              <td>{{ medicalDisability.StartDate }}</td>
              <td>{{ medicalDisability.EndDate }}</td>
              <td>
                <div class="pos-rel">
                  <a class="waves-effect waves-ligh more" @click="toggleObj($event, 'menuDisab')" aria-haspopup="true" aria-controls="disability_menu"><i class="material-icons">more_vert</i></a>
                  <Menu id="disability_menu" ref="menuDisab" :model="itemsDisa" :popup="true">
                    <template #item="{item}">
                      <a class="p-menuitem-link" role="menuitem" @click="item.command()">
                        <span class="p-menuitem-text">{{item.label}}</span>
                      </a>
                    </template>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="fix-width scroll-inner">
        <table v-if="medicalCertificate">
          <thead>
            <tr>
              <th>{{ $t("newCon.addDocument.certificate.tableTitles[0]") }}</th>
              <th>{{ $t("newCon.addDocument.certificate.tableTitles[1]") }}</th>
              <th>{{ $t("newCon.addDocument.certificate.tableTitles[2]") }}</th>
              <th>{{ $t("newCon.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ medicalCertificate.type }}</td>
              <td>{{ medicalCertificate.Diagnose.Code }} - {{ medicalCertificate.Diagnose.Diagnose }}</td>
              <td>{{ medicalCertificate.Description }}</td>
              <td>
                <div class="pos-rel">
                  <a class="waves-effect waves-ligh more"><i class="material-icons"
                      @click="toggleObj($event, 'menuCert')" aria-haspopup="true" aria-controls="certificate_menu">more_vert</i></a>
                  <Menu id="certificate_menu" ref="menuCert" :model="itemsCert" :popup="true">
                    <template #item="{item}">
                      <a class="p-menuitem-link" role="menuitem" @click="item.command()">
                        <span class="p-menuitem-text">{{item.label}}</span>
                      </a>
                    </template>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="appointment-note">
      <div class="note-header">
        <p>{{ $t("newCon.titles[7]") }}</p>
        <a class="modal-trigger" href="#addAnalysis-modal" v-if="!analysis"><i class="material-icons">add</i>
          {{ $t("newCon.add") }}
        </a>
      </div>
      <div class="fix-width scroll-inner">
        <table v-if="analysis">
          <thead>
            <tr>
              <th>{{ $t("newCon.addAnalysis.tableTitle") }}</th>
              <th>{{ $t("newCon.action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ analysis.description }}</td>
              <td>
                <div class="pos-rel">
                  <a class="waves-effect waves-ligh more"><i class="material-icons"
                      @click="toggleObj($event, 'menuAnalysis')" aria-haspopup="true" aria-controls="analysis_menu">more_vert</i></a>
                  <Menu id="analysis_menu" ref="menuAnalysis" :model="itemsAnalysis" :popup="true">
                    <template #item="{item}">
                      <a class="p-menuitem-link" role="menuitem" @click="item.command()">
                        <span class="p-menuitem-text">{{item.label}}</span>
                      </a>
                    </template>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="d-flex" v-if="available">
      <button class="waves-effect waves-light btn btn-primary btn-rounded" id="save-button" @click="createConsultation">
        {{ $t("newCon.button") }}
      </button>
    </div>

    <!-- Add-note modal -->
    <div id="addNote-modal" class="modal" :class="{ 'modal-small': !newNote.hastype }">
      <div class="modal-content">
        <div class="choose-type" v-if="!newNote.hastype">
          <h4 class="left-align">{{ $t("newCon.addNote.choose.title") }}</h4>
          <div class="type">
            <h5>{{ $t("newCon.addNote.choose.items[0]") }} </h5>
            <label>
              <input class="with-gap" id="type-consultation" type="radio" :value="$t('newCon.addNote.choose.items[0]')"
                v-model="newNote.type" />
              <span> </span>
            </label>
          </div>
          <div class="type">
            <h5>{{ $t("newCon.addNote.choose.items[1]") }} </h5>
            <label>
              <input class="with-gap" id="type-evolution" type="radio" :value="$t('newCon.addNote.choose.items[1]')"
                v-model="newNote.type" />
              <span> </span>
            </label>
          </div>
        </div>
        <div class="reason" v-if="newNote.hastype && newNote.type == $t('newCon.addNote.choose.items[0]')">
          <h4 class="left-align">{{ $t("newCon.addNote.reason.title") }}</h4>
          <div class="col s12 input-field">
            <input id="reason" name="reason" type="text" v-model="newNote.reason" autocomplete="off" />
            <label for="reason">{{ $t("newCon.addNote.reason.inputLabel") }}</label>
          </div>
          <p class="col s12 left-align">
            {{ $t("newCon.addNote.reason.textareaLabel") }}
          </p>
          <textarea name="add-note" id="add-note" rows="30" v-model="newNote.illness"></textarea>
        </div>
        <div class="evolution" v-if="newNote.hastype && newNote.type == $t('newCon.addNote.choose.items[1]')">
          <h4 class="left-align">{{ $t("newCon.addNote.evolutionNote.title") }}</h4>
          <p class="col s12 left-align">
            {{ $t("newCon.addNote.evolutionNote.label") }}
          </p>
          <textarea name="add-note" id="add-note" rows="30" v-model="newNote.illness"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{
          $t("newCon.btnCancel")
        }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" v-if="!newNote.hastype"
          @click="newNote.hastype = true">{{ $t("newCon.btnNext") }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" v-else
          @click="addNote">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- End add-note modal -->

    <!-- Physical exam modal -->
    <div id="addExam-modal" class="modal modal-fixed-footer">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("newCon.addExam.title") }}</h4>
        <div class="switch-cont d-flex">
          <span> {{ $t('newCon.addExam.switchLabel') }} </span>
          <InputSwitch v-model="switch1" @change="getState"/>
        </div>
        <div class="row">
          <form class="col s12 modal-form">
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="head">{{ $t("newCon.addExam.fields[0]") }}</label>
                <input class="rounded-input" id="head" name="head" type="text" v-model="newExam.head">
              </div>
              <div class="col s12 m12 l6">
                <label for="neck">{{ $t("newCon.addExam.fields[1]") }}</label>
                <input class="rounded-input" id="neck" name="neck" type="text" v-model="newExam.neck">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="eyes">{{ $t("newCon.addExam.fields[2]") }}</label>
                <input class="rounded-input" id="eyes" name="eyes" type="text" v-model="newExam.eyes">
              </div>
              <div class="col s12 m12 l6">
                <label for="nose">{{ $t("newCon.addExam.fields[3]") }}</label>
                <input class="rounded-input" id="nose" name="nose" type="text" v-model="newExam.nose">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="mouth">{{ $t("newCon.addExam.fields[4]") }}</label>
                <input class="rounded-input" id="mouth" name="mouth" type="text" v-model="newExam.mouth">
              </div>
              <div class="col s12 m12 l6">
                <label for="ears">{{ $t("newCon.addExam.fields[5]") }}</label>
                <input class="rounded-input" id="ears" name="ears" type="text" v-model="newExam.ears">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="cardio">{{ $t("newCon.addExam.fields[6]") }}</label>
                <input class="rounded-input" id="cardio" name="cardio" type="text" v-model="newExam.cardio">
              </div>
              <div class="col s12 m12 l6">
                <label for="thorax">{{ $t("newCon.addExam.fields[7]") }}</label>
                <input class="rounded-input" id="thorax" name="thorax" type="text" v-model="newExam.thorax">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="abdomen">{{ $t("newCon.addExam.fields[8]") }}</label>
                <input class="rounded-input" id="abdomen" name="abdomen" type="text" v-model="newExam.abdomen">
              </div>
              <div class="col s12 m12 l6">
                <label for="genital">{{ $t("newCon.addExam.fields[9]") }}</label>
                <input class="rounded-input" id="genital" name="genital" type="text" v-model="newExam.genital">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="extremities">{{ $t("newCon.addExam.fields[10]") }}</label>
                <input class="rounded-input" id="extremities" name="extremities" type="text"
                  v-model="newExam.extremities">
              </div>
              <div class="col s12 m12 l6">
                <label for="mental">{{ $t("newCon.addExam.fields[11]") }}</label>
                <input class="rounded-input" id="mental" name="mental" type="text" v-model="newExam.mental">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="neuro">{{ $t("newCon.addExam.fields[12]") }}</label>
                <input class="rounded-input" id="neuro" name="neuro" type="text" v-model="newExam.neuro">
              </div>
              <div class="col s12 m12 l6">
                <label for="skin">{{ $t("newCon.addExam.fields[13]") }}</label>
                <input class="rounded-input" id="skin" name="skin" type="text" v-model="newExam.skin">
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{
          $t("newCon.btnCancel")
        }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addExam">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- End Physical exam modal -->

    <!-- review exam modal -->
    <div id="addRevision-modal" class="modal modal-fixed-footer">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("newCon.addRevision.title") }}</h4>
        <div class="switch-cont d-flex">
          <span> {{ $t('newCon.addRevision.switchLabel') }} </span>
          <InputSwitch v-model="switch2" @change="getRevisionState"/>
        </div>
        <div class="row">
          <form class="col s12 modal-form">
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="consti">{{ $t("newCon.addRevision.fields[0]") }}</label>
                <input class="rounded-input" id="consti" name="consti" type="text" v-model="newRevision.consti">
              </div>
              <div class="col s12 m12 l6">
                <label for="sense">{{ $t("newCon.addRevision.fields[1]") }}</label>
                <input class="rounded-input" id="sense" name="sense" type="text" v-model="newRevision.sense">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="cardio">{{ $t("newCon.addRevision.fields[2]") }}</label>
                <input class="rounded-input" id="cardio" name="cardio" type="text" v-model="newRevision.cardio">
              </div>
              <div class="col s12 m12 l6">
                <label for="endocrine">{{ $t("newCon.addRevision.fields[3]") }}</label>
                <input class="rounded-input" id="endocrine" name="endocrine" type="text"
                  v-model="newRevision.endocrine">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="gastro">{{ $t("newCon.addRevision.fields[4]") }}</label>
                <input class="rounded-input" id="gastro" name="gastro" type="text" v-model="newRevision.gastro">
              </div>
              <div class="col s12 m12 l6">
                <label for="genito">{{ $t("newCon.addRevision.fields[5]") }}</label>
                <input class="rounded-input" id="genito" name="genito" type="text" v-model="newRevision.genito">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="musculo">{{ $t("newCon.addRevision.fields[6]") }}</label>
                <input class="rounded-input" id="musculo" name="musculo" type="text" v-model="newRevision.musculo">
              </div>
              <div class="col s12 m12 l6">
                <label for="neuro">{{ $t("newCon.addRevision.fields[7]") }}</label>
                <input class="rounded-input" id="neuro" name="neuro" type="text" v-model="newRevision.neuro">
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l6">
                <label for="mental">{{ $t("newCon.addRevision.fields[8]") }}</label>
                <input class="rounded-input" id="mental" name="mental" type="text" v-model="newRevision.mental">
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{
          $t("newCon.btnCancel")
        }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded"
          @click="addRevision">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- End review exam modal -->

    <!-- add Diagnosis modal -->
    <div id="addDiagnosis-modal" class="modal">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("newCon.addDiagnosis.title") }}</h4>
        <div class="row">
          <div class="input-field col s12 m12 l6">
            <span class="p-float-label">
              <AutoComplete id="principal" v-model="newDiagnosis.principal" :suggestions="cie10info"
                @complete="getCie10($event)" field="Label" :minLength="3" />
              <label for="principal">{{ $t('newCon.addDiagnosis.selects[0]') }}</label>
            </span>
          </div>
          <div class="input-field col s12 m12 l6">
            <span class="p-float-label">
              <AutoComplete id="secondary" v-model="newDiagnosis.secondary" :suggestions="cie10info"
                @complete="getCie10($event)" field="Label" :minLength="3" />
              <label for="secondary">{{ $t('newCon.addDiagnosis.selects[1]') }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{
          $t("newCon.btnCancel")
        }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded"
          @click="addDiagnosis">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- End add-Diagnosis modal -->

    <!-- add new prescriptions modal -->
    <div id="addPrescription-modal" class="modal">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("newCon.addPrescription.title") }} {{ prescriptions.length + 1 }} </h4>
        <div class="row">
          <div class="input-field col s12 m12 l6">
            <input id="destiny" type="text" v-model="prescriptionDestiny">
            <label for="destiny">{{ $t("newCon.addPrescription.inputLabel") }}</label>
          </div>
          <div class="col s12 m12 l6 drop-col">
            <span class="p-float-label">
              <Dropdown id="dropdown1" v-model="prescriptionDiagnose" :options="allDiagnose" optionLabel="Label"
                :emptyMessage="$t('newCon.addDocument.emptySelect')" />
              <label for="dropdown1">{{ $t('newCon.addDocument.disability.labels[0]') }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{
          $t("newCon.btnCancel")
        }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded"
          @click="addPrescription">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- End add-prescriptions modal -->

    <!-- Add-Medicine modal -->
    <div id="addMedicine-modal" class="modal modal-fixed-footer">
      <div class="modal-content">
        <h4 class="left-align" v-if="newMedicine.step == 1">{{ $t("newCon.addMedicine.title1") }}</h4>
        <h4 class="left-align" v-else>{{ $t("newCon.addMedicine.title2") }}</h4>
        <div class="table-medicine" v-if="newMedicine.step == 1">
          <div class="col s12 m12 l8">
            <div class="search-input">
              <i class="material-icons">search</i>
              <input type="text" :placeholder="$t('userList.placeholder')" :value="search"
                @input="event => search = event.target.value" autocomplete="off" />
            </div>
          </div>
          <div class="col s12">
            <div class="fix-width scroll-inner">
              <table>
                <thead>
                  <tr>
                    <th> {{ $t('newCon.addMedicine.tableTitles[0]') }} </th>
                    <th> {{ $t('newCon.addMedicine.tableTitles[1]') }} </th>
                    <th> {{ $t('newCon.addMedicine.tableTitles[2]') }} </th>
                    <th> {{ $t('newCon.addMedicine.tableTitles[3]') }} </th>
                    <th> {{ $t('newCon.addMedicine.tableTitles[4]') }} </th>
                    <th> {{ $t('newCon.addMedicine.tableTitles[5]') }} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="medicine in medicines" :key="medicine" :class="{ active: medicine === newMedicine.medicine }"
                    @click="selectMedicine(medicine)">
                    <td> {{ medicine.ActiveSubstance }} </td>
                    <td> {{ medicine.Product }} </td>
                    <td> {{ medicine.CommercialDescription }} </td>
                    <td> {{ medicine.Presentation }} </td>
                    <td> {{ medicine.Unit }} </td>
                    <td> {{ medicine.Doze }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="formula" v-if="newMedicine.step == 2">
          <div class="row">
            <div class="input-field col s12 m12 l4">
              <input id="quantity" type="number" @keypress="isNumber($event)" v-model="newMedicine.quantity"
                autocomplete="off">
              <label for="quantity">{{ $t('newCon.addMedicine.formulaLabels[0]') }}</label>
            </div>
            <div class="col s12 m12 l4 drop-col">
              <span class="p-float-label">
                <Dropdown id="frequency" v-model="newMedicine.frequency" :options="frequencies" optionLabel="Label"
                  :emptyMessage="$t('newCon.addDocument.emptySelect')" />
                <label for="frequency">{{ $t('newCon.addMedicine.formulaLabels[1]') }}</label>
              </span>
            </div>
            <div class="col s12 m12 l4 drop-col">
              <span class="p-float-label">
                <Dropdown id="when" v-model="newMedicine.when" :options="whenList" optionLabel="description"
                  :emptyMessage="$t('newCon.addDocument.emptySelect')" />
                <label for="when">{{ $t('newCon.addMedicine.formulaLabels[2]') }}</label>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12 l6 d-flex days-col">
              <div class="input-field">
                <input id="duration" type="number" @keypress="isNumber($event)" v-model="newMedicine.duration"
                  autocomplete="off">
                <label for="duration">{{ $t('newCon.addMedicine.formulaLabels[3]') }}</label>
              </div>
              <p>{{ $t('newCon.addMedicine.days') }}</p>
            </div>
            <div class="col s2"></div>
            <div class="input-field col s12">
              <textarea name="add-descMed" id="add-descDiag" rows="30" v-model="newMedicine.description"
                :placeholder="$t('newCon.addDocument.textAreaPlaceholder')"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex">
          <div>
            {{ $t('newCon.addMedicine.step') }} {{ newMedicine.step }}/2
          </div>
          <div>
            <a class="modal-close waves-effect waves-light btn btn-rounded">{{ $t("newCon.btnCancel") }}</a>
            <button class="waves-effect waves-light btn btn-primary btn-rounded" v-if="newMedicine.step == 1"
              @click="newMedicine.step++" :disabled="newMedicine.medicine == null">{{ $t("newCon.btnNext") }}</button>
            <a class="waves-effect waves-light btn btn-primary btn-rounded" v-else
              @click="addMedicine">{{ $t("newCon.btnSave") }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- End add-Medicine modal -->

    <!-- add Order modal -->
    <div id="addOrder-modal" class="modal">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("newCon.addOrder.title") }} {{ orderId }} </h4>
        <div class="row">
          <div class="input-field col s12 m12 l6">
            <input id="destiny" type="text" v-model="orderDestiny">
            <label for="destiny">{{ $t("newCon.addOrder.inputLabel") }}</label>
          </div>
          <div class="input-field col s12 m12 l6">
            <select v-model="newLaboratory.type">
              <option value="1"> {{ $t('newCon.addLab.selectItems[0]') }} </option>
              <option value="2"> {{ $t('newCon.addLab.selectItems[1]') }} </option>
              <option value="3"> {{ $t('newCon.addLab.selectItems[2]') }} </option>
              <option value="4"> {{ $t('newCon.addLab.selectItems[3]') }} </option>
            </select>
            <label> {{ $t('newCon.addLab.labels[0]') }} </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{
          $t("newCon.btnCancel")
        }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addOrder">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- End add-Order modal -->

    <!-- add lab-modal -->
    <div id="addLab-modal" class="modal">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("newCon.addLab.title") }}</h4>
        <div class="row">
          <div class="col s12 m12 l6 drop-col">
            <span class="p-float-label">
              <Dropdown id="dropdownlab" v-model="newLaboratory.diagnosis" :options="allDiagnose" optionLabel="Label"
                :emptyMessage="$t('newCon.addDocument.emptySelect')" />
              <label for="dropdownlab">{{ $t('newCon.addDocument.disability.labels[0]') }}</label>
            </span>
          </div>
          <div class="input-field col s12 m12 l6">
            <span class="p-float-label">
              <AutoComplete v-model="newLaboratory.selectedProc" :suggestions="procedures"
                @complete="getProcedures($event)" field="Label" :minLength="3" :disabled="!newLaboratory.diagnosis" />
              <label>{{ $t('newCon.addLab.labels[1]') }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{
          $t("newCon.btnCancel")
        }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" @click="addLab">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- end addLab modal -->

    <!-- Add-Document modal -->
    <div id="addDocument-modal" class="modal" :class="{ 'modal-small': !newDocument.hastype }">
      <div class="modal-content">
        <div class="choose-type" v-if="!newDocument.hastype">
          <h4 class="left-align">{{ $t("newCon.addDocument.title") }}</h4>
          <div class="type" v-if="!medicalDisability">
            <h5>{{ $t("newCon.addDocument.options[0]") }} </h5>
            <label>
              <input class="with-gap" id="type-consultation" type="radio" value="disability"
                v-model="newDocument.type" />
              <span> </span>
            </label>
          </div>
          <div class="type" v-if="!medicalCertificate">
            <h5>{{ $t("newCon.addDocument.options[1]") }} </h5>
            <label>
              <input class="with-gap" id="type-evolution" type="radio" value="certificate" v-model="newDocument.type" />
              <span> </span>
            </label>
          </div>
        </div>
        <div class="disability" v-if="newDocument.hastype && newDocument.type == 'disability'">
          <h4 class="left-align">{{ $t("newCon.addDocument.disability.title") }}</h4>

          <div class="col s12 m10 l6 drop-col">
            <span class="p-float-label">
              <Dropdown id="dropdown1" v-model="newDocument.disability.diagnosis" :options="allDiagnose"
                optionLabel="Label" :emptyMessage="$t('newCon.addDocument.emptySelect')" />
              <label for="dropdown1">{{ $t('newCon.addDocument.disability.labels[0]') }}</label>
            </span>
          </div>

          <p class="col s12 left-align">
            {{ $t("newCon.addDocument.disability.labels[1]") }}
          </p>
          <textarea name="add-descDiag" id="add-descDiag" rows="30" v-model="newDocument.disability.description"
            :placeholder="$t('newCon.addDocument.textAreaPlaceholder')"></textarea>
          <div class="row">
            <div class="col s12 m10 l6 input-field">
              <input id="days" name="days" type="number" @keypress="isNumber($event)"
                v-model="newDocument.disability.days" autocomplete="off" />
              <label for="days">{{ $t("newCon.addDocument.disability.labels[2]") }}</label>
            </div>
          </div>

          <div class="row">
            <div class="col s6">
              <p class="left-align">{{ $t('newCon.addDocument.disability.labels[3]') }}</p>
              <input id="start-date" :value="startDate" disabled />
            </div>

            <div class="col s6">
              <p class="left-align">{{ $t('newCon.addDocument.disability.labels[4]') }}</p>
              <input id="end-date" :value="endDate" disabled />
            </div>
          </div>


        </div>

        <div class="certificate" v-if="newDocument.hastype && newDocument.type == 'certificate'">
          <h4 class="left-align">{{ $t("newCon.addDocument.certificate.title") }}</h4>

          <div class="col s12 m10 l6 drop-col">
            <span class="p-float-label">
              <Dropdown id="dropdown2" v-model="newDocument.certificate.diagnosis" :options="allDiagnose"
                optionLabel="Label" :emptyMessage="$t('newCon.addDocument.emptySelect')" />
              <label for="dropdown2">{{ $t('newCon.addDocument.certificate.labels[0]') }}</label>
            </span>
          </div>

          <p class="col s12 left-align">
            {{ $t("newCon.addDocument.certificate.labels[1]") }}
          </p>
          <textarea name="add-desc" id="add-desc" rows="30" v-model="newDocument.certificate.description"
            :placeholder="$t('newCon.addDocument.textAreaPlaceholder')"></textarea>
        </div>

      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{
          $t("newCon.btnCancel")
        }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" v-if="!newDocument.hastype"
          @click="newDocument.hastype = true">{{ $t("newCon.btnNext") }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded" v-else
          @click="addDocument">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- End add-Document modal -->

    <!-- Add-analysis modal -->
    <div id="addAnalysis-modal" class="modal">
      <div class="modal-content">
        <h4 class="left-align">{{ $t("newCon.addAnalysis.title") }}</h4>
        <textarea name="add-note" id="add-note" rows="30" v-model="analysisDesc"></textarea>
      </div>
      <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn btn-rounded">{{
          $t("newCon.btnCancel")
        }}</a>
        <a class="waves-effect waves-light btn btn-primary btn-rounded"
          @click="addAnalysis">{{ $t("newCon.btnSave") }}</a>
      </div>
    </div>
    <!-- End add-analysis modal -->

    <div id="loading-modal" v-if="loading">
      <div class="modal-content">
        <ProgressSpinner />
        <h4>{{ $t("newCon.loading") }}...</h4>
      </div>
    </div>

    <BlockUI :blocked="loading" :fullScreen="true"></BlockUI>
  </div>

</template>

<script>
  import axios from "axios";
  import store from "@/store";
  import BlockUI from 'primevue/blockui';
  import ProgressSpinner from 'primevue/progressspinner';
  import generalMixin from '@/mixins/generalMixin'

  export default ({
    name: "NewConsultation",
    mixins: [generalMixin],
    components: {
      BlockUI,
      ProgressSpinner,
    },
    props: {
      patient: Object,
      consultationId: String,
      consultationType: Number,
    },
    data() {
      return {
        available: true,
        itemsNote: [
          {
            label: this.$t("newCon.options.edit"),
            command: note => this.editNote(note),
          },
          {
            label: this.$t("newCon.options.delete"),
            command: () => this.deleteNote(),
          }
        ],
        itemsRev: [
          {
            label: this.$t("newCon.options.edit"),
            command: revision => this.editRevision(revision),
          },
          {
            label: this.$t("newCon.options.delete"),
            command: () => this.deleteRevision(),
          },
        ],
        itemsExam: [
          {
            label: this.$t("newCon.options.edit"),
            command: exam => this.editExam(exam),
          },
          {
            label: this.$t("newCon.options.delete"),
            command: () => this.deleteExam(),
          },
        ],
        itemsDiag: [
          {
            label: this.$t("newCon.options.edit"),
            command: diagnosis => this.editDiagnosis(diagnosis),
          },
          {
            label: this.$t("newCon.options.delete"),
            command: diagnosis => this.deleteDiagnosis(diagnosis),
          },
        ],
        itemsPresc: [
          {
            label: this.$t("newCon.options.addMedicine"),
            icon: 'add',
            command: prescription => this.openMedicineModal(prescription),
          },
          {
            label: this.$t("newCon.options.delete"),
            command: prescription => this.deletePrescription(prescription),
          },
        ],
        itemsOrder: [
          {
            label: this.$t("newCon.options.addExam"),
            icon: 'add',
            command: order => this.openLabModal(order),
          },
          {
            label: this.$t("newCon.options.delete"),
            command: order => this.deleteOrder(order),
          },
        ],
        itemsDisa: [
          {
            label: this.$t("newCon.options.edit"),
            command: () => this.editDisability(),
          },
          {
            label: this.$t("newCon.options.delete"),
            command: () => this.deleteDisability(),
          },
        ],
        itemsCert: [
          {
            label: this.$t("newCon.options.edit"),
            command: () => this.editCertificate(),
          },
          {
            label: this.$t("newCon.options.delete"),
            command: () => this.deleteCertificate(),
          },
        ],
        itemsAnalysis: [
          {
            label: this.$t("newCon.options.edit"),
            command: () => this.editAnalysis(),
          },
        ],
        newNote: {
          reason: "",
          illness: "",
          type: "",
          hastype: false,
        },
        newDiagnosis: {
          principal: null,
          secondary: null,
        },
        newMedicine: {
          step: 1,
          prescription: null,
          medicine: null,
          quantity: null,
          frequency: null,
          duration: null,
          description: '',
          when: null,
        },
        newLaboratory: {
          type: null,
          selectedProc: null,
          diagnosis: null,
          name: '',
          order: {},
        },
        newExam: {
          head: '',
          neck: '',
          eyes: '',
          nose: '',
          mouth: '',
          ears: '',
          cardio: '',
          thorax: '',
          abdomen: '',
          genital: '',
          extremities: '',
          mental: '',
          neuro: '',
          skin: '',
        },
        newRevision: {
          consti: '',
          sense: '',
          cardio: '',
          endocrine: '',
          gastro: '',
          genito: '',
          musculo: '',
          mental: '',
          neuro: '',
        },
        newDocument: {
          type: "",
          hastype: false,
          disability: {
            diagnosis: null,
            description: '',
            days: null,
          },
          certificate: {
            diagnosis: null,
            description: '',
          },
        },
        prescriptionDestiny: '',
        prescriptionDiagnose: null,
        orderId: 1,
        orderDestiny: '',
        diagnosisId: 1,
        prescriptionId: 1,
        analysisDesc: '',
        notes: [],
        exams: [],
        revisions: [],
        diagnostics: [],
        prescriptions: [],
        orders: [],
        analysis: null,
        procedures: null,
        cie10info: null,
        medicines: null,
        medicalDisability: null,
        medicalCertificate: null,
        search: '',
        editingDiagnosis: null,
        loading: false,
        switch1: false,
        switch2: false,
      };
    },
    computed: {
      userToken() {
        return store.getters.getToken;
      },
      startDate() {
        let date = new Date()
        return this.convertDate(date)
      },
      endDate() {
        let date = new Date()
        date.setDate(date.getDate() + this.newDocument.disability.days)
        return this.convertDate(date)
      },
      allDiagnose() {
        let d = []
        for (let i = 0; i < this.diagnostics.length; i++) {
          let e = this.diagnostics[i];
          d.push(this.diagnostics[i].principal)
          if (this.diagnostics[i].secondary) {
            d.push(this.diagnostics[i].secondary)
          }
        }

        return d
      },
    },
    watch: {
      search(value) {
        if (value != "") {
          this.getMedicines(value)
        } else {
          this.medicines = null
        }
      }
    },
    methods: {
      clearNoteFields() {
        this.newNote = {
          reason: "",
          illness: "",
          type: "",
          hastype: false,
        }
      },

      toggleObj(event, ref) {
        this.$refs[ref].toggle(event);
      },

      async getConsultation() {
        const headers = {
          Authorization: `Bearer ${this.userToken}`,
          appid: process.env.VUE_APP_APP_ID,
          companyid: process.env.VUE_APP_COMPANY_ID,
        };

        const response = await axios.get(
          process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/consultation/" + this.consultationId, {
            headers
          }
        );

        let data = response.data
        if(data != "") {
          this.available = false
        }
      },

      addNote() {
        const {
          illness,
          reason,
          type
        } = this.newNote;

        const note = {
          id: this.notes.length,
          reason,
          illness,
          type,
        };

        if (this.notes.length > 0) {
          this.notes[0] = note
        } else {
          this.notes.push(note);
        }
        var elem = document.getElementById("addNote-modal");
        var instance = M.Modal.getInstance(elem);

        instance.close();

        this.newNote.illness = "";
        this.newNote.reason = "";
        this.newNote.type = ""
      },

      editNote(note) {
        var elem = document.getElementById("addNote-modal");
        var instance = M.Modal.getInstance(elem);

        instance.open();

        this.newNote = {
          reason: note.reason,
          illness: note.illness,
          type: note.type,
          hastype: true,
        }

        setTimeout(() => {
          let input = document.getElementById("reason");
          input.labels[0].classList.add("active");
        }, 100);
      },

      deleteNote() {
        this.newNote.hastype = false
        this.notes.pop()
      },

      deleteExam() {
        this.exams.pop()
      },

      deleteRevision() {
        this.revisions.pop()
      },

      selectMedicine(med) {
        if (med !== this.newMedicine.medicine) {
          this.newMedicine.medicine = med
        } else {
          this.newMedicine.medicine = null
        }
      },

      addRevision() {
        const {
          consti,
          sense,
          cardio,
          endocrine,
          gastro,
          genito,
          musculo,
          mental,
          neuro,
        } = this.newRevision

        let denies = false

        if (consti === this.$t('newCon.addRevision.switchLabel') &&
          sense === this.$t('newCon.addRevision.switchLabel') &&
          cardio === this.$t('newCon.addRevision.switchLabel') &&
          endocrine === this.$t('newCon.addRevision.switchLabel') &&
          gastro === this.$t('newCon.addRevision.switchLabel') &&
          genito === this.$t('newCon.addRevision.switchLabel') &&
          musculo === this.$t('newCon.addRevision.switchLabel') &&
          mental === this.$t('newCon.addRevision.switchLabel') &&
          neuro === this.$t('newCon.addRevision.switchLabel')) {
          denies = true
        }

        let rev = {
          consti,
          sense,
          cardio,
          endocrine,
          gastro,
          genito,
          musculo,
          mental,
          neuro,
          denies,
        }

        if (this.revisions.length > 0) {
          this.revisions[0] = rev
        } else {
          this.revisions.push(rev);
        }
        var elem = document.getElementById("addRevision-modal");
        var instance = M.Modal.getInstance(elem);

        instance.close();

        this.newRevision = {
          consti: "",
          sense: "",
          cardio: "",
          endocrine: "",
          gastro: "",
          genito: "",
          musculo: "",
          mental: "",
          neuro: "",
        }
      },

      editRevision(revision) {
        const {
          consti,
          sense,
          cardio,
          endocrine,
          gastro,
          genito,
          musculo,
          mental,
          neuro,
        } = revision
        var elem = document.getElementById("addRevision-modal");
        var instance = M.Modal.getInstance(elem);

        instance.open();

        this.newRevision = {
          consti,
          sense,
          cardio,
          endocrine,
          gastro,
          genito,
          musculo,
          mental,
          neuro,
        }
      },

      addExam() {
        const {
          head,
          neck,
          eyes,
          nose,
          mouth,
          ears,
          cardio,
          thorax,
          abdomen,
          genital,
          extremities,
          mental,
          neuro,
          skin,
        } = this.newExam

        let normal = false

        if (head === "Normal" &&
          neck === "Normal" &&
          eyes === "Normal" &&
          nose === "Normal" &&
          mouth === "Normal" &&
          ears === "Normal" &&
          cardio === "Normal" &&
          thorax === "Normal" &&
          abdomen === "Normal" &&
          genital === "Normal" &&
          extremities === "Normal" &&
          mental === "Normal" &&
          skin === "Normal" &&
          neuro === "Normal") {
          normal = true
        }

        const exam = {
          head,
          neck,
          eyes,
          nose,
          mouth,
          ears,
          cardio,
          thorax,
          abdomen,
          genital,
          extremities,
          mental,
          neuro,
          skin,
          normal,
        }

        if (this.exams.length > 0) {
          this.exams[0] = exam
        } else {
          this.exams.push(exam);
        }
        var elem = document.getElementById("addExam-modal");
        var instance = M.Modal.getInstance(elem);

        instance.close();

        this.newExam = {
          head: '',
          neck: '',
          eyes: '',
          nose: '',
          mouth: '',
          ears: '',
          cardio: '',
          thorax: '',
          abdomen: '',
          genital: '',
          extremities: '',
          mental: '',
          neuro: '',
          skin: '',
        }
      },

      editExam(exam) {
        const {
          head,
          neck,
          eyes,
          nose,
          mouth,
          ears,
          cardio,
          thorax,
          abdomen,
          genital,
          extremities,
          mental,
          neuro,
          skin,
        } = exam
        var elem = document.getElementById("addExam-modal");
        var instance = M.Modal.getInstance(elem);

        instance.open();

        this.newExam = {
          head,
          neck,
          eyes,
          nose,
          mouth,
          ears,
          cardio,
          thorax,
          abdomen,
          genital,
          extremities,
          mental,
          neuro,
          skin,
        }
      },

      addDiagnosis() {

        const {
          principal,
          secondary
        } = this.newDiagnosis

        if (!principal) {
          return M.toast({
            html: this.$t("newCon.addDiagnosis.error"),
          });
        }

        if (this.editingDiagnosis) {
          let index = this.diagnostics.indexOf(this.editingDiagnosis)
          this.diagnostics[index] = {
            id: this.editingDiagnosis.id,
            principal,
            secondary,
          }
          this.editingDiagnosis = null
        } else {
          const diagnosis = {
            id: this.diagnosisId,
            principal,
            secondary,
          }
          this.diagnostics.push(diagnosis)
          this.diagnosisId++
        }

        var elem = document.getElementById("addDiagnosis-modal");
        var instance = M.Modal.getInstance(elem);
        instance.close();
        this.newDiagnosis.principal = null
        this.newDiagnosis.secondary = null
      },

      editDiagnosis(diag) {
        const {
          principal,
          secondary
        } = diag

        this.newDiagnosis = {
          principal,
          secondary
        }

        this.editingDiagnosis = diag

        var elem = document.getElementById("addDiagnosis-modal");
        var instance = M.Modal.getInstance(elem);

        instance.open();

      },

      deleteDiagnosis(diag) {
        this.diagnostics = this.diagnostics.filter((item) => item.id != diag.id)
      },

      addPrescription() {

        if (!this.prescriptionDestiny || this.prescriptionDestiny.trim() == "") {
          return M.toast({
            html: this.$t("newCon.addPrescription.errors[0]"),
          });
        }

        if (!this.prescriptionDiagnose) {
          return M.toast({
            html: this.$t("newCon.addPrescription.errors[1]"),
          });
        }

        const prescription = {
          id: this.prescriptionId,
          destiny: this.prescriptionDestiny,
          diagnose: this.prescriptionDiagnose,
          medicines: [],
        }

        this.prescriptions.push(prescription)

        var elem = document.getElementById("addPrescription-modal");
        var instance = M.Modal.getInstance(elem);

        instance.close();

        this.prescriptionDestiny = ""
        this.prescriptionDiagnose = null,
          this.prescriptionId++
      },

      deletePrescription(item) {
        this.prescriptions = this.prescriptions.filter((i) => i.id != item.id)
      },

      addMedicine() {
        const {
          medicine,
          prescription,
          quantity,
          when,
          duration,
          frequency,
          description
        } = this.newMedicine

        if (!description || description.trim() == "" ||
          !quantity || !duration || !when || !frequency) {
          return M.toast({
            html: this.$t("newCon.errormsg"),
          });
        }

        medicine.Quantity = quantity,
          medicine.Frequency = frequency,
          medicine.Duration = {
            Quantity: duration,
            Type: 'dias'
          }
        medicine.Annotation = description
        medicine.When = when.description

        const index = this.prescriptions.indexOf(prescription)

        this.prescriptions[index].medicines.push(medicine)

        this.newMedicine = {
          step: 1,
          prescription: null,
          medicine: null,
          quantity: null,
          frequency: null,
          duration: null,
          description: '',
          when: null,
        }

        var elem = document.getElementById("addMedicine-modal");
        var instance = M.Modal.getInstance(elem);

        instance.close();
      },

      openMedicineModal(prescription) {
        this.search = ""
        this.newMedicine.step = 1
        var elem = document.getElementById("addMedicine-modal");
        var instance = M.Modal.getInstance(elem);

        instance.open();

        this.newMedicine.prescription = prescription
      },

      addOrder() {

        if (!this.orderDestiny || this.orderDestiny.trim() == "") {
          return M.toast({
            html: this.$t("newCon.addOrder.errors[0]"),
          });
        }

        if (!this.newLaboratory.type) {
          return M.toast({
            html: this.$t("newCon.addOrder.errors[1]"),
          });
        }

        const order = {
          id: this.orderId,
          destiny: this.orderDestiny,
          type: this.newLaboratory.type,
          laboratories: [],
        }

        this.orders.push(order)

        var elem = document.getElementById("addOrder-modal");
        var instance = M.Modal.getInstance(elem);

        instance.close();

        this.orderDestiny = ""
        this.orderId++
      },

      deleteOrder(item) {
        this.orders = this.orders.filter((i) => i.id != item.id)
      },

      openLabModal(order) {
        var elem = document.getElementById("addLab-modal");
        var instance = M.Modal.getInstance(elem);

        instance.open();

        this.newLaboratory.order = order
      },

      addLab() {
        const {
          selectedProc,
          order,
          diagnosis
        } = this.newLaboratory

        if (!diagnosis) {
          return M.toast({
            html: this.$t("newCon.addLab.errors[0]"),
          });
        }

        if (!selectedProc) {
          return M.toast({
            html: this.$t("newCon.addLab.errors[1]"),
          });
        }

        const index = this.orders.indexOf(order)

        this.orders[index].laboratories.push({
          Diagnose: diagnosis,
          Proc: selectedProc,
        })

        this.newLaboratory.type = null
        this.newLaboratory.selectedProc = null

        var elem = document.getElementById("addLab-modal");
        var instance = M.Modal.getInstance(elem);

        instance.close();
      },

      addDocument() {
        const {
          type
        } = this.newDocument

        if (type == 'disability') {

          const {
            description,
            diagnosis,
            days
          } = this.newDocument.disability

          if (!diagnosis || !description || description.trim() == "" || !days) {
            return M.toast({
              html: this.$t("newCon.errormsg"),
            });
          }

          this.medicalDisability = {
            type: this.$t('newCon.addDocument.options[0]'),
            Description: description,
            StartDate: this.startDate,
            EndDate: this.endDate,
            DisabilityDays: days,
            Diagnose: {
              Code: diagnosis.Code,
              Diagnose: diagnosis.Name
            },
          }

        } else {

          const {
            description,
            diagnosis
          } = this.newDocument.certificate

          if (!diagnosis || !description || description.trim() == "") {
            return M.toast({
              html: this.$t("newCon.errormsg"),
            });
          }

          this.medicalCertificate = {
            type: this.$t('newCon.addDocument.options[1]'),
            Description: description,
            Diagnose: {
              Code: diagnosis.Code,
              Diagnose: diagnosis.Name
            },
          }
        }

        this.newDocument = {
          type: "",
          hastype: false,
          disability: {
            diagnosis: null,
            description: '',
            dateRange: {
              start: new Date(),
              end: new Date(),
            },
            days: null,
          },
          certificate: {
            diagnosis: null,
            description: '',
          },
        }

        var elem = document.getElementById("addDocument-modal");
        var instance = M.Modal.getInstance(elem);

        instance.close();
      },

      editDisability() {
        const disability = {
          diagnosis: this.medicalDisability.Diagnose,
          description: this.medicalDisability.Description,
          days: this.medicalDisability.DisabilityDays,
        }

        this.newDocument.hastype = true
        this.newDocument.type = "disability"
        this.newDocument.disability = disability

        var elem = document.getElementById("addDocument-modal");
        var instance = M.Modal.getInstance(elem);

        instance.open();
      },

      deleteDisability() {
        this.medicalDisability = null
      },

      editCertificate() {
        const certificate = {
          diagnosis: this.medicalCertificate.Diagnose,
          description: this.medicalCertificate.Description,
        }

        this.newDocument.hastype = true
        this.newDocument.type = "certificate"
        this.newDocument.certificate = certificate

        var elem = document.getElementById("addDocument-modal");
        var instance = M.Modal.getInstance(elem);

        instance.open();
      },

      deleteCertificate() {
        this.medicalCertificate = null
      },

      addAnalysis() {
        const a = {
          description: this.analysisDesc,
        }

        if(!a.description || a.description.trim() == "") {
          return M.toast({
            html: this.$t("newCon.addAnalysis.error"),
          });
        }

        this.analysis = a
        var elem = document.getElementById("addAnalysis-modal");
        var instance = M.Modal.getInstance(elem);

        instance.close();

        this.analysisDesc = ""
      },

      editAnalysis() {
        this.analysisDesc = this.analysis.description

        var elem = document.getElementById("addAnalysis-modal");
        var instance = M.Modal.getInstance(elem);

        instance.open();
      },

      async getProcedures(event) {
        let term = event.query
        if (term.length >= 3) {
          const headers = {
            Authorization: `Bearer ${this.userToken}`,
            lang: process.env.VUE_APP_LOCALE,
            country: process.env.VUE_APP_COUNTRY,
            appid: process.env.VUE_APP_APP_ID,
            companyid: process.env.VUE_APP_COMPANY_ID,
          };

          let type = this.newLaboratory.type

          const params = {
            term,
            count: 5,
            type,
            appid: process.env.VUE_APP_APP_ID,
            companyid: process.env.VUE_APP_COMPANY_ID,
          }

          const response = await axios.get(
            process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/procedures", {
              headers,
              params
            }
          );

          let data = response.data
          let newData = []
          for (let i = 0; i < data.length; i++) {
            const element = {
              Label: data[i].Code + ' - ' + data[i].Name,
              Name: data[i].Name,
              Code: data[i].Code,
              Description: data[i].Description,
              Applicability: data[i].Applicability,
            };
            newData.push(element)
          }

          this.procedures = newData
        }
      },

      getState() {
        let form = {
          head: '',
          neck: '',
          eyes: '',
          nose: '',
          mouth: '',
          ears: '',
          cardio: '',
          thorax: '',
          abdomen: '',
          genital: '',
          extremities: '',
          mental: '',
          neuro: '',
          skin: '',
        }
        if (this.switch1) {
          form = {
            head: 'Normal',
            neck: 'Normal',
            eyes: 'Normal',
            nose: 'Normal',
            mouth: 'Normal',
            ears: 'Normal',
            cardio: 'Normal',
            thorax: 'Normal',
            abdomen: 'Normal',
            genital: 'Normal',
            extremities: 'Normal',
            mental: 'Normal',
            neuro: 'Normal',
            skin: 'Normal',
          }
        }
        this.newExam = form
      },

      getRevisionState() {
        let form = {
          consti: '',
          sense: '',
          cardio: '',
          endocrine: '',
          gastro: '',
          genito: '',
          musculo: '',
          mental: '',
          neuro: '',
        }
        if (this.switch2) {
          form = {
            consti: this.$t('newCon.addRevision.switchLabel'),
            sense: this.$t('newCon.addRevision.switchLabel'),
            cardio: this.$t('newCon.addRevision.switchLabel'),
            endocrine: this.$t('newCon.addRevision.switchLabel'),
            gastro: this.$t('newCon.addRevision.switchLabel'),
            genito: this.$t('newCon.addRevision.switchLabel'),
            musculo: this.$t('newCon.addRevision.switchLabel'),
            mental: this.$t('newCon.addRevision.switchLabel'),
            neuro: this.$t('newCon.addRevision.switchLabel'),
          }
        }
        this.newRevision = form
      },

      async createConsultation() {
        let body = this.generateBody()

        if (body) {
          const headers = {
            Authorization: `Bearer ${this.userToken}`,
            lang: process.env.VUE_APP_LOCALE,
            country: process.env.VUE_APP_COUNTRY,
            appid: process.env.VUE_APP_APP_ID,
            companyid: process.env.VUE_APP_COMPANY_ID,
          };

          this.loading = true

          try {
            await axios.post(process.env.VUE_APP_BOPS_KEYC_API_V2_URL + "/api/consultation", body, {
              headers,
            }).then((response) => {
              if (response.status == 200) {
                M.toast({
                  html: this.$t("newCon.addMsgs.success"),
                });
                this.available = false
                this.$emit('newCon')
              } else {
                M.toast({
                  html: this.$t("newCon.addMsgs.success"),
                });
              }
            })
          } catch (error) {
            console.log(error)
            M.toast({
              html: this.$t("newCon.addMsgs.success"),
            });
          }

          this.loading = false
        }

      },

      generateBody() {

        let date = new Date()

        let dateStr = this.convertDate(date) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date
          .getSeconds())

        let MedicalDisability = null
        if (this.medicalDisability) {
          MedicalDisability = {
            Description: this.medicalDisability ? this.medicalDisability.Description : null,
            Diagnose: this.medicalDisability ? this.medicalDisability.Diagnose : null,
            StartDate: this.medicalDisability ? this.medicalDisability.StartDate : null,
            EndDate: this.medicalDisability ? this.medicalDisability.EndDate : null,
            DisabilityDays: this.medicalDisability ? this.medicalDisability.DisabilityDays : null,
          }
        }

        let MedicalCertificate = null
        if (this.medicalCertificate) {
          MedicalCertificate = {
            Description: this.medicalCertificate ? this.medicalCertificate.Description : null,
            Diagnose: this.medicalCertificate ? this.medicalCertificate.Diagnose : null,
          }
        }



        let Diagnoses = []
        for (let i = 0; i < this.allDiagnose.length; i++) {
          let e = {
            Code: this.allDiagnose[i].Code,
            Diagnose: this.allDiagnose[i].Name
          }
          Diagnoses.push(e)
        }

        if (Diagnoses.length == 0) {
          M.toast({
            html: this.$t("newCon.addMsgs.validations[0]"),
          });
          return null
        }


        let Recipes = []
        for (let i = 0; i < this.prescriptions.length; i++) {
          let e = this.prescriptions[i];
          let medications = []

          if (e.medicines.length == 0) {
            M.toast({
              html: this.$t("newCon.addMsgs.validations[1]"),
            });
            return null
          }

          for (let k = 0; k < e.medicines.length; k++) {
            let med = {
              Quantity: e.medicines[k].Quantity,
              Annotation: e.medicines[k].Annotation,
              Frenquency: {
                Quantity: e.medicines[k].Frequency.Quantity,
                Type: e.medicines[k].Frequency.Type
              },
              Duration: {
                Quantity: e.medicines[k].Duration.Quantity,
                Type: e.medicines[k].Duration.Type,
              },
              Atc: e.medicines[k].Code,
              ActiveSubstance: e.medicines[k].ActiveSubstance,
              Name: e.medicines[k].Name,
              Product: e.medicines[k].Product,
              Presentation: e.medicines[k].Presentation,
              Unit: e.medicines[k].Unit,
              Doze: e.medicines[k].Doze,
              Via: e.medicines[k].Via,
              Pharmacy: e.medicines[k].Pharmacy,
              When: e.medicines[k].When
            }

            medications.push(med)
          }

          let recipe = {
            Description: e.destiny,
            Diagnose: e.diagnose,
            Medications: medications,
          }


          Recipes.push(recipe)
        }

        let Orders = []
        for (let i = 0; i < this.orders.length; i++) {
          let e = this.orders[i];
          let procedures = []

          if (e.laboratories.length == 0) {
            M.toast({
              html: this.$t("newCon.addMsgs.validations[2]"),
            });
            return null
          }

          for (let k = 0; k < e.laboratories.length; k++) {
            let proc = {
              Code: e.laboratories[k].Proc.Code,
              Name: e.laboratories[k].Proc.Name,
              Description: e.laboratories[k].Proc.Description,
              Applicability: e.laboratories[k].Proc.Applicability,
              Diagnose: {
                Code: e.laboratories[k].Diagnose.Code,
                Diagnose: e.laboratories[k].Diagnose.Name
              }
            }

            procedures.push(proc)
          }

          let order = {
            Type: e.type,
            Description: e.destiny,
            Procedures: procedures,
          }

          Orders.push(order)
        }

        let ReviewBySystems = null
        if (this.revisions.length > 0) {
          ReviewBySystems = {
            Constitucionales: this.revisions[0].consti,
            OrganosSentidos: this.revisions[0].sense,
            Cardiorespiratorio: this.revisions[0].cardio,
            Endocrino: this.revisions[0].endocrine,
            Gastrointestinal: this.revisions[0].gastro,
            Genitourinario: this.revisions[0].genito,
            Musculoesqueletico: this.revisions[0].musculo,
            Neurologico: this.revisions[0].neuro,
            Mental: this.revisions[0].mental
          }
        }

        let PhisicalExam = null
        if (this.exams.length > 0) {
          PhisicalExam = {
            Cabeza: this.exams[0].head,
            Cuello: this.exams[0].neck,
            Ojos: this.exams[0].eyes,
            Nariz: this.exams[0].nose,
            Boca: this.exams[0].mouth,
            Orejas: this.exams[0].ears,
            Cardiorespiratorio: this.exams[0].cardio,
            Torax: this.exams[0].thorax,
            Abdomen: this.exams[0].abdomen,
            Genital: this.exams[0].genital,
            Extremidades: this.exams[0].extremities,
            Mental: this.exams[0].mental,
            Neurologico: this.exams[0].neuro,
            Piel: this.exams[0].skin,
          }
        }

        let Analysis = null
        if (this.analysis) {
          Analysis = {
            Description: this.analysis.description
          }
        }

        let Note = null
        if (this.notes.length > 0) {
          Note = {
            Reason: this.notes[0].reason,
            Type: this.notes[0].type,
            Description: this.notes[0].illness,
          }
        }

        const body = {
          ConsultationId: this.consultationId,
          ConsultationDate: dateStr,
          PatientId: this.patient.Id,
          Type: this.consultationType,
          Status: "4",
          DoctorId: store.getters.getUser.Id,
          Note,
          ReviewBySystems,
          PhisicalExam,
          Diagnoses,
          MedicalDisability,
          MedicalCertificate,
          Recipes,
          Orders,
          Analysis,
        }

        return body
      },
    },
    mounted() {
      this.getConsultation()
      M.AutoInit();

      var select = document.querySelectorAll("select");
      M.FormSelect.init(select);

      var modals = document.querySelectorAll(".modal");
      M.Modal.init(modals);
    },
  });
</script>

<style scoped>
  #newConsultation {
    position: relative;
    padding: 16px 20px;
    overflow-y: scroll;
    height: calc(100% - 135px);
  }

  .modal,
  #newConsultation:deep(.modal-overlay) {
    position: absolute !important;
  }

  #newConsultation:deep(.modal-overlay) {
    top: 0 !important;
    height: 100%;
  }

  .input-field {
    padding: 0;
  }

  .modal-content h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }

  .modal-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #909194;
    margin-bottom: 10px !important;
  }

  .modal-footer .btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #ffffff !important;
    padding: 10px 24px;
    box-shadow: none !important;
  }

  .modal-footer .d-flex {
    justify-content: space-between;
    align-items: center;
  }

  .modal .modal-footer {
    padding: 15px 24px 0;
    margin-bottom: 17px;
  }

  .modal-footer .modal-close {
    background: #e1e2e6;
    color: #656e73 !important;
    margin-right: 16px !important;
  }

  #save-button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .more {
    border-radius: 50%;
  }

  #addExam-modal label,
  #addRevision-modal label {
    float: left !important;
    margin: 0 15px 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #3F454E;
  }

  #addExam-modal .row,
  #addRevision-modal .row {
    margin-bottom: 0;
  }

  .switch-cont span {
    font-weight: 500;
    color: #3F454E;
    margin-right: 10px;
  }

  #addExam-modal h4,
  #addRevision-modal h4,
  .switch-cont {
    margin-left: 15px !important;
  }

  .switch-cont {
    margin: 20px 0;
  }

  #addPrescription .input-field {
    padding: 0 0.75rem !important;
  }

  #addNote-modal .modal-content h5,
  #addDocument-modal .modal-content h5 {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    margin: 0 !important;
    letter-spacing: 0.5px !important;
    color: #1B1D1F !important;
  }

  .type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 5px;
  }

  .disability .row p {
    font-size: 12px !important;
    line-height: 18px !important;
    margin: 0 !important;
  }

  .disability .row {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }

  .table-medicine {
    min-height: 420px !important;
  }

  #addMedicine .modal-footer {
    margin-top: 24px;
  }

  #addMedicine-modal tbody tr {
    cursor: pointer;
  }

  #addMedicine-modal tbody tr.active {
    background-color: #d1d2d4;
  }

  #addMedicine-modal tbody tr td {
    border-radius: 0 !important;
  }

  #addMedicine-modal tbody tr:hover {
    background-color: #e1e2e6;
  }

  #addMedicine-modal .formula .row {
    margin-bottom: 0 !important;
  }

  #addMedicine-modal .formula .days-col {
    align-items: center;
  }

  #addMedicine-modal .formula .days-col p {
    margin: 5px 5px 0 !important;
  }

  #addMedicine-modal .formula .input-field,
  #addMedicine-modal .formula .drop-col {
    margin: 15px 0 !important;
  }

  #addLab-Modal .drop-col {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  #addMedicine-modal .formula .drop-col {
    padding: 0 0.75rem !important;
  }

  #addMedicine-modal .formula input[type="number"] {
    text-align: center;
  }

  #loading-modal {
    position: fixed !important;
    top: 25% !important;
    width: 40%;
    z-index: 9999;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 25px 0;
  }

  #loading-modal h4 {
    font-size: 30px !important;
    margin: 35px 0 !important;
  }

  @media (max-width: 882px) {
    .modal-small {
      width: 80% !important;
    }
  }
</style>