<template>
  <div id="call-view">
    <ConfirmDialog class="call-exit"></ConfirmDialog>
    <div class="controls d-flex">
      <button class="waves-effect waves-light btn btn-text" v-if="cam" @click="changeCam(false)"><i
          class="material-icons">videocam</i></button>
      <button class="waves-effect waves-light btn btn-text" v-else @click="changeCam(true)"><i
          class="material-icons">videocam_off</i></button>
      <button class="waves-effect waves-light btn btn-text" v-if="mic" @click="changeMic(false)"><i
          class="material-icons">mic</i></button>
      <button class="waves-effect waves-light btn btn-text" v-else @click="changeMic(true)"><i
          class="material-icons">mic_off</i></button>
      <button class="waves-effect waves-light btn btn-text"><i
          class="material-icons">note_add</i></button>   
      <button class="waves-effect waves-light btn btn-text disconnect" @click="disconnect"><i
          class="material-icons">call_end</i></button>
      <button class="waves-effect waves-light btn btn-text"><i
          class="material-icons">radio_button_checked</i></button>  
      <button class="waves-effect waves-light btn btn-text"><i
          class="material-icons">lock</i></button>      
    </div>
    <div id="remotevideo">
      <div id="localvideo" class="cont-cam"></div>
    </div>
  </div>
</template>

<script>
import { joinRoom, disconnectCall, turnOnOffCam, turnOnOffMic } from '@/core/twilio'
export default {
  name: 'CallView',
  data() {
    return {
      cam: true,
      mic: true,
      room: null,
    }
  },
  methods: {
    changeMic(state) {
      this.room.then(room => {
        this.mic = state
        turnOnOffMic(room, state)
      })
    },
    changeCam(state) {
      this.room.then(room => {
        this.cam = state
        turnOnOffCam(room, state)
      })
    },
    disconnect() {
      return this.$confirm.require({
        message: this.$t('callExit.validate.text'),
        acceptClass: 'btn-text go',
        rejectClass: 'btn-text cancel',
        acceptLabel: this.$t('callExit.validate.buttons[1]'),
        rejectLabel: this.$t('callExit.validate.buttons[0]'),
        accept: () => {
        },
        reject: () => {
          this.room.then((room) => {
            disconnectCall(room)
          })
          this.room = null
          window.removeEventListener('beforeunload', this.beforeExit)
          window.close()
        }
      }) 
    },
    beforeExit: function handler (event) {
      event.preventDefault();
      return event.returnValue = this.$t('callExit.validate.text')
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeExit)
  },
  mounted() {
    let params = this.$route.query
    this.room = joinRoom(params.token, params.roomId, params.type, 'remotevideo')
    console.log(params)
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.beforeExit)
  },
}
</script>

<style scoped>
#call-view {
  height: 100vh;
}

.controls {
  justify-content: center;
  background-color: #363B42;
}

.controls .btn {
  margin: 0 5px;
}

.controls .btn .material-icons {
  margin-right: 0 !important;
  line-height: 10px;
  height: 14px;
  font-size: 21px !important;
}

.controls .disconnect {
  color: #EE1919 !important;
}

#localvideo {
  width: 180px;
  height: 150px;
  margin: 0;
  background-color: #242A33;
  position: absolute !important;
  bottom: 20px !important;
  right: 20px !important;
}

#remotevideo {
  height: 100%;
}

#remotevideo:deep(.media-container) {
  height: 100%;
}

</style>
