<template>
  <div id="call-view-loader" v-if="!hasSession">
    <div class="row">
      <div class="col s12">
        <h3>Por favor espere mientras el paciente se conecta a la videollamada, esto puede tomar unos minutos.</h3>
        <ProgressSpinner />
      </div>
    </div>
  </div>
  <div id="call-view" v-else>
    <ConfirmDialog class="call-exit"></ConfirmDialog>
    <div class="controls d-flex">
      <button class="waves-effect waves-light btn btn-text" v-if="cam" @click="changeCam(false)"><i
          class="material-icons">videocam</i></button>
      <button class="waves-effect waves-light btn btn-text" v-else @click="changeCam(true)"><i
          class="material-icons">videocam_off</i></button>
      <button class="waves-effect waves-light btn btn-text" v-if="mic" @click="changeMic(false)"><i
          class="material-icons">mic</i></button>
      <button class="waves-effect waves-light btn btn-text" v-else @click="changeMic(true)"><i
          class="material-icons">mic_off</i></button>
      <input type="file" name="file-input" id="file-input" accept=".jpg,.jpeg,.png,.pdf" hidden @change="sendFile" />
      <label for="file-input" class="waves-effect waves-light btn btn-text">
        <i class="material-icons">note_add</i>
      </label>

      <button class="waves-effect waves-light btn btn-text disconnect" @click="disconnect"><i
          class="material-icons">call_end</i></button>
      <button class="waves-effect waves-light btn btn-text"><i
          class="material-icons">radio_button_checked</i></button>  
      <button class="waves-effect waves-light btn btn-text"><i
          class="material-icons">lock</i></button>      
    </div>
    <div id="remotevideo">
      <div id="localvideo" class="cont-cam"></div>
    </div>
  </div>
</template>

<script>
import { joinSession, setAudio, setVideo, hangUpSession } from '../../core/vonage'
import { database } from '@/core/firebase'
import { ref, get, update } from '@firebase/database'
import { httpKeycV2 } from '@/core/http'
import store from '@/store'
import ProgressSpinner from 'primevue/progressspinner';
export default {
  name: 'CallView',
  components: {
    ProgressSpinner
  },
  data() {
    return {
      cam: true,
      mic: true,
      room: null,
      session: null,
      hasSession: false,
    }
  },
  computed: {
    notifications() {
      return store.getters.getNotifications.sort((a, b) => { return b.date - a.date });
    },
    notiLength() {
      return store.getters.getNotifications.length;
    },
  },
  watch: {
    notiLength() {
      this.activeCall();
      this.disconnectCall();
    },
  },
  methods: {
    changeMic(state) {
      this.mic = state;
      setAudio(this.session.publisher, state);
    },
    changeCam(state) {
      this.cam = state;
      setVideo(this.session.publisher, state);
    },
    async activeCall() {
      let { appointmentId } = this.$route.query
      for (let i = 0; i < this.notifications.length; i++) {
        const n = this.notifications[i];
        if(n.data.notificationCode == "VD001" && n.data.appointmentId === appointmentId && !this.hasSession) {
          this.hasSession = true;
          this.session = await joinSession(n.data.twilioCallRoom);
          this.session.session.on('sessionDisconnected', function() {
            window.close();
          })
          break;
        }
      }
    },
    disconnectCall() {
      let { appointmentId } = this.$route.query
      for (let i = 0; i < this.notifications.length; i++) {
        const n = this.notifications[i];
        if(n.data.notificationCode == "VD002" && n.data.appointmentId === appointmentId) {
          hangUpSession(this.session.session);          
        }
      }
    },
    async sendInteraction() {
      let { appointmentId } = this.$route.query
      const notiref = ref(database, 'interactions/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID + '/' + appointmentId);

      const interaction = {
        StartedBy: store.getters.getUser.Id,
        Timestamp: new Date().toISOString(),
        Type: 'endCall'
      };

      await get(notiref).then((snap) => {
        console.log(snap.val())
        let interactions = snap.val().Interactions;
        update(notiref, { Interactions: [...interactions, interaction] })
      })
    },
    async sendFile(event) {
      const allowedTypes = ['pdf', 'png', 'jpg', 'jpeg'];
      const target = event.target;
      let file = target.files ? target.files.item(0) : null;
      if (!file) return;

      const extension = file.name.split('.').at(-1);

      const found = allowedTypes.find(e => e === extension);

      if (!found)return;

      console.log(this.$route.query)

      let { appointmentId, patientId, consultationDate } = this.$route.query
      const formData = new FormData();

      formData.append('FileExtension', extension);
      formData.append('ContentType', file.type);
      formData.append('File', file);
      formData.append('PatientId', patientId);
      formData.append('DoctorId', store.getters.getUser.Id);
      formData.append('ConsultationId', appointmentId);
      formData.append('ConsultationDate', consultationDate);
      formData.append('FileName', file.name);

      for (var pair of formData.entries()) {
          console.log(pair[0]+ ', ' + pair[1]); 
      }

      try {
        await httpKeycV2.post('/api/files', formData);
        M.toast({
          html: `El archivo ha sido enviado exitosamente`,
        });
      } catch (error) {
        M.toast({
          html: `Se ha producido un problema, intente cargar el archivo nuevamente`,
        });
      }
    },
    async notificationDisconnect() {
      let { appointmentId } = this.$route.query
      let notiref = ref(database, 'notification/'+appointmentId);

      const notification = {
        data: {
          notificationCode: "VD003",
          title: "Call finished",
          message: "Doctor finished the call",
        },
        properties: {
          groupId: "calls",
          order: 4,
          showNotification: false,
          viewed: false,
        },
        timestamp: new Date().toISOString(),
      };

      let notiSnapshot = await get(notiref);

      const notifications = notiSnapshot.val().Notifications;
      await update(notiref, {
        Notifications: [...notifications, notification]
      });
    },
    disconnect() {
      return this.$confirm.require({
        message: this.$t('callExit.validate.text'),
        acceptClass: 'btn-text go',
        rejectClass: 'btn-text cancel',
        acceptLabel: this.$t('callExit.validate.buttons[1]'),
        rejectLabel: this.$t('callExit.validate.buttons[0]'),
        accept: () => {
        },
        reject: async () => {
          window.removeEventListener('beforeunload', this.beforeExit, true);
          await this.sendInteraction();
          hangUpSession(this.session.session);
          await this.notificationDisconnect();
          this.session = null;
        }
      }) 
    },
    beforeExit: function handler (event) {
      event.preventDefault();
      return event.returnValue = this.$t('callExit.validate.text')
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeExit, true)
  },
  async mounted() {
  },
  unmounted() {
    window.removeEventListener('beforeunload', this.beforeExit, true)
  },
}
</script>

<style scoped>
#call-view {
  height: 100vh;
}

#call-view-loader {
  margin-top: 100px;
}

#call-view-loader h3 {
  margin-bottom: 30px !important;
}

.controls {
  justify-content: center;
  background-color: #363B42;
}

.controls .btn {
  margin: 0 5px;
}

.controls .btn .material-icons {
  margin-right: 0 !important;
  line-height: 10px;
  height: 14px;
  font-size: 21px !important;
}

.controls .disconnect {
  color: #EE1919 !important;
}

#localvideo {
  width: 180px;
  height: 150px;
  margin: 0;
  background: url('../../assets/images/videocall-background.svg') no-repeat center center;
  background-size: cover;
  background-color: #242A33;
  position: absolute !important;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 1000;
}

#remotevideo {
  height: 100%;
}

#remotevideo:deep(.media-container) {
  background: url('../../assets/images/videocall-background.svg') no-repeat center center;
  background-size: cover;
  height: 100%;
}

</style>