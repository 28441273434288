
  import {
    defineComponent
  } from "vue";

  export default defineComponent({
    props: {
      type: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        options: [{
          title: 'Haz clic en las programaciones para ver información de la cita',
          subtitle: 'Podras llamar y chatear con tus pacientes',
          img: 'CallcenterIconOrange.png',
        }, {
          title: 'Haz clic en un paciente para ver la información de la cita finalizada',
          subtitle: 'En la parte derecha puedes visualizar los datos del paciente, documentos e interacciones que hubieron durante la cita.',
          img: 'CallCenterEnded.png',
        }, {
          title: 'haz clic en un paciente para ver la información de la cita cancelada',
          subtitle: 'Podrás elegir el motivo por el cual la cita fue cancelada.',
          img: 'CallCenterCancel.png',
        },],
      }
    },
  });
