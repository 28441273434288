import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c3b64d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-content" }
const _hoisted_3 = { class: "card-title left-align" }
const _hoisted_4 = { class: "left-align" }
const _hoisted_5 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('callcenterInfo.patient.title')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('callcenterInfo.patient.text')), 1),
      _withDirectives(_createElementVNode("input", {
        class: "rounded-input",
        type: "text",
        placeholder: _ctx.$t('callcenterInfo.patient.placeholder'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.validateCode && _ctx.validateCode(...args)), ["enter"]))
      }, null, 40, _hoisted_5), [
        [_vModelText, _ctx.code]
      ]),
      _createElementVNode("button", {
        class: "btn btn-primary btn-rounded waves-effect waves-light",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.validateCode && _ctx.validateCode(...args)))
      }, _toDisplayString(_ctx.$t('callcenterInfo.patient.button')), 1)
    ])
  ]))
}