
  import {
    defineComponent
  } from 'vue'
  import store from '../../../store';

  export default defineComponent({
    name: 'SideNav',
    mounted() {
      var elems = document.querySelectorAll('.sidenav');
      M.Sidenav.init(elems);
    },
    methods: {
      close() {
        var elem = document.querySelector('.sidenav');
        var instance = M.Sidenav.getInstance(elem as any)
        instance.close()
      }      
    },
    computed: {
      route() {
        return this.$route.name
      },
      scheduleSpeciality() {
        return store.getters.agendaSpecialties;
      }
    }
  })
