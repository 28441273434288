<template>
  <div id="unattended">
    <Dialog v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :closable="false"
      :style="{width: '50vw'}" :modal="true" :draggable="false">
      <h3 class="left-align"> {{ $t('callExit.reason.title') }} </h3>
      <Dropdown id="reason-exit" v-model="reason" :options="wrapUpCodes" optionLabel="Label" :emptyMessage="$t('newCon.addDocument.emptySelect')" />
      <template #footer>
        <button class="waves-effect waves-light btn btn-rounded btn-primary" @click="updateAppointment">{{ $t('callExit.reason.buttons[1]') }}</button>
      </template>
    </Dialog>
    <ConfirmDialog class="call-exit"></ConfirmDialog>
    <div class="row">
      <div class="col s12">
        <div class="title">
          <i class="material-icons">smartphone</i>
          <h3 class="left-align"> {{ user.Name }} {{ user.LastName }} ({{ user.Phone }})</h3>
        </div>
      </div>
      <div class="col s12 call-cont">
        <div class="call-data" id="call-container">
          <div class="unattended-icon">
            <img :src="require('@/assets/images/Unattended.png')" alt="">
          </div>
          <div class="select-reason">
            <p>Cual fue el motivo por la cual esta cita no se llevo a cabo?</p>
            <Dropdown id="reason-exit" v-model="reasonUnattended" :options="options" :emptyMessage="$t('newCon.addDocument.emptySelect')" placeholder="-- Selecciona --" @change="markUnAttended"/>
          </div>
        </div>
        <div class="row buttons">
          <div class="col s12">
            <button class="waves-effect waves-light btn" v-if="!openPhonepad" @click="openKeyboard" :disabled="reasonUnattended === ''"><i class="material-icons-outlined">phone</i>
              {{ $t('call.buttons[1]') }} </button>
          </div>
        </div>
        <div class="history">
          <div class="history-title">
            <h3>Historial</h3>
          </div>
          <div class="history-numbers">
            <p> {{ lastNumber }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import router from '@/router';
  import { httpKeycV1 } from '@/core/http';
  import Dialog from 'primevue/dialog';
  import { database } from '@/core/firebase'
  import { ref, onValue } from '@firebase/database'
  import codes from "@/data/codes"
    
  export default({
    name: 'UnAttendedCall',
    components: {
      Dialog
    },
    data() {
      return {
        options: ['Usuario no completo requisitos', 'Usuario no contesto', 'Télefono incorrecto'],
        reason: '',
        displayModal: false,
        wrapUpCodes: [],
        lastNumber: '',
        codes: codes,
        reasonUnattended: '',
        openPhonepad: false,
      }
    },
    props: {
      user: Object,
      appointment: Object,
    },
    computed: {
      currentUser() {
        return store.getters.getUser;
      },
      userToken() {
        return store.getters.getToken;
      },
      quota() {
        return this.appointment.quota.trim().toLowerCase() == 'true'
      },
      consent() {
        return this.appointment.consent.trim().toLowerCase() == 'true'
      },
      notAnnotation() {
        return this.appointment.statusAnnotation === '';
      },
      realCodes() {
        const locale = this.$i18n.locale
        if (locale == 'es') {
          return this.codes.codesEs
        } else if (locale == 'en') {
          return this.codes.codesEn
        } else {
          return this.codes.codesPr
        }
      },
    },
    watch: {
      appointment(val) {
        this.reasonUnattended = val.statusAnnotation;
      }
    },
    methods: {
      async updateAppointment() {
        if (!this.reason) {
          return M.toast({
            html: this.$t("callExit.reason.alert"),
          });
        }
        const body = {
          StatusId: "4",
          AppointmentId: this.appointment.id,
          Annotation: this.reason.InternalCode,
          UserId: this.appointment.user.Id,
          CompanyId: process.env.VUE_APP_COMPANY_ID,
          AppId: process.env.VUE_APP_APP_ID,
        }

        try {
          await httpKeycV1.post(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/agenda/appointment-status', body).then((response) => {
            console.log(response)
            if (response.status == 200) {
              this.displayModal = false
              this.$emit('finishCall', this.appointment)
            }
          })
        } catch (error) {
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },
      openKeyboard() {
        let route = router.resolve({ name: 'KeyboardView', query: { appointmentId: this.appointment.id, userId: this.appointment.user.Id, specialty: this.appointment.specialty } })
        let call = window.open(route.href, "_blank", "left=150,width=350,height=450,top=100,status=no,location=no,toolbar=no,menubar=no,scrollbars=no,resizable=no")
        this.openPhonepad = true;
        var timer = setInterval(() => { 
            if(call.closed) {
              clearInterval(timer);
              this.$confirm.require({
                message: '¿Logró atender al paciente mediante llamada telefónica?',
                acceptClass: 'btn-text go',
                rejectClass: 'btn-text cancel',
                acceptLabel: this.$t('callExit.validate.buttons[0]'),
                rejectLabel: this.$t('callExit.validate.buttons[1]'),
                accept: () => {
                  this.displayModal = true;
                },
                reject: () => {
                  this.openPhonepad = false;
                }
              }) 
            }
        }, 1000);
      },
      async getWrapUpCodes() {
        let response = await httpKeycV1.get(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/agenda/wrapup-codes')

        let data = response.data

        for (let i = 0; i < data.length; i++) {
          const elem = data[i];
          this.wrapUpCodes.push({
            Label: this.realCodes[i],
            InternalCode: elem.InternalCode
          })
        }
      },
      getHistoryNumbers() {
        const notiref = ref(database, 'interactions/' + process.env.VUE_APP_APP_ID + '/' + process.env.VUE_APP_COMPANY_ID + '/' + this.appointment.id + '/PhoneAnswered');

        onValue(notiref, (snapshot) => {
          this.lastNumber = snapshot.val();
        });
      },
      async markUnAttended() {
        const body = {
          StatusId: "5",
          AppointmentId: this.appointment.id,
          Annotation: this.reasonUnattended,
          UserId: this.appointment.user.Id,
          CompanyId: process.env.VUE_APP_COMPANY_ID,
          AppId: process.env.VUE_APP_APP_ID,
        }
        console.log(body)

        try {
          await httpKeycV1.post(process.env.VUE_APP_BOPS_KEYC_API_URL + '/api/agenda/appointment-status', body).then((response) => {
            console.log(response)
            if (response.status == 200) {
              this.$emit('unattended', this.appointment)
            }
          })
        } catch (error) {
          M.toast({
            html: this.$t("newCon.addMsgs.error"),
          });
        }
      },
    },
    mounted() {
      this.getHistoryNumbers();
      this.getWrapUpCodes();
      this.reasonUnattended = this.appointment.statusAnnotation;
    },
  })
</script>

<style lang="scss" scoped>
  #unattended {
    height: 100%;
    overflow: hidden;
  }

  #unattended>.row {
    height: 100%;
  }

  .call-cont {
    height: calc(100% - 60px);
    overflow-y: auto;
  }

  .title {
    background-color: #EFF0F4;
    padding-left: 30px;
    height: 60px;
    display: flex;
    align-items: center;
  }
  .title h3 {
    margin: 0;
  }

  .material-icons,
  .material-icons-outlined {
    margin-right: 6px;
    vertical-align: sub !important;
  }

  .requirement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #969aa1 !important;
    }
  }

  .call-data {
    justify-content: center;
    max-width: 450px;
    margin: 65px auto 15px;
  }

  .unattended-icon {
    margin-bottom: 25px;
  }

  button {
    border-radius: 8px;
    box-shadow: none;
    font-weight: 500;
  }

  .select-reason {
    margin-top: 35px;
    padding-top: 21px;
    border-top: 1px solid #E4E7EC;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #798291;
    }
  }

  .buttons {
    .btn {
      border: 1px solid #FF6600;
      border-radius: 8px;
      background-color: transparent;
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #FF6600;
      text-transform: capitalize;
    }
    .btn:disabled {
      border: none !important;
    }
  }

  .history {
    .history-title {
      border-bottom: 1px solid #E4E7EC;
      padding-left: 40px;
      padding-bottom: 10px;
      h3 {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
         color: #000000;
         text-align: left;
      }
    }
    .history-numbers {
      padding-left: 40px;
      margin-top: 10px;
      margin-bottom: 20px;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #798291;
        text-align: left;
      }
    }
  }
</style>