import store from "@/store";
import { httpUsrkc } from "./http";

export const getTheme = async () => {
  const theme = await httpUsrkc.get("/api/v1/pageparams");

  const country = await httpUsrkc.get("/api/v1/country", { params: {
    id: theme.data.App.Countries[0],
  }});

  let themeid = process.env.VUE_APP_THEME == 'SCHEDULE_COMPLETE' ? 1 : 2
  
  try {
    require(`@/assets/scss/themes/${themeid}/index.scss`)
  } catch (e) {}

  const pageData = theme.data.App;
  const color = {
    primary: pageData.Colors.primaryColor,
    secondary: pageData.Colors.secondaryColor,
  }

  store.commit('SET_COLOR', color)
  store.commit('SET_APP', pageData)
  store.commit('SET_COMPANY', theme.data.Company)
  store.commit('SET_PLAN', theme.data.Plans[0])
  store.commit('SET_PLANS', theme.data.Plans)
  store.commit('SET_COUNTRY', country.data)

  const favicon = document.getElementById("favicon") as HTMLAnchorElement
  favicon.href = pageData.Properties.FaviconUrl

  store.commit('SET_LOGO', pageData.Properties.LogoUrl)

  let innerHTML = "";

  innerHTML += `
        :root {
          --primary: ${color.primary}!important;
          --secondary: ${color.secondary}!important;
        }

        .btn-primary {
          background-color: ${color.primary}!important;
        }.

    `;

  const style = document.createElement("style");
  style.innerHTML = innerHTML;

  const head = document.querySelector("head") as any;
  head.append(style);
};
