
import { defineComponent } from 'vue'
import RegisterForm from '@/components/themes/2/Register/RegisterForm.vue'

export default defineComponent({
  name: 'Register',
  components: {
    RegisterForm,
  },
})
