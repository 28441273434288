<template>
  <div id="user-table">
    <div class="row">
      <div class="col s12 m12 l12 d-flex">
        <div class="select-search">
          <MultiSelect id="optionsSearch" v-model="optionsSearch" :options="searchOptions" optionLabel="label" :placeholder="$t('userList.optionsLabel')" />
        </div>
        <div class="search-input" v-if="optionsSearch.length > 0">
          <i class="material-icons">search</i>
          <input type="text" v-model="searchName" :placeholder="$t('userList.searchOptions[0]')" @keyup.enter="searchSpecific" v-if="filterOptions(0)"/>
          <input type="text" v-model="searchLastname" :placeholder="$t('userList.searchOptions[1]')" @keyup.enter="searchSpecific" v-if="filterOptions(1)"/>
          <input type="text" v-model="searchId" :placeholder="$t('userList.searchOptions[2]')" @keyup.enter="searchSpecific" v-if="filterOptions(2)"/>
          <input type="text" v-model="searchEmail" :placeholder="$t('userList.searchOptions[3]')" @keyup.enter="searchSpecific" v-if="filterOptions(3)"/>
          <input type="text" v-model="searchPhone" :placeholder="$t('userList.searchOptions[4]')" @keyup.enter="searchSpecific" v-if="filterOptions(4)"/>
        </div>
        <button class="btn filter-btn waves-effect" @click="searchSpecific" v-if="optionsSearch.length > 0">
          <i class="material-icons-outlined">filter_alt</i>
        </button>
      </div>
      <div class="col s12">
        <ProgressSpinner v-if="loading" style="margin-top: 100px;"/>
        <div class="fix-width scroll-inner">
          <table v-if="patients.length > 0">
            <thead>
              <tr>
                <th>{{ $t("userList.tableTitles[0]") }}</th>
                <th>{{ $t("userList.tableTitles[1]") }}</th>
                <th>{{ $t("userList.tableTitles[2]") }}</th>
                <th>{{ $t("userList.tableTitles[3]") }}</th>
                <th>{{ $t("userList.tableTitles[5]") }}</th>
                <th>{{ $t("userList.tableTitles[6]") }}</th>
                <th>{{ $t("userList.tableTitles[7]") }}</th>
                <th>{{ $t("userList.tableTitles[8]") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in patients" :key="index">
                <td>{{ row.user.LastName }}</td>
                <td>{{ row.user.Name }}</td>
                <td>{{ calculateAge(row.user.Birthdate) }} años</td>
                <td>{{ row.user.Document.DocumentNumber }}</td>
                <td>{{ row.user.Phone }}</td>
                <td>{{ row.user.City }}</td>
                <td>{{ row.user.Address }}</td>
                <td>
                  <div class="pos-rel">
                    <a class="waves-effect waves-ligh more" @click="toggle($event, 'menuUser', index)" aria-haspopup="true" :aria-controls="'user_menu-' + index"><i class="material-icons">more_vert</i></a>
                    <Menu :id="'user_menu-' + index" ref="menuUser" :model="itemsUser" :popup="true">
                      <template #item="{item}">
                        <a class="p-menuitem-link" role="menuitem" @click="item.command(row)">
                          <span class="p-menuitem-icon material-icons">add</span>
                          <span class="p-menuitem-text">{{item.label}}</span>
                        </a>
                      </template>
                    </Menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="newAgenda-modal" class="modal modal-fixed-footer">
          <div class="modal-content">
            <div v-if="!addSuccess">
              <h4 class="left-align" v-if="modal.type === 1"> {{ $t('userList.modalftf.title') }} </h4>
              <h4 class="left-align" v-if="modal.type === 2"> {{ $t('userList.modalVirtual.title') }} </h4>
              <h4 class="left-align" v-if="modal.type === 3"> {{ $t('userList.modalHome.title') }} </h4>
              <p class="left-align" v-if="modal.page < 3"> {{ $t('userList.modalftf.step[0]') }} {{ modal.page }}
                {{ $t('userList.modalftf.step[1]') }} </p>
              <p class="left-align" v-else> {{ $t('userList.modalftf.step[0]') }} 3
                {{ $t('userList.modalftf.step[1]') }} </p>
              <div class="appointment-info">
                <p class="left-align title">{{ $t('userList.modalftf.appointmentTitles[0]') }} </p>
                <p class="left-align name"> {{ activeUser.Name }} {{ activeUser.LastName }} </p>
              </div>
              <div class="appointment-info" v-if="modal.page >= 2">
                <p class="left-align title">{{ $t('userList.modalftf.appointmentTitles[1]') }} </p>
                <p class="left-align name"> {{ modal.selectedDoctor.Name }} {{ modal.selectedDoctor.LastName }} </p>
              </div>
              <div class="appointment-info" v-if="modal.page >= 3">
                <p class="left-align title">{{ $t('userList.modalftf.appointmentTitles[2]') }} </p>
                <p class="left-align name"> {{ $t('userList.modalftf.months[' + modal.date.getMonth() + ']') }}
                  {{ modal.date.getDate() }}, {{ modal.date.getFullYear() }} </p>
              </div>
              <div class="appointment-info" v-if="modal.page >= 4">
                <p class="left-align title">{{ $t('userList.modalftf.appointmentTitles[3]') }} </p>
                <p class="left-align name"> {{ modal.schedule }} <span v-if="displayTimezones">- {{ modal.timezone.city }} - UTC{{ getOffsetTimezone(modal.timezone.timezone) >= 0 ? '+' : '' }}{{ getOffsetTimezone(modal.timezone.timezone) }} {{ modal.timezone.timezone }}</span> </p>
              </div>
              <div class="appointment-info" v-if="modal.page >= 4 && displayTimezones">
                <p class="left-align title">{{ $t('userList.modalftf.appointmentTitles[4]') }} </p>
                <p class="left-align name"> {{ transformDate(modal.date, modal.schedule, getOffsetTimezone(modal.timezone.timezone)) }}  </p>
              </div>

              <div v-if="modal.page === 1">
                <div class="services" v-if="!modal.chooseService">
                  <h3 class="left-align">
                    {{ $t('userList.modalftf.services.title') }}
                  </h3>
                  <div class="rectangle enable" @click="modal.chooseService = true">
                    <p class="left-align">{{ $t('userList.modalftf.services.items[0]') }} </p>
                  </div>
                  <div class="rectangle">
                    <p class="left-align">{{ $t('userList.modalftf.services.items[1]') }} </p>
                  </div>
                  <div class="rectangle">
                    <p class="left-align">{{ $t('userList.modalftf.services.items[2]') }} </p>
                  </div>
                </div>
                <div class="professionals" v-else>
                  <h3 class="left-align">
                    {{ $t('userList.modalftf.doctorsTitle') }}
                  </h3>

                  <div class="search-input input-modal">
                    <input type="text" v-model="searchdoc" :placeholder="$t('userList.placeholder')" @keyup.enter="searchDoctors(rowsDoctors)" />
                    <i class="material-icons">search</i>
                  </div>

                  <div class="professional" v-for="(doctor, index) in rowsDoctors" :key="doctor.id">
                    <p> {{doctor.Name}} {{ doctor.LastName }} - {{ doctor.Email }} </p>
                    <label>
                      <input class="with-gap" :id="'radio-' + index" :name="'radio-' + index" type="radio"
                        :value="doctor" v-model="modal.selectedDoctor" />
                      <span> </span>
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="modal.page === 2">
                <h3 class="left-align">
                  {{ $t('userList.modalftf.dateTitle') }}
                </h3>
                <v-date-picker :locale="locale" class="calendar-bops" :min-date="new Date()" v-model="modal.date" is-expanded />
              </div>

              <div v-if="modal.page === 3">
                <h3 class="left-align">
                  {{ $t('userList.modalftf.scheduleTitle') }}
                </h3>
                <div class="timezones" v-if="displayTimezones">
                  <span class="p-float-label">
                    <AutoComplete id="timezone" v-model="modal.timezone" :suggestions="filteredCities" field="city" @complete="searchCity($event)" />
                    <label for="timezone">{{ $t("userList.modalftf.timezone") }}</label>
                  </span>
                </div>
                <div class="schedules">
                  <div class="schedule" v-for="(schedule, index) in schedules" :key="index"
                    :class="{ active: schedule == modal.schedule }" @click="modal.schedule = schedule">
                    <p>{{ schedule }}</p>
                  </div>
                </div>
              </div>
            </div>


            <div class="success-msg" v-else>
              <div>
                <img :src="require('@/assets/images/check-circle.png')" alt="check">
                <p v-if="modal.type === 1"> {{ $t('userList.modalftf.success') }} </p>
                <p v-if="modal.type === 2"> {{ $t('userList.modalVirtual.success') }} </p>
                <p v-if="modal.type === 3"> {{ $t('userList.modalHome.success') }} </p>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="!addSuccess">
            <a class="modal-close waves-effect btn btn-rounded"
              v-if="!modal.chooseService">{{ $t("userList.modalftf.buttons.cancel") }}</a>
            <a class="waves-effect btn btn-rounded back-btn" v-if="modal.chooseService"
              @click="back">{{ $t("userList.modalftf.buttons.back") }}</a>
            <a class="waves-effect waves-light btn btn-primary btn-rounded" v-if="modal.page <= 3"
              :class="{ disabled: !modal.chooseService }" @click="go">{{ $t("userList.modalftf.buttons.next") }}</a>
            <a class="waves-effect waves-light btn btn-primary btn-rounded" v-if="modal.page > 3"
              @click="addAppointment(activeUser, modal.selectedDoctor)">{{ $t("userList.modalftf.buttons.add") }}</a>
          </div>
        </div>
      </div>
      <div class="col s12 footer-list">
        <div class="total">
          <p> {{ rows.length }} {{ $t("userList.footer") }}</p>
        </div>
        <div class="pagination-btns">
          <button class="btn waves-effect" @click="firstPage"><i class="material-icons" :class="{ noMore: page == 1 }">first_page</i></button>
        <button class="btn waves-effect" @click="prevPage"><i class="material-icons" :class="{ noMore: page == 1 }">chevron_left</i></button>
        <button class="btn waves-effect" :class="{ active: item == page }" v-for="item in Math.ceil(rows.length / perPage)" :key="item" @click="goToPage(item)">{{ item }}</button>
        <button class="btn waves-effect" @click="nextPage"><i class="material-icons" :class="{ noMore: page == Math.ceil(rows.length / perPage) }">chevron_right</i></button>
        <button class="btn waves-effect" @click="lastPage"><i class="material-icons" :class="{ noMore: page == Math.ceil(rows.length / perPage) }">last_page</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import { httpKeycV1 } from '../../../../core/http'
  import cityMap from '../../../../data/cityMap.json'
  import Dropdown from 'primevue/dropdown';
  import MultiSelect from 'primevue/multiselect';
  import ProgressSpinner from 'primevue/progressspinner';
  import { getTimezone } from 'countries-and-timezones';

  export default({
    name: "UserTable",
    components: {
      Dropdown,
      ProgressSpinner,
      MultiSelect
    },
    data() {
      return {
        search: "",
        searchdoc: "",
        searchName: "",
        searchLastname: "",
        searchId: "",
        searchEmail: "",
        searchPhone: "",
        rows: [],
        data: [],
        doctors: [],
        rowsDoctors: [],
        schedules: [],
        activeUser: {},
        addSuccess: false,
        modal: {
          chooseService: false,
          selectedDoctor: {},
          page: 1,
          date: new Date(),
          schedule: 0,
          type: null,
          timezone: null
        },
        perPage: 10,
        page: 1,
        itemsUser: [
          {
            label: this.$t("userList.dropdownOptions[0]"),
            command: row => this.openModal(row, 2),
          },
          {
            label: this.$t("userList.dropdownOptions[1]"),
            command: row => this.openModal(row, 1),
          },
          {
            label: this.$t("userList.dropdownOptions[2]"),
            command: row => this.openModal(row, 3),
          },
        ],
        searchOptions: [{
          label: this.$t("userList.searchOptions[0]"),
          type: 0,
        }, {
          label: this.$t("userList.searchOptions[1]"),
          type: 1,
        }, {
          label: this.$t("userList.searchOptions[2]"),
          type: 2,
        }, {
          label: this.$t("userList.searchOptions[3]"),
          type: 3,
        }, {
          label: this.$t("userList.searchOptions[4]"),
          type: 4,
        }],
        optionsSearch: [],
        loading: false,
        cities: cityMap,
        filteredCities: null,
      };
    },
    computed: {
      userToken() {
        return store.getters.getToken;
      },
      patients() {
        return this.rows.slice((this.page - 1) * this.perPage, this.page * this.perPage)
      },
      locale() {
        return this.$i18n.locale
      },
      displayTimezones() {
        return store.getters.getCompany.Properties.MultipleTimeZones ? store.getters.getCompany.Properties.MultipleTimeZones : false;
      },
      specialties() {
        return store.getters.agendaSpecialties;
      }
    },
    watch: {
      searchdoc() {
        this.searchDoctors(this.doctors)
      },
      '$i18n.locale': function () {
        this.searchOptions[0].label = this.$t("userList.searchOptions[0]")
        this.searchOptions[1].label = this.$t("userList.searchOptions[1]")
        this.searchOptions[2].label = this.$t("userList.searchOptions[2]")
        this.searchOptions[3].label = this.$t("userList.searchOptions[3]")
        this.searchOptions[4].label = this.$t("userList.searchOptions[4]")

        this.itemsUser[0].label = this.$t("userList.dropdownOptions[0]")
        this.itemsUser[1].label = this.$t("userList.dropdownOptions[1]")
        this.itemsUser[2].label = this.$t("userList.dropdownOptions[2]")
      },
      optionsSearch() {
        this.searchName = this.filterOptions(0) ? this.searchName : '';
        this.searchLastname = this.filterOptions(1) ? this.searchLastname : '';
        this.searchId = this.filterOptions(2) ? this.searchId : '';
        this.searchEmail = this.filterOptions(3) ? this.searchEmail : '';
        this.searchPhone = this.filterOptions(4) ? this.searchPhone : '';
      }
    },
    methods: {
      filterOptions(id) {
        return this.optionsSearch.filter(e => e.type === id).length > 0
      },

      searchPatients(patients) {
        if(this.searchName == '' && this.searchLastname == '' && this.searchId == '' && this.searchEmail == '' && this.searchPhone == '') {
          this.rows = this.data
        } else {
          const lastnameSearch = this.searchLastname == '' ?  [] : patients.filter( row => row.user.LastName.toLowerCase().includes(this.searchLastname.toLowerCase()))
          const nameSearch = this.searchName == '' ?  [] : patients.filter( row => row.user.Name.toLowerCase().includes(this.searchName.toLowerCase()))
          const docNumberSearch = this.searchId == '' ?  [] : patients.filter( row => row.user.Document.DocumentNumber.toLowerCase().includes(this.searchId.toLowerCase()))
          const phoneSearch = this.searchPhone == '' ?  [] : patients.filter( row => row.user.Phone.toLowerCase().includes(this.searchPhone.toLowerCase()))
          const emailSearch = this.searchEmail == '' ?  [] : patients.filter( row => row.user.Email.toLowerCase().includes(this.searchEmail.toLowerCase()))
          
          let searchs = lastnameSearch.concat(nameSearch, docNumberSearch, phoneSearch, emailSearch)
          searchs = [...new Set([...lastnameSearch, ...nameSearch, ...docNumberSearch, ...phoneSearch, ...emailSearch])]
          this.rows = searchs
        }
      },

      searchDoctors(doctors) {
        if(this.searchdoc != "") {
          const lastnameSearch = doctors.filter( row => row.LastName.toLowerCase().includes(this.searchdoc.toLowerCase()))
          const nameSearch = doctors.filter( row => row.Name.toLowerCase().includes(this.searchdoc.toLowerCase()))
          const docNumberSearch = doctors.filter( row => row.Document.DocumentNumber.toLowerCase().includes(this.searchdoc.toLowerCase()))
          const phoneSearch = doctors.filter( row => row.Phone.toLowerCase().includes(this.searchdoc.toLowerCase()))
          const emailSearch = doctors.filter( row => row.Email.toLowerCase().includes(this.searchdoc.toLowerCase()))
          
          let searchs = lastnameSearch.concat(nameSearch, docNumberSearch, phoneSearch, emailSearch)
          searchs = [...new Set([...lastnameSearch, ...nameSearch, ...docNumberSearch, ...phoneSearch, ...emailSearch])]
          this.rowsDoctors = searchs
        } else {
          this.rowsDoctors = this.doctors
        }
      },

      async searchSpecific() {
        this.rows = [];
        this.data = [];
        this.loading = true;
        if(this.searchName == '' && this.searchLastname == '' && this.searchId == '' && this.searchEmail == '' && this.searchPhone == '') {
          await this.getPatients();
          this.loading = false;
          return
        }

        if(this.optionsSearch.length <= 0) {
          return M.toast({
            html: this.$t('userList.errorSearch'),
          });
        }

        const params = {
          name: this.searchName,
          lastName: this.searchLastname,
          patientId: this.searchId,
          email: this.searchEmail,
          phone: this.searchPhone,
          country: store.getters.getApp.Countries[0],
        }

        const response = await httpKeycV1.get("/api/patient", {
          params
        });

        const data = response.data;

        for (let i = 0; i < data.length; i++) {
          this.data.push({
            user: data[i],
            isOpen: false,
          })
        }
        this.rows = this.data
        this.searchPatients(this.rows);
        this.loading = false
      },

      async getPatients() {
        const response = await httpKeycV1.get("/api/patient", { params: { country: store.getters.getApp.Countries[0], } });

        const data = response.data;
        
        let patients = data.filter(
          (patient) => this.calculateAge(patient.Birthdate) >= 18
        );

        // patients = patients.filter((patient) => patient.Document.DocumentNumber != "")

        for (let i = 0; i < patients.length; i++) {
          this.data.push({
            user: patients[i],
            isOpen: false,
          })
        }
        this.rows = this.data
      },

      async getDoctors() {
        let doctors = [];
        for (let i = 0; i < this.specialties.length; i++) {
          const e = this.specialties[i];
          const response = await httpKeycV1.get("/api/user/doctors", { params: { specialty: e } });
          doctors = doctors.concat(response.data);
        }
        this.doctors = doctors;
        this.rowsDoctors = this.doctors;
      },

      async getDoctorAvailability(modal) {
        const date = modal.date
        const doctor = modal.selectedDoctor

        const params = {
          userId: doctor.Id,
          consultanceDate: date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
        }

        const r = await httpKeycV1.get('/api/user/availability', { params });
        let data = r.data
        let schedules = []

        for (let i = 0; i < data.length; i++) {
          const e = data[i];
          let date = new Date(e)
          let now  = new Date()
          if(date.getTime() > now.getTime() ) {
            let hour = this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes())
            schedules.push(hour)
          }
        }
        this.schedules = schedules
      },

      searchCity(event) {
        this.filteredCities = this.cities.filter((city) => {
          return city.city.toLowerCase().startsWith(event.query.toLowerCase());
        })
      },

      getOffsetTimezone(timezone) {
        return ((getTimezone(timezone).utcOffset)/60)
      },

      transformDate(date, hour, timezone) {
        let sign = Math.sign(timezone) === -1 ? '-' : '+';
        let formatDate = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate()) +
          'T' + hour + ':00'+ sign + this.addZero(Math.abs(timezone)) + ':00'
        let newDate = new Date(Date.parse(formatDate))
        
        return new Date(newDate.toISOString()).toLocaleString('default');
      },

      async addAppointment(patient, doctor) {
        const date = this.modal.date
        let formatDate = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate()) +
          'T' + this.modal.schedule + ':00'
        
        const tz = this.displayTimezones ? this.getOffsetTimezone(this.modal.timezone.timezone) : (-1 * ((new Date().getTimezoneOffset())/60))

        const body = {
          UserId: patient.Id,
          DoctorId: doctor.Id,
          Date: formatDate,
          Type: this.modal.type,
          Zone: tz,
          Specialty: this.specialties[0],
        }

        try {
          await httpKeycV1.post('/api/patient', body).then((response) => {
            if (response.status == 200) {
              this.addSuccess = true
              return setTimeout(() => {
                var elem = document.getElementById("newAgenda-modal");
                var instance = M.Modal.getInstance(elem);
                instance.close()
                this.modal = {
                  chooseService: false,
                  selectedDoctor: {},
                  page: 1,
                  date: new Date(),
                  schedule: 0,
                  type: null,
                  timezone: null
                }
              }, 3000)
            }
          })
        } catch (error) {
          console.log(error)
        }
      },

      addZero(date) {
        if ((date / 10) < 1)
          return '0' + date

        return date
      },

      openDropdown(row) {
        row.isOpen = !row.isOpen
      },

      toggle(event, ref, index) {
        this.$refs[ref][index].toggle(event);
      },

      openModal(row, type) {
        row.isOpen = false
        this.activeUser = row.user
        this.modal.page = 1
        this.modal.chooseService = false
        this.modal.selectedDoctor = {}
        this.modal.type = type
        this.addSuccess = false

        var elem = document.getElementById("newAgenda-modal");
        var instance = M.Modal.getInstance(elem);
        instance.open();
      },

      back() {
        if (this.modal.page > 1) {
          this.modal.page--
        } else if (this.modal.page === 1) {
          this.modal.chooseService = false
        }
      },

      go() {
        if (this.modal.page == 1) {
          if (Object.keys(this.modal.selectedDoctor).length !== 0) {
            return this.modal.page++
          }
        }

        if (this.modal.page == 2) {
          if (this.modal.date) {
            this.getDoctorAvailability(this.modal)
            return this.modal.page++
          }
        }

        if (this.modal.page == 3) {
          if(this.displayTimezones && !this.modal.timezone) {
            return M.toast({
              html: this.$t('userList.modalftf.errorTimezone'),
            });
          }

          if (this.modal.schedule === 0) {
            return M.toast({
              html: this.$t('userList.modalftf.errorhour'),
            });
          }

          return this.modal.page++
        }
      },

      prevPage() {
        if(this.page !== 1) {
          this.page--;
        }
      },

      nextPage() {
        if (this.page !== Math.ceil(this.rows.length / this.perPage)) {
          this.page++;
        }
      },

      goToPage(numPage) {
        this.page = numPage
      },

      firstPage() {
        this.page = 1
      },

      lastPage() {
        this.page = Math.ceil(this.rows.length / this.perPage)
      },

      calculateAge(dateString) {
        var birthday = new Date(dateString);
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      },
    },
    created() {
      this.getPatients();
      this.getDoctors();
    },
    mounted() {
      M.AutoInit();
      var modals = document.querySelectorAll(".modal");
      M.Modal.init(modals);
    }
  });
</script>

<style scoped>
  #user-table {
    margin-left: 67px;
    padding: 24px;
  }

  .modal {
    width: 50% !important;
  }

  .modal-content h3 {
    font-weight: 800 !important;
    font-size: 22px !important;
    line-height: 33px !important;
    color: #79869A;
    margin: 24px 0 !important;
  }

  .modal-content h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #242A33;
  }

  .modal-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #748AA1;
  }

  .modal-content .appointment-info {
    border-bottom: 2px solid #E4E7EC;
    padding: 15px 5px;
    margin: 5px 0 10px;
  }

  .modal-content .appointment-info p {
    font-weight: 600 !important;
    margin-bottom: 5px !important;
  }

  .modal-content .appointment-info p.title {
    color: #79869A;
  }

  .modal-content .appointment-info p.name {
    color: #242A33;
  }

  .modal-content .services .rectangle {
    padding: 20px;
    box-shadow: 0px 3px 8px rgba(72, 78, 90, 0.18);
    border-radius: 8px;
    margin-bottom: 15px;
  }

  .modal-content .services .rectangle.enable {
    cursor: pointer;
  }

  .modal-content .services .rectangle p,
  .modal-content .professionals .professional p {
    font-weight: 700 !important;
    letter-spacing: 0.1px;
    color: #576374 !important;
  }

  .modal-content .professionals .professional {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border: 1px solid #E1E2E6;
    border-radius: 8px;
    margin-bottom: 12px;
  }

  .modal-content .timezones{
    margin: 20px 0;
  }

  .modal-content .timezones:deep(input:hover){
    border-color: var(--primary);
  }

  .modal-content .timezones:deep(input:focus){
    border-bottom: 1px solid var(--primary) !important;
    box-shadow: 0 1px 0 0 var(--primary) !important;
  }

  .modal-content .schedules {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 20px 0;
  }

  .modal-content .schedules .schedule {
    border: 1px solid var(--primary);
    border-radius: 30px;
    padding: 3px 11px;
    margin: 0 8px;
    cursor: pointer;
  }

  .modal-content .schedules .schedule.active {
    background-color: var(--primary) !important;
  }

  .modal-content .schedules .schedule p {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: var(--primary);
  }

  .modal-content .schedules .schedule.active p {
    color: #fff !important;
  }

  .modal-content .success-msg {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .modal-content .success-msg p{
    margin-top: 8px;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #1B1D1F !important;
  }

  .modal .modal-footer {
    padding: 0 24px;
  }

  .modal-footer .btn {
    font-size: 16px !important;
    padding: 0 24px;
  }

  .modal-footer .btn-primary {
    color: #fff !important;
    margin-left: 16px !important;
  }

  .modal-footer .back-btn {
    background-color: transparent !important;
    border: 1px solid var(--primary) !important;
    box-shadow: none !important;
  }

  .modal-footer .btn-primary.disabled {
    background-color: var(--primary) !important;
    opacity: 0.5 !important;
  }

  .modal-footer .modal-close {
    background: #E1E2E6 !important;
    color: #656e73 !important;
  }

  .row {
    box-shadow: 0px 0px 8px rgba(27, 30, 32, 0.15);
    border-radius: 15px;
    padding: 0 35px;
  }

  .search-input {
    display: flex;
    align-items: center;
    margin: 37px 0 20px;
    border: 1px solid #c5c6ca;
    border-radius: 8px;
    padding: 0 15px;
    width: fit-content;
  }

  .search-input.input-modal {
    width: 100%;
  }

  .search-input input {
    border-bottom: none !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    margin-left: 15px;
    border-left: 1px solid #c5c6ca;
    padding-left: 10px;
  }

  .search-input.input-modal input {
    border-left: none !important;
  }

  .select-search {
    margin: 37px 15px 20px 0;
    width: 175px;
  }

  .select-search:deep(.p-multiselect) {
    border-radius: 8px !important;
    width: 100%;
  }

  .filter-btn {
    box-shadow: none !important;
    background-color: transparent !important;
    border: 1px solid #c5c6ca;
    border-radius: 8px;
    margin: 37px 22px 20px;
    height: 44px;
    padding: 0 14px 0 12px;
  }

  .filter-btn .material-icons-outlined {
    color: #303133;
  }

  .search-input .material-icons {
    color: #aaacae !important;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 14px;
  }

  th {
    padding: 0 15px !important;
  }

  td,
  th {
    text-align: center;
  }

  tbody tr td {
    border-top: 1px solid #d2d8e6 !important;
    border-bottom: 1px solid #d2d8e6 !important;
    border-left: none;
    border-right: none;
    background: #fafcff !important;
    border-radius: 0;
  }

  tbody tr td:first-child {
    border-radius: 15px 0 0 15px;
    border-left: 1px solid #d2d8e6 !important;
  }

  tbody tr td:last-child {
    border-radius: 0 15px 15px 0;
    border-right: 1px solid #d2d8e6 !important;
  }

  .footer-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 15px;
  }

  .footer-list .total p {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #748AA1 !important;
  }

  .pagination-btns .btn {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #748AA1;
    font-weight: 500;
    font-size: 16px; 
    line-height: 24px;
  }

  .pagination-btns .btn .material-icons {
    color: #242A33;
  }

  .pagination-btns .btn .material-icons.noMore {
    color: #748AA1;
  }

  .pagination-btns .btn.active{
    color: var(--primary) !important;
  }

  .modal.modal-fixed-footer {
    height: 90%;
  }

  @media (max-width: 768px) {
    #user-table {
      margin-left: 0px;
    }

    .modal {
      width: 75% !important;
    }
  }
</style>