
import { defineComponent } from 'vue'
import axios from 'axios';
import store from '@/store';

export default defineComponent({
  name: 'PatientCode',
  props: {
    interactionId: String,
  },
  data() {
    return {
      code: '',
    }
  },
  computed: {
    userToken() {
      return store.getters.getToken;
    },
  },
  methods: {
    validateCode() {
      if (!this.code || this.code.trim() == '') {
        return M.toast({
          html: this.$t('callcenterInfo.patient.noCode'),
        });
      }

      // const headers = {
      //   Authorization: `Bearer ${this.userToken}`,
      //   appid: process.env.VUE_APP_APP_ID,
      //   companyid: process.env.VUE_APP_COMPANY_ID,
      // }

      // const params = {
      //   interactionId: this.interactionId,
      //   callCode: this.code
      // }

      // const response = await axios.get(process.env.VUE_APP_BOPS_KEYC_API_URL + "/api/user/validate-code", {
      //   headers,
      //   params,
      // })

      if(this.code.trim() == '1234') {
        this.$emit('validateCode')
      } else {
        return M.toast({
          html: this.$t('callcenterInfo.patient.wrongPatient'),
        });
      }
    }
  }
})
