
  import TopBar from '@/components/themes/1/TopBar.vue'
  import SideNav from '@/components/themes/1/SideNav.vue'
  import UserTable from '@/components/themes/1/Users/UserTable.vue'
  import {
    defineComponent
  } from 'vue'

  export default defineComponent({
    components: {
      TopBar,
      SideNav,
      UserTable,
    },
    name: 'UserList',
  })
